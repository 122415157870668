import { FrequencyOfUsageTypes } from '../../../../../../graphql/reminders/models/reminder.models';

export const LENSES_AMOUNT_MIN = '0';
export const LENSES_AMOUNT_MAX = '360';

export const usageFrequencyValueObj = {
  [FrequencyOfUsageTypes.everyDay]: 7,
  [FrequencyOfUsageTypes.fiveDays]: 5,
  [FrequencyOfUsageTypes.threeToFourDays]: 4,
  [FrequencyOfUsageTypes.oneToTwoDays]: 2,
  [FrequencyOfUsageTypes.lessThanOneDay]: 1,
  [FrequencyOfUsageTypes.iDontKnow]: 7,
};
