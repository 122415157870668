import { ReactElement, ReactNode } from 'react';

export interface IUserActivitiesListProps<T> {
  title?: ITitle;
  activities: ReactElement<T>[];
  footer?: ReactNode;
  placeholder?: ReactNode;
}

export interface ITitle {
  size?: ITitleSize;
  label: ReactNode
}

export enum ITitleSize {
  md = 'md',
  lg = 'lg',
}
