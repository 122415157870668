import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  borderRadiuses, palette, breakpoints, fontSizes, lineHeights,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    border: `1px dashed ${palette.grey['400']}`,
    [breakpoints.up('sm')]: {
      padding: 64,
    },
    [breakpoints.up('lg')]: {
      minHeight: 288,
    },
    '&&': {
      borderRadius: borderRadiuses.md,
      boxShadow: 'none',
    },
  },
  icon: {
    margin: '0 auto 12px',
  },
  description: {
    maxWidth: 418,
    '&, & p': {
      margin: 0,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.sm,
      textAlign: 'center',
      [breakpoints.up('sm')]: {
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.md,
      },
    },
  },
}));
