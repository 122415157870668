import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  title: {
    position: 'relative',
    paddingLeft: 25,
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.xxl,
    margin: '0 0 12px',
    [breakpoints.up('md')]: {
      paddingLeft: 0,
      fontSize: fontSizes.xxl,
      margin: '0 0 24px',
    },
  },
  backButton: {
    position: 'absolute',
    paddingRight: 5, // to cover gap between title & icon
    top: 2,
    left: -8,
    [breakpoints.up('md')]: {
      top: 1,
      left: -36,
    },
  },
}));
