import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette, fontFamilies, fontSizes, lineHeights, breakpoints,
}: Theme) => ({
  lensesAmountWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'start',
    gap: 20,
    '&& label': {
      transform: 'none',
    },
  },
  addPackButton: {
    marginTop: 20,
    padding: '8px 16px',
    border: `2px solid ${palette.grey['400']}`,
    borderRadius: 60,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
