import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    marginBottom: 32,
    [breakpoints.up('md')]: {
      marginBottom: 64,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 80,
    },
  },
  title: {
    textAlign: 'center',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    fontWeight: fontWeights.medium,
    margin: '0 0 16px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 24,
    },
    [breakpoints.up('lg')]: {
      fontSize: fontSizes.xxxl,
      lineHeight: lineHeights.xxxl,
      marginBottom: 32,
    },
  },
  cardsList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    display: 'grid',
    columnGap: '16px',
    gridTemplateColumns: 'minmax(0, 248px)',
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 248px))',
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 234px))',
    },
    [breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 248px))',
      justifyContent: 'center',
    },
    '& .swiper-slide:first-of-type': {
      paddingLeft: 16,
      [breakpoints.up('lg')]: {
        paddingLeft: 20,
      },
      [breakpoints.up('xl')]: {
        paddingLeft: 0,
      },
    },
    '& .swiper-slide:last-of-type': {
      paddingRight: 16,
      [breakpoints.up('lg')]: {
        paddingRight: 20,
      },
      [breakpoints.up('xl')]: {
        paddingRight: 0,
      },
    },
  },
  cardsItem: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    height: '100%',
  },
  footer: {
    paddingTop: 32,
    textAlign: 'center',
  },
}));
