import React, { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { Select as SelectBase, ISelectProps } from '../../select';
import { IAdditionalFormProps, TFormExtendedProps } from '../form.models';

export const Select = ({
  name,
  helperText,
  onChangeDependecies,
  ...props
}: TFormExtendedProps<ISelectProps>): JSX.Element => {
  const { control, formState: { errors } } = useFormContext();
  const {
    field: { ref, onChange, ...restField },
    fieldState: { invalid },
  } = useController({
    name,
    control,
  });
  const additionalProps: IAdditionalFormProps = {};

  if (invalid) {
    additionalProps.error = true;
    additionalProps.helperText = errors[name]?.message;
  } else if (helperText) {
    additionalProps.helperText = helperText;
  }

  const handleChange = useCallback((...values: unknown[]) => {
    onChange(...values);

    if (onChangeDependecies) {
      onChangeDependecies();
    }
  }, [onChange, onChangeDependecies]);

  return (
    <SelectBase
      {...props}
      {...restField}
      {...additionalProps}
      onChange={handleChange}
    />
  );
};
