import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  rootSizeSm: {
    width: 120,
    height: 21,
  },
  rootSizeMd: {
    width: 150,
    height: 26,
  },
  rootSizeLg: {
    width: 190,
    height: 33,
  },
  withBackground: {
    '&$rootSizeLg': {
      width: 100,
      height: 114,
    },
  },
}));
