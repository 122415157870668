import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PhoneStep } from './reset-password-steps/phone-step';
import { OneTimePinStep } from './reset-password-steps/one-time-pin-step/one-time-pin-step.container';
import { PasswordStep } from './reset-password-steps/password-step';
import { RecaptchaActions } from '../../../../rest/consumer';

export const resetPasswordSteps = [
  {
    title: (
      <span data-testid="phone-step-title">
      <FormattedMessage id="resetPassword.title" />
    </span>
    ),
    description: <FormattedMessage id="resetPassword.phoneStep.description" />,
    StepComponent: PhoneStep,
    id: 'username',
    disableNextButton: true,
    isRecaptcha: true,
    recaptchaAction: RecaptchaActions.resetPassword,
  },
  {
    title: (
      <span data-testid="one-time-pin-step-title">
      <FormattedMessage id="resetPassword.title" />
    </span>
    ),
    description: <FormattedMessage id="resetPassword.oneTimePinStep.description" />,
    StepComponent: OneTimePinStep,
    id: 'oneTimePin',
    disableNextButton: true,
  },
  {
    title: (
      <span data-testid="password-step-title">
      <FormattedMessage id="resetPassword.title" />
    </span>
    ),
    description: <FormattedMessage id="resetPassword.passwordStep.description" />,
    StepComponent: PasswordStep,
    id: 'password',
    nextButtonLabel: <FormattedMessage id="resetPassword.passwordStep.nextButton.label" />,
    nextButtonAriaLabel: <FormattedMessage id="resetPassword.passwordStep.nextButton.ariaLabel" />,
  },
];
