import React from 'react';

import { ContentWrapper } from '../../content-wrapper';
import { MyLenses } from './my-lenses';
import { useStyles } from './my-bonuses-lenses-section.styles';
import { MyBonuses } from './my-bonuses';

export const MyBonusesLensesSection = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <ContentWrapper>
        <div className={classes.container}>
          <MyBonuses />
          <MyLenses />
        </div>
      </ContentWrapper>
    </section>
  );
};
