import { cx } from '@emotion/css';
import { useIntl } from 'react-intl';

import { ISupportBlockProps } from './support-block.models';

import { useStyles } from './support-block.styles';

export const SupportBlock = (props: ISupportBlockProps): JSX.Element => {
  const {
    title,
    description,
    content,
    phoneNumber,
    titleClasses,
    descriptionClasses,
    rootClasses,
    actionClasses,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={cx(classes.section, rootClasses)}
      data-testid="footer-support-block"
    >
      <h2
        className={cx(classes.text, classes.title, titleClasses)}
        id="help"
        tabIndex={-1}
        data-testid="footer-support-title"
      >
        {title}
      </h2>
      <p className={cx(classes.text, classes.description, descriptionClasses)}>
        {description}
      </p>
      <div className={classes.contactWrapper}>
        <a
          href={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
          className={cx(classes.button, actionClasses)}
          data-testid="footer-phone-link"
        >
          {phoneNumber}
        </a>
        {content}
      </div>
    </div>
  );
};
