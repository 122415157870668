import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  title: {
    margin: '0 0 4px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.md,
    color: palette.text.primary,
  },
  param: {
    margin: '0',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.md,
    color: palette.text.primary,
  },
  paramValue: {
    fontWeight: fontWeights.medium,
  },
}));
