import React from 'react';
import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';

import { GET_IS_USER_LOGGED_IN } from '../../../../../graphql/user/queries';
import { Button, TButtonProps } from '../../../../common/button';
import { AskLoginModal } from '../../../../common/ask-login-modal';
import { useStyles } from './ask-login-link.styles';

export const AskLoginLink = ({ to, state, children }: TButtonProps): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
  const { showModal } = useModal();
  const classes = useStyles();

  const handleClick = () => {
    showModal(AskLoginModal, {
      description: 'myAcuvueProgram.registration.popup.description',
    });
  };

  return (
    <>
      {
        !isLoggedIn ? (
          <Button
            onClick={handleClick}
            className={classes.link}
            data-testid="ask-login-button"
          >
            {children}
          </Button>
        ) : (
          <Button
            to={to}
            state={state}
            className={classes.link}
            data-testid="ask-login-link"
          >
            {children}
          </Button>
        )
      }
    </>
  );
};
