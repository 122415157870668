import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import campaignIcon
  from '../../../assets/images/backgrounds/campaign/campaign.png';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  campaign: {
    objectFit: 'contain',
    display: 'block',
    width: '100%',
    height: 148,
    margin: '48px auto 0',
    backgroundImage: `url(${campaignIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    [breakpoints.between('sm', 'lg')]: {
      width: 87,
      height: 125,
      margin: '40px 0 0',
      backgroundPosition: 'right center',
    },
    [breakpoints.up('lg')]: {
      margin: '44px 0 0',
      backgroundPosition: 'right center',
    },
  },
  lessTopSpace: {
    marginTop: 12,
  },
}));
