import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { ContentWrapper } from '../../content-wrapper';
import { SupportBlock } from '../../../../common/support-block';
import { HelpBlock } from '../../../../common/help-block';
import { SocialsNetworks } from '../../../../common/socials-networks';
import { Links } from './links';
import { useStyles } from './footer.styles';
import { ContactAJnjProModal } from '../../../../common/contact-a-jnj-pro-modal';

export const Footer = (): JSX.Element => {
  const classes = useStyles();
  const { showModal } = useModal();

  const handleAskQuestionClick = () => {
    showModal(ContactAJnjProModal, {});
  };

  return (
    <footer
      className={classes.footer}
      data-testid="footer"
    >
      <ContentWrapper>
        <div className={classes.supportContainer}>
          <SupportBlock
            title={<FormattedMessage id="footer.supportService.title" />}
            description={
              <FormattedMessage id="footer.supportService.description" />
            }
            content={(
              <SocialsNetworks
                isMail
              />
            )}
            phoneNumber={<FormattedMessage id="common.phoneNumber.support" />}
          />
          <HelpBlock
            title={
              <FormattedMessage id="footer.medicalExpertAssistance.title" />
            }
            description={(
              <FormattedMessage
                id="footer.medicalExpertAssistance.description"
                values={{
                  nonBreakSpace: <>&nbsp;</>,
                }}
              />
            )}
            action={{
              children: (
                <FormattedMessage id="footer.medicalExpertAssistance.askQuestionButton.label" />
              ),
              onClick: handleAskQuestionClick,
            }}
            hintTexts={(
              <>
                <FormattedMessage id="footer.medicalExpertAssistance.footerDescription.part1" />
                <br />
                <FormattedMessage id="footer.medicalExpertAssistance.footerDescription.part2" />
              </>
            )}
          />
        </div>
        <Links />
      </ContentWrapper>
    </footer>
  );
};
