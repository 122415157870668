import { useStyles } from './no-entries-placeholder.styles';
import { Card } from '../card';
import { INoEntriesPlaceholderProps } from './no-entries-placeholder.models';
import { Icon, IconSizes } from '../icon';

export const NoEntriesPlaceholder = ({
  iconType, description, ...props
}: INoEntriesPlaceholderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      {...props}
      className={classes.root}
    >
      {iconType && (
        <Icon
          size={IconSizes.xxl}
          type={iconType}
          aria-hidden="true"
          className={classes.icon}
        />
      )}
      {description && (
        <div
          data-testid="no-entries-placeholder-description"
          className={classes.description}
        >
          {description}
        </div>
      )}
    </Card>
  );
};