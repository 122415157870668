import React from 'react';

import { Stepper } from '../../../../../common/stepper';
import { ISurveyStepProps } from './survey-step.models';

export const SurveyStep = ({
  surveySteps, onSurveyDone,
}: ISurveyStepProps): JSX.Element | null => (
  <Stepper
    steps={surveySteps}
    onDone={onSurveyDone}
  />
);
