import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSpecificPath = (paths: string[]) => {
  const { pathname } = useLocation();

  const isSpecificPath = useMemo(
    () => !!paths.find((path: string) => path === pathname || path === pathname.slice(1)),
    [paths, pathname],
  );

  return { isSpecificPath };
};
