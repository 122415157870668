import React from 'react';
import { cx } from '@emotion/css';

import { IInfoLabelProps } from './info-label.models';
import { useStyles } from './info-label.styles';

export const InfoLabel = ({ icon, description, fullWidth }: IInfoLabelProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, {
        [classes.fullWidth]: fullWidth,
      })}
      data-testid="info-label"
    >
      <div className={classes.icon}>
        {icon}
      </div>
      <p className={classes.description}>
        {description}
      </p>
    </div>
  );
};
