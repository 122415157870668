import { ReactNode, forwardRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sub } from 'date-fns';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { DatePicker } from '../../../../../../common/form/date-picker';
import { Select } from '../../../../../../common/form/select';
import { TextField } from '../../../../../../common/form/text-field';
import { IWeeklyLensesFormViewProps } from './weekly-lenses-form.models';
import { useStyles } from './weekly-lenses-form.styles';
import { changeNotificationItems, buyNotificationItems } from './form-select-items';
import { LENSES_AMOUNT_MAX, LENSES_AMOUNT_MIN } from '../reminder-form.constants';
import { toDate } from '../../../../../../../utils/date/to-date';
import { Button } from '../../../../../../common/button';

export const WeeklyLensesForm = forwardRef<
IFormImperativeHandleProps, IWeeklyLensesFormViewProps>(({
  packSize,
  defaultValues,
  validationSchema,
  onSubmit,
  onAddLensesClick,
  onBuyDateDependency,
  onChangeLensesDependency,
}, ref): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const minStartWearingDate = useMemo(() => toDate(sub(new Date(), { weeks: 2 })), [])!;

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationMode="onBlur"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    >
      <div className={classes.lensesAmoutWrapper}>
        <TextField
          type="number"
          name="amountOfLenses"
          inputProps={{
            min: LENSES_AMOUNT_MIN,
            max: LENSES_AMOUNT_MAX,
            'data-testid': 'reminder-lenses-amount-field',
          }}
          onChangeDependecies={onBuyDateDependency}
          label={(
            <FormattedMessage
              id="reminders.modal.weeklyLensesForm.amountOfLenses.label"
            />
          )}
        />
        <Button
          className={classes.addPackButton}
          onClick={onAddLensesClick}
          data-testid="add-lenses-button"
        >
          <FormattedMessage
            id="reminders.modal.weeklyLensesForm.packSize"
            values={{ packSize }}
          />
        </Button>
      </div>
      <DatePicker
        variant="dialog"
        className={classes.narrowLabelText}
        name="startWearingAt"
        disableFuture
        minDate={minStartWearingDate}
        onChangeDependecies={onChangeLensesDependency}
        InputProps={{
          readOnly: true,
          'data-testid': 'reminder-start-wearing-date',
          className: classes.startWearInput,
        }}
        components={{
          SwitchViewButton: () => <></>,
          SwitchViewIcon: () => <></>,
        }}
        dateOpenPickerChange
        views={['day']}
        label={intl.formatMessage(
          { id: 'reminders.modal.weeklyLensesForm.startWearingAt.label' },
          { span: (chunks: ReactNode) => <span className={classes.startWearPartial}>{chunks}</span> },
        )}
      />
      <DatePicker
        name="changeLensesAt"
        readOnly
        InputProps={{
          'data-testid': 'reminder-change-date',
        }}
        label={(
          <FormattedMessage
            id="reminders.modal.weeklyLensesForm.changeLensesAt.label"
          />
        )}
        disableOpenPicker
        views={['day']}
      />
      <Select
        className={classes.narrowLabelText}
        data-testid="reminder-first-notification"
        name="firstNotification"
        fullWidth
        options={changeNotificationItems}
        label={(
          <FormattedMessage
            id="reminders.modal.weeklyLensesForm.changeNotification.label"
          />
        )}
      />
      <DatePicker
        name="buyNewPackAt"
        disablePast
        InputProps={{
          readOnly: true,
          'data-testid': 'reminder-buy-date',
        }}
        dateOpenPickerChange
        components={{
          SwitchViewButton: () => <></>,
          SwitchViewIcon: () => <></>,
        }}
        label={(
          <FormattedMessage
            id="reminders.modal.weeklyLensesForm.buyNewPackAt.label"
          />
        )}
        views={['day']}
      />
      <Select
        className={classes.narrowLabelText}
        data-testid="reminder-second-notification"
        name="secondNotification"
        fullWidth
        options={buyNotificationItems}
        label={(
          <FormattedMessage
            id="reminders.modal.weeklyLensesForm.buyNotification.label"
          />
        )}
      />
    </Form>
  );
});
