import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './tech-issues.styles';
import { ContentWrapper } from '../../business/app/content-wrapper';
import { Icon, IconTypes } from '../icon';
import { Button } from '../button';
import { useGoBack } from '../../../hooks/use-go-back';

export const TechIssues = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  return (
    <div
      className={classes.root}
      data-testid="tech-issues-wrapper"
    >
      <ContentWrapper>
        <div
          className={classes.mainIcon}
          data-testid="tech-issues-main-icon"
          aria-hidden="true"
        >
          <Icon type={IconTypes.wrenchBlueBg} />
        </div>
        <h1
          className={classes.title}
          data-testid="tech-issues-title"
        >
          <FormattedMessage
            id="techIssues.title"
          />
        </h1>
        <div
          className={classes.description}
          data-testid="tech-issues-description"
        >
          {intl.formatMessage(
            { id: 'techIssues.description' },
            { p: (chunks: ReactNode) => (
              <p
                className={classes.descriptionPartial}
                data-testid="tech-issues-description-partial"
              >
                {chunks}
              </p> 
            ) },
          )}
        </div>
        <Button
          data-testid="tech-issues-button"
          variant="contained"
          to="/"
          state={hasPreviousLocationState}
          className={classes.actionButton}
        >
          <FormattedMessage id="techIssues.button.label" />
      </Button>
    </ContentWrapper>
    </div>
  );
};
