import { Marker as GoogleMapMarker } from '@react-google-maps/api';
import { useReactiveVar } from '@apollo/client';

import { IMarkerProps } from './marker.models';
import {
  getCampaignMarkerIcon,
  getLatLng,
  getMarkerIcon,
} from '../../../utils/ecp-locator';
import { setIsLocatorIconsCampaign } from '../../../graphql/configuration/configuration.cache';

export const Marker = ({
  store,
  onMarkerLoad,
  onMarkerClick,
  markerCode,
  clusterer,
  position,
  markerIcon,
  ...props
}: IMarkerProps): JSX.Element => {
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <GoogleMapMarker
      position={position || getLatLng(store!)}
      icon={markerIcon || (isCampaignIcon ? getCampaignMarkerIcon(store!, markerCode!) : getMarkerIcon(store!, markerCode!))}
      onLoad={onMarkerLoad && ((marker: google.maps.Marker) => onMarkerLoad(marker, store!))}
      onClick={onMarkerClick && ((event: google.maps.MapMouseEvent) => onMarkerClick(event, store!))}
      title={store && `${store.name} ${store.street}`}
      {...props}
    />
  );
};
