import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  register: {
    marginBottom: 12,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  benefitsList: {
    '&&': {
      marginBottom: 24,
    },
  },
}));
