import React, { useRef } from 'react';
import { useModal } from 'mui-modal-provider';

import { ReminderModalType } from './create-edit-reminder-modal.models';
import { IFormImperativeHandleProps } from '../../../../common/form';
import { ICreateEditReminderModalProps } from './create-edit-reminder-modal.models';
import { CreateReminderModal } from './create-reminder-modal';
import { EditReminderModal } from './edit-reminder-modal';
import { UnsavedChangesConfirmationModal } from '../../../../common/unsaved-changes-confirmation-modal';

export const CreateEditReminderModal = ({
  type, options, onClose, ...props
}: ICreateEditReminderModalProps): JSX.Element => {
  const reminderFormRef = useRef<IFormImperativeHandleProps>(null);
  const { showModal } = useModal();
  const ReminderModal = type === ReminderModalType.create
    ? CreateReminderModal
    : EditReminderModal;

  const handleUpdateReminder = () => {
    if (reminderFormRef.current) {
      reminderFormRef.current.submit();
    }
  };

  const handleClose = () => {
    if (reminderFormRef.current?.isDirty) {
      const { hide } = showModal(UnsavedChangesConfirmationModal, {
        onConfirm: () => {
          onClose!();
          hide();
        },
        onReject: () => {
          hide();
        },
      });
      
      return;
    }
  
    onClose!();
  };

  return (
    <ReminderModal
      {...props}
      ref={reminderFormRef}
      options={options}
      onClose={handleClose}
      onSubmitModalClose={onClose!}
      onUpdateReminder={handleUpdateReminder}
    />
  );
};
