import { FormattedMessage } from 'react-intl';

import { useStyles } from './ask-login-modal.styles';
import { Button, Modal } from '../../../../../common';
import { useGoBack } from '../../../../../../hooks/use-go-back';
import { InternalLinks } from '../../../../../../constants';
import { PreSurveyView } from '../../../vision-profile';
import { IAskLoginModalProps } from './ask-login-modal.models';

export const AskLoginModal = ({ ...props }: IAskLoginModalProps): JSX.Element => {
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  return (
    <Modal
      { ...props }
      data-testid="vp-ask-login-modal"
      content={<PreSurveyView
        benefitsListStyles={classes.benefitsList}
        titleId="vp.startPage.title.unregistered"
      />}
      footer={(
        <div className={classes.actions}>
          <Button
            variant="contained"
            to={InternalLinks.registration}
            state={hasPreviousLocationState}
            className={classes.register}
            data-testid="vp-ask-login-register"
          >
            <FormattedMessage
              id="myAcuvueProgram.registration.popup.registerButton.label"
            />
          </Button>
          <Button
            variant="outlined"
            to={InternalLinks.login}
            state={hasPreviousLocationState}
            data-testid="vp-ask-login-login"
          >
            <FormattedMessage
              id="myAcuvueProgram.registration.popup.loginButton.label"
            />
          </Button>
        </div>
      )}
    />
  );
};
