import React from 'react';
import { cx } from '@emotion/css';
import { capitalize } from 'lodash';

import { ReactComponent as LogoIcon } from '../../../assets/images/logo/logo.svg';
import { ReactComponent as LogoSecondaryIcon } from '../../../assets/images/logo/logo-secondary.svg';
import { ILogoProps } from './logo.models';
import { useStyles } from './logo.styles';

export const Logo = ({
  size, withBackground, className, ...props
}: ILogoProps): JSX.Element => {
  const classes = useStyles(props);

  const sizeClass = classes[
    `rootSize${capitalize(size as string)}` as 'rootSizeSm' | 'rootSizeMd' | 'rootSizeLg'
  ];

  return withBackground ? (
    <LogoSecondaryIcon
      data-testid="logo-secondary"
      className={
        cx(
          classes.root,
          sizeClass,
          className,
          {
            [classes.withBackground]: withBackground,
          },
        )
      }
    />
  ) : (
    <LogoIcon
      data-testid="logo"
      className={
        cx(classes.root, sizeClass, className)
      }
    />
  );
};

Logo.defaultProps = {
  size: 'sm',
  withBackground: false,
} as Partial<ILogoProps>;
