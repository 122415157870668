import React from 'react';

import { WhyCard } from './why-card';
import { IWhy } from './why.models';
import { useStyles } from './why.styles';
import { Carousel } from '../carousel';

export const Why = ({ title, items, footer }: IWhy): JSX.Element => {
  const classes = useStyles();

  return (
    <section
      className={classes.root}
      data-testid="why-list"
    >
      <h3 className={classes.title}>
        {title}
      </h3>
      <Carousel
        className={classes.cardsList}
        spaceBetween={16}
        draggable
        slidesPerView={'auto'}
      >
        {items.map((item, itemIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.cardsItem} key={itemIndex}>
            <WhyCard {...item} />
          </div>
        ))}
      </Carousel>
      {footer && (
        <footer className={classes.footer}>
          {footer}
        </footer>
      )}
    </section>
  );
};
