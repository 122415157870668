import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IStepProps } from '../../../common/stepper';
import { AppointmentCheckStep } from './registration-steps/appointment-check-step';
import { AppointmentSuccessStep } from './registration-steps/appointment-success-step';
import { DateStep } from './registration-steps/date-step';

export const appointmentRegistrationSteps: IStepProps[] = [{
  id: 'appointmentDate',
  StepComponent: DateStep,
  nextButtonLabel: (
    <FormattedMessage
      id="appointmentRegistration.dateStep.nextButton.label"
    />
  ),
}, {
  StepComponent: AppointmentCheckStep,
  id: 'appointmentCheck',
  title: (
    <span data-testid="appointment-check-step-title">
      <FormattedMessage
        id="appointmentRegistration.appointmentCheckStep.title"
        values={{
          linebreak: <br />,
        }}
      />
    </span>
  ),
  nextButtonLabel: (
    <FormattedMessage
      id="appointmentRegistration.appointmentCheckStep.nextButton.label"
    />
  ),
}, {
  StepComponent: AppointmentSuccessStep,
  disableBackButon: true,
  id: 'appointmentSuccess',
  nextButtonLabel: (
    <FormattedMessage
      id="appointmentRegistration.appointmentSuccessStep.nextButton.label"
    />
  ),
}];
