import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: palette.grey.A100,
    borderRadius: borderRadiuses.sm,
    padding: '4px 8px',
  },
  fullWidth: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    paddingRight: 8,
  },
  description: {
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xxs,
    fontFamily: fontFamilies.primary,
    color: palette.text.secondary,
    textAlign: 'start',
    margin: 0,
  },
}));
