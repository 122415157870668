import React, { RefObject, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';

import { useModal } from 'mui-modal-provider';
import { useStyles } from './ecp-locator.styles';
import { EcpMap } from '../ecp-map';
import { EcpStores } from '../ecp-stores';
import { EcpSearch } from '../ecp-search';
import { EcpSort } from '../ecp-sort/ecp-sort';
import { StoreDetail } from './store-detail';
import { useStoreDetailFromCache } from '../../../../graphql/ecp-locator/actions/store-detail';

import {
  ecpStoresCached,
  setInternetShops,
  setStoreDetail,
  setStoreCode,
  filterParams,
  setEcpFormSubmitted,
} from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { useInternetShops } from '../../../../graphql/ecp-locator/actions/internet-shops';
import { IconButton } from '../../../common/icon-button';
import { Icon, IconSizes, IconTypes } from '../../../common/icon';
import { InternetShops } from './internet-shops';
import { EcpFiltersModal } from '../ecp-filters-modal';
import { Button } from '../../../common/button';
import { IEcpStore } from '../../../../rest/ecp-locator';
import { isFilterStateDefault } from '../ecp-filters-modal/utils/is-filter-state-default';
import { IEcpLocatorProps, MobileViewTypes } from './ecp-locator.models';
import { Preloader } from '../../../common/preloader';
import { ErrorPlain, ErrorTypes } from '../../../common/error-plain';
import { Appointment } from '../user-activity-items/appointment';
import { EcpToggleView } from '../ecp-toggle-view';

export const EcpLocator = ({
  isFitted, isLoading, isError, updateStores,
}: IEcpLocatorProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isTablet = !isMobile && !isDesktop;

  const [mobileView, setMobileView] = useState(MobileViewTypes.map);

  const { setStoreDetailToCache } = useStoreDetailFromCache();
  const { setInternetShopsToCache } = useInternetShops();

  const ecpStores = useReactiveVar(ecpStoresCached);
  const isInternetShops = useReactiveVar(setInternetShops);
  const storeDetail = useReactiveVar(setStoreDetail);
  const storeCode = useReactiveVar(setStoreCode);
  const currentFilters = useReactiveVar(filterParams);
  const isEcpFormSubmitted = useReactiveVar(setEcpFormSubmitted);

  const { showModal } = useModal();
  const { isFiltersChanged } = currentFilters;
  const isFilterApplied = !isFilterStateDefault(currentFilters) && isFiltersChanged;
  const isStoreListShow = !storeDetail && !isInternetShops;
  const isLayerCard = isTablet || (isMobile && isStoreListShow);
  const isMapHidden = isMobile && (mobileView !== MobileViewTypes.map || !isStoreListShow);
  const isStoresShow = !isMobile || mobileView === MobileViewTypes.list;
  const isMobileButtonsShow = isMobile && isStoreListShow;
  const inputIcon = isMobile ? undefined : <Icon type={IconTypes.map} />;

  const handleStoreSelect = (store: IEcpStore): void => {
    setStoreDetailToCache(store);
  };

  const handleStoreClose = (code: string) => {
    setStoreDetailToCache(null);
    // To manage scroll to store card.
    setStoreCode(code);
  };

  const handleScrollToStore = (
    currentStoreId: string,
    storeTitle: RefObject<HTMLHeadingElement>,
    storeId?: string,
  ) => {
    if (storeId?.length && storeId === currentStoreId) {
      storeTitle.current?.focus();
      storeTitle.current?.scrollIntoView();
      setStoreCode('');
    }
  };

  const handleIShopsShowClick = () => {
    setInternetShopsToCache(true);
  };

  const handleIShopsBackButtonClick = () => {
    setInternetShopsToCache(false);
  };

  const handleFilterButtonClick = () => {
    showModal(EcpFiltersModal, {});
  };

  const renderStores = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    return (
      <EcpStores
        stores={ecpStores}
        onStoreSelect={handleStoreSelect}
        storeCode={storeCode}
        onScrollToStore={handleScrollToStore}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'ecpLocatorPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'ecpLocatorPage.description' })}
        />
      </Helmet>
      <div
        className={classes.root}
        data-testid="ecp-locator"
      >
        <div
          className={cx(classes.ecpLayerRoot, {
            [classes.ecpLayerRootCard]: isLayerCard,
          })}
        >
          {isStoreListShow && (
            <>
              <div
                className={cx(classes.ecpLayerWrapper, classes.shadowWrapper)}
                data-testid="ecp-stores-container"
              >
                {!isMobile && (
                  <h1
                    className={classes.title}
                    data-testid="ecp-stores-title"
                  >
                    <FormattedMessage
                      id="ecp.title"
                    />
                  </h1>
                )}
                <div className={classes.searchBar}>
                  <EcpSearch
                    updateStores={updateStores!}
                    inputIcon={inputIcon}
                    inputStyles={classes.input}
                    rootInputStyles={classes.inputRoot}
                  />
                  <div className={classes.filterButtonDesktop}>
                    <Button
                      data-testid="filter-stores-button"
                      fullWidth
                      variant="outlined"
                      onClick={handleFilterButtonClick}
                      isMarked={isFilterApplied}
                    >
                      <span data-testid="button-marked-icon">
                        <FormattedMessage
                          id="ecp.filterButton.label"
                        />
                      </span>
                    </Button>
                  </div>
                  <IconButton
                    aria-label={intl.formatMessage({
                      id: 'ecp.onlineShops.title',
                    })}
                    to={handleIShopsShowClick}
                    iconProps={{
                      type: isMobile ? IconTypes.internetShops : IconTypes.internetShopsWrapped,
                      size: isMobile ? IconSizes.sm : IconSizes.lg,
                    }}
                  />
                </div>
                {isStoresShow && (
                  <div className={classes.sortButtons}>
                    <EcpSort />
                  </div>
                )}
              </div>
              {(isEcpFormSubmitted && !ecpStores.length && isStoresShow) && (
                <div className={classes.ecpLayerWrapper}>
                  <ErrorPlain
                    content="ecp.error.noResults"
                    isolated
                    variant={ErrorTypes.plainText}
                  />
                </div>
              )}
              {isStoresShow && (
                <div className={cx(classes.ecpLayerWrapper, classes.storesList)}>
                  {renderStores()}
                </div>
              )}
            </>
          )}
          {storeDetail && (
            <div className={classes.ecpLayerWrapper}>
              <StoreDetail
                storeData={storeDetail}
                onStoreClose={handleStoreClose}
                updateStores={updateStores!}
              />
            </div>
          )}
          {isInternetShops && (
            <div className={classes.ecpLayerWrapper}>
              <InternetShops
                isFitted={!!isFitted}
                onBackButtonClick={handleIShopsBackButtonClick}
              />
            </div>
          )}
        </div>
        {!(mobileView === MobileViewTypes.list) && (
          <div
            data-testid="ecp-map-container"
            className={cx(classes.mapLayer,
              { [classes.mapLayerHidden]: isMapHidden },
              { [classes.mapLayerOverlay]: isInternetShops },
            )}
          >
            <EcpMap
              ecpStores={ecpStores}
              storeDetail={storeDetail}
              isMapHidden={isMapHidden}
            />
            <div
              data-testid="appointment-wrapper"
              className={classes.appointmentWrapper}
            >
              <Appointment />
            </div>
          </div>
        )}
        {isMobileButtonsShow && (
          <>
            <div className={classes.filterButtonMobile}>
              <IconButton
                to={handleFilterButtonClick}
                isMarked={isFilterApplied}
                iconProps={{
                  type: IconTypes.filter,
                  size: IconSizes.xs,
                }}
              />
            </div>
            <div className={classes.mobileViewButton}>
              <EcpToggleView
                value={mobileView}
                fullWidth
                onChange={setMobileView}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
