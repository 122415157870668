import { useQuery } from '@apollo/client';

import { MyBonusesLensesSection } from './my-bonuses-lenses-section';
import { Promotions } from './promotions';
import { GET_IS_USER_LOGGED_IN } from '../../../../graphql/user/queries';
import { SignUpSlide } from './sign-up-slide';
import { WhyMyAcuvue } from './why-my-acuvue';
import { PrimaryBanner } from './primary-banner';
import { EcpLocator } from './ecp-locator';
import { Appointment } from './appointment';
import { Wiki } from './wiki';
import { VisionProfile } from './vision-profile';
import { PrimaryBannerUnregistered } from './primary-banner-unregistered';
import { useStyles } from './landing-page.styles';
import { VisionProfileUnregistered } from './vision-profile-unregistered';

export const LandingPage = (): JSX.Element => {
  const classes = useStyles();
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  return (
    <>
      {!isLoggedIn && <SignUpSlide />}
      {!isLoggedIn && <WhyMyAcuvue />}
      {isLoggedIn && (
        <>
          <Appointment />
          <PrimaryBanner />
        </>
      )}
      {isLoggedIn && (
        <div className={classes.coloredSection}>
          <MyBonusesLensesSection />
          <Promotions />
          <VisionProfile />
        </div>
      )}
      {!isLoggedIn && <VisionProfileUnregistered />}
      <EcpLocator />
      <Wiki />
      {!isLoggedIn && <PrimaryBannerUnregistered />}
    </>
  );
};
