import { FrequencyOfUsageTypes } from '../../../../../../../../graphql/reminders/models/reminder.models';
import { startOfDay, differenceInDays } from 'date-fns';
import { usageFrequencyValueObj } from '../../reminder-form.constants';

interface IRecalculatedParams {
  amountOfLenses: number;
  eyesAmount: number;
  startWearingAt: string;
  frequencyOfUsage: FrequencyOfUsageTypes;
}

export const getRecalculatedLensesAmount = ({
  amountOfLenses, eyesAmount, startWearingAt, frequencyOfUsage,
}: IRecalculatedParams): string => {
  const week = 7;
  const daysDifference = differenceInDays(startOfDay(new Date()), new Date(startWearingAt));
  const roundDays = Math.ceil(daysDifference / (week / usageFrequencyValueObj[frequencyOfUsage]));
  const currentAmountOfLenses = amountOfLenses - roundDays * eyesAmount;

  return String(currentAmountOfLenses);
};
