import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';

import {
  IStepComponentProps,
  IStepperImperativeHandleProps,
} from '../../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { yup } from '../../../../../../../services/yup';
import { IStepsState } from '../survey-step.models';
import { UPDATE_VISION_PROFILE } from '../../../../../../../graphql/profile/mutations';
import { GET_VISION_PROFILE } from '../../../../../../../graphql/profile/queries/get-vision-profile';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { IVisionProfileData } from '../../../../../../../graphql/profile/models/get-vision-profile.models';
import { GadgetsUseDurationView } from '../../../vision-profile-views/gadgets-use-duration-view';
import { TGadgetsUseDurationValues } from '../../../vision-profile-views/gadgets-use-duration-view/gadgets-use-duration-form';

export const GadgetsUseDurationStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
  onNextButtonDisabled,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { gadgetsUseDuration: gadgetsUseDurationState } = stepsState as IStepsState;
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => gadgetsUseDurationState || {
    gadgetsUseDuration: null,
  }, []);

  const validationSchema = useMemo(() => yup.object({
    gadgetsUseDuration: yup.string().nullable().required(intl.formatMessage({
      id: 'common.errors.requiredPickOne',
    })),
  }), [intl]);

  const handleUpdateGadgetUseDuration = async ({
    gadgetsUseDuration: gadgetsUseDurationCurrent,
  }: TGadgetsUseDurationValues) => {
    const gadgetsUseDuration = gadgetsUseDurationState?.gadgetsUseDuration;

    if (gadgetsUseDurationCurrent === gadgetsUseDuration) {
      onGoNextSuccess({ gadgetsUseDuration });
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              gadgetsUseDuration: gadgetsUseDurationCurrent,
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      removeActionInProgress();
      onGoNextSuccess({ gadgetsUseDuration: patchVisionProfile.gadgetsUseDuration });
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }

      removeActionInProgress();
    }
  };

  const handleValidateDependencies = (isValid: boolean) => {
    onNextButtonDisabled(!isValid);
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <GadgetsUseDurationView
      ref={formRef}
      formProps={{
        onSubmit: handleUpdateGadgetUseDuration,
        onValidateDependencies: handleValidateDependencies,
        validationSchema,
        defaultValues,
        validationMode: 'onChange',
      }}
    />
  );
});
