import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../common/button';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { Modal, ResponsiveWidth } from '../../../../../common/modal';
import { ReminderForm } from '../reminder-form';
import { IEditReminderModalViewProps } from './edit-reminder-modal.models';
import { useStyles } from './edit-reminder-modal.styles';

export const EditReminderModal = forwardRef<
IFormImperativeHandleProps, IEditReminderModalViewProps>(({
  onUpdateReminder, onDeleteReminder, onSubmitModalClose, options, ...props
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      {...props}
      data-testid="edit-reminder-modal"
      responsiveWidth={ResponsiveWidth.md}
      title={(
        <FormattedMessage
          id="reminders.modal.edit.title"
        />
      )}
      content={(
        <ReminderForm
          ref={ref}
          options={options}
          onModalClose={onSubmitModalClose}
        />
      )}
      footer={(
        <div className={classes.actionButtons}>
          <Button
            fullWidth
            variant="contained"
            onClick={onUpdateReminder}
            data-testid="reminder-edit-button"
          >
            <FormattedMessage
              id="reminders.modal.edit.saveButton.label"
            />
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={onDeleteReminder}
            data-testid="reminder-delete-button"
          >
            <FormattedMessage
              id="reminders.modal.edit.deleteButton.label"
            />
          </Button>
        </div>
      )}
    />
  );
});
