import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IStepProps } from '../../../../common/stepper';
import { CompleteStep } from './complete-step';
import { PreSurveyStep } from './pre-survey-step';
import { SurveyStep } from './survey-step';

export const useVisionProfileSteps = (): IStepProps[] => {
  const intl = useIntl();

  return [{
    id: 'preSurvey',
    StepComponent: PreSurveyStep,
    disableBackButon: true,
    nextButtonLabel: <FormattedMessage id="vp.startPage.button" />,
    hintText: <FormattedMessage id="vp.startPage.buttonHelper" />,
  }, {
    id: 'survey',
    StepComponent: SurveyStep,
    disableBackButon: true,
    disableNextButton: true,
  }, {
    id: 'complete',
    StepComponent: CompleteStep,
    disableBackButon: true,
    nextButtonLabel: (
      <span>
        {intl.formatMessage(
          { id: 'vp.surveyDone.button' },
          { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
        )}
      </span>
    ),
  }];
};
