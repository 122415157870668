import { ReactNode } from 'react';
import { cx } from '@emotion/css';

import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../../../common/button';
import { useStyles } from './points-description-card.styles';
import { IPointsDescriptionCardProps } from './points-description-card.models';

export const PointsDescriptionCard = ({
  isFitted, onFitLensesClick,
}: IPointsDescriptionCardProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const renderContent = () => (
    <>
      {isFitted ? (
        <>
          <h2
            className={classes.title}
            data-testid="fitted-card-title"
          >
            <FormattedMessage
              id="ecp.onlineShops.pointsDescriptionCard.fitted.title"
            />
          </h2>
          <p className={classes.description}>
            {intl.formatMessage(
              { id: 'ecp.onlineShops.pointsDescriptionCard.fitted.description' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
        </>
      ) : (
        <>
          <h2
            className={classes.title}
            data-testid="not-fitted-card-title"
          >
            <FormattedMessage
              id="ecp.onlineShops.pointsDescriptionCard.notFitted.title"
            />
          </h2>
          <p className={cx(classes.description, classes.descriptionGap)}>
            {intl.formatMessage(
              { id: 'ecp.onlineShops.pointsDescriptionCard.notFitted.description' },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
          <Button
            variant="outlined"
            onClick={onFitLensesClick}
          >
            <FormattedMessage
              id="ecp.onlineShops.pointsDescriptionCard.notFitted.button.label"
            />
          </Button>
        </>
      )}
    </>
  );

  return (
    <div
      className={classes.root}
      data-testid="points-description-card"
    >
      <div
        className={cx(classes.content,
          { [classes.fittedContent]: isFitted },
          { [classes.notFittedcontent]: !isFitted },
        )}
      >
        {renderContent()}
      </div>
    </div>
  );
};
