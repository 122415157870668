import React from 'react';
import { omit } from 'lodash';
import { useModal } from 'mui-modal-provider';

import { ILensesCardProps } from './lenses-card.models';
import { LensesCard as LensesCardView } from './lenses-card';
import { CreateEditReminderModal, ReminderModalType } from '../../create-edit-reminder-modal';


export const LensesCard = ({ cardData, onUpdateLenses }: ILensesCardProps): JSX.Element => {
  const { showModal } = useModal();
  const { id, reminder, brand: { schedule, points }, leftEye, rightEye } = cardData;
  const { packSize } = points[0];
  const isReminderActive = !!reminder.length;
  const eyesAmount = !rightEye.length || !leftEye.length ? 1 : 2;

  const handleReminderIconClick = () => {
    const type = isReminderActive ? ReminderModalType.edit : ReminderModalType.create;

    showModal(CreateEditReminderModal, {
      type,
      options: {
        packSize,
        schedule: schedule!,
        consumerLensesId: id,
        eyesAmount,
        reminderProps: reminder[0] ? omit(reminder[0], '__typename') : undefined,
        onUpdateLenses,
      },
    });
  };

  return (
    <LensesCardView
      isReminderActive={isReminderActive}
      cardData={cardData}
      onReminderIconClick={handleReminderIconClick}
    />
  );
};
