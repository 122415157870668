import React, { forwardRef } from 'react';

import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IReminderFormProps } from './reminder-form.models';
import { DailyLensesForm } from './daily-lenses-form';
import { LensesScheduleType } from '../../../../../../graphql/lenses/models/get-lenses.models';
import { WeeklyLensesForm } from './weekly-lenses-form';

export const ReminderForm = forwardRef<IFormImperativeHandleProps, IReminderFormProps>(({
  options, onModalClose,
}, ref): JSX.Element => {
  const { schedule } = options;
  const ReminderFormComponent = schedule === LensesScheduleType.daily
    ? DailyLensesForm
    : WeeklyLensesForm;

  return (
    <ReminderFormComponent
      ref={ref}
      options={options}
      onModalClose={onModalClose}
    />
  );
});
