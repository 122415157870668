import React from 'react';

import { CompanyPolicy } from '../company-policy';
import { DownloadApp } from '../download-app';
import { useStyles } from './links.styles';

export const Links = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      data-testid="footer-links"
    >
      <CompanyPolicy />
      <DownloadApp />
    </div>
  );
};
