import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../../common/button';
import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { DatePicker } from '../../../../../../common/form/date-picker';
import { Select } from '../../../../../../common/form/select';
import { TextField } from '../../../../../../common/form/text-field';
import { LENSES_AMOUNT_MIN, LENSES_AMOUNT_MAX } from '../reminder-form.constants';
import { IDailyLensesFormViewProps } from './daily-lenses-form.models';
import { useStyles } from './daily-lenses-form.styles';
import { firstNotificationItems, frequencyOfUsageItems } from './form-select-items';

export const DailyLensesForm = forwardRef<IFormImperativeHandleProps, IDailyLensesFormViewProps>(({
  packSize,
  defaultValues,
  validationSchema,
  secondNotificationOptions,
  onSubmit,
  onAddLensesClick,
  onBuyDateDependency,
  onSecondNotificationDependency,
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      validationMode="onBlur"
      validationSchema={validationSchema}
      defaultValues={defaultValues}
    >
      <div className={classes.lensesAmountWrapper}>
        <TextField
          type="number"
          name="amountOfLenses"
          inputProps={{
            min: LENSES_AMOUNT_MIN,
            max: LENSES_AMOUNT_MAX,
            'data-testid': 'reminder-lenses-amount-field',
          }}
          onChangeDependecies={onBuyDateDependency}
          label={(
            <FormattedMessage
              id="reminders.modal.dailyLensesForm.amountOfLenses.label"
            />
          )}
        />
        <Button
          className={classes.addPackButton}
          onClick={onAddLensesClick}
          data-testid="add-lenses-button"
        >
          <FormattedMessage
            id="reminders.modal.dailyLensesForm.packSize"
            values={{ packSize }}
          />
        </Button>
      </div>
      <Select
        data-testid="reminder-frequency-usage"
        name="frequencyOfUsage"
        fullWidth
        options={frequencyOfUsageItems}
        onChangeDependecies={onBuyDateDependency}
        label={(
          <FormattedMessage
            id="reminders.modal.dailyLensesForm.frequencyOfUsage.label"
          />
        )}
      />
      <DatePicker
        name="buyNewPackAt"
        disablePast
        InputProps={{
          readOnly: true,
          'data-testid': 'reminder-buy-date',
        }}
        dateOpenPickerChange
        label={(
          <FormattedMessage
            id="reminders.modal.dailyLensesForm.buyNewPackAt.label"
          />
        )}
        views={['day']}
      />
      <Select
        data-testid="reminder-first-notification"
        name="firstNotification"
        fullWidth
        options={firstNotificationItems}
        onChangeDependecies={onSecondNotificationDependency}
        label={(
          <FormattedMessage
            id="reminders.modal.dailyLensesForm.firstNotification.label"
          />
        )}
      />
      <Select
        data-testid="reminder-second-notification"
        name="secondNotification"
        fullWidth
        options={secondNotificationOptions}
        label={(
          <FormattedMessage
            id="reminders.modal.dailyLensesForm.secondNotification.label"
          />
        )}
      />
    </Form>
  );
});
