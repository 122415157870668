import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { LayoutRegGuideV1 } from './layout-reg-guide-v1';
import { LayoutPrimary } from './layout-primary';
import { LayoutSecondary } from './layout-secondary';
import { LayoutRegGuide } from './layout-reg-guide/layout-reg-guide';
import { Login } from './login';
import { ResetPassword } from './reset-password';
import { Profile } from './profile';
import { Registration } from './registration';
import { LandingPage } from './landing-page';
import { CookieBanner } from './cookie-banner';
import { MyLenses } from './my-lenses';
import { MyBonuses } from './my-bonuses';
import { PointsHowTo } from './points-how-to';
import { CookiePolicy } from './cookie-policy';
import { PageNotFound } from './page-not-found';
import { Program } from './program';
import { EcpLocator } from './ecp-locator';
import { Appointment } from './appointment/appointment.container';
import { LOCALE } from '../../../constants';
import { InternalLinks } from '../../../constants/internal-links';
import { VisionProfilePage } from './vision-profile';
import { usePrivateRoute } from '../../../hooks/use-private-route';
import { AppLevelModals } from './app-level-modals';
import { RegGuidePage } from './reg-guide-page';
import { RegGuidePageNew } from './reg-guide-page-new';
import { ProfileDelete } from './profile-delete';
import { EcpLocatorExternal } from './ecp-locator-external';
import { MyAcuvueIdContainer } from './my-acuvue-id/my-acuvue-id.container';
import { ArlPage } from './arl-page';

export const App = (): JSX.Element => {
  const intl = useIntl();
  const { getPrivateElement } = usePrivateRoute();

  return (
    <>
      <Helmet htmlAttributes={{ lang: LOCALE }}>
        <title>
          {intl.formatMessage({ id: 'app.title' })}
        </title>
        <meta name="description" content={intl.formatMessage({ id: 'app.description' })} />
      </Helmet>
      <Routes>
        <Route element={<LayoutPrimary />}>
          <Route path="/" element={<LandingPage />} />
          <Route path={InternalLinks.cookiePage} element={<CookiePolicy />} />
          <Route path={InternalLinks.programPage} element={<Program />} />
          <Route path={InternalLinks.ecpLocator} element={<EcpLocator />} />
          <Route path={InternalLinks.profilePage} element={getPrivateElement(<Profile />)} />
          <Route path={InternalLinks.myLensesPage} element={getPrivateElement(<MyLenses />)} />
          <Route path={InternalLinks.myBonusesPage} element={getPrivateElement(<MyBonuses />)} />
          <Route path={InternalLinks.pointsHowToPage} element={getPrivateElement(<PointsHowTo />)} />
          <Route path={InternalLinks.appointment} element={getPrivateElement(<Appointment />)} />
          <Route path={InternalLinks.visionProfile} element={getPrivateElement(<VisionProfilePage />)} />
          <Route path={InternalLinks.deleteAccount} element={<ProfileDelete />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<LayoutSecondary />}>
          <Route path={InternalLinks.login} element={<Login />} />
          <Route path={InternalLinks.registration} element={<Registration />} />
          <Route path={InternalLinks.resetPassword} element={<ResetPassword />} />
        </Route>
        <Route element={<LayoutRegGuide withLegalFooter />}>
          <Route path={InternalLinks.regGuideNew} element={<RegGuidePageNew />} />
        </Route>
        <Route element={<LayoutRegGuide />}>
          <Route path={InternalLinks.arl} element={<ArlPage />} />
        </Route>
        <Route element={<LayoutRegGuideV1 />}>
          <Route path={InternalLinks.regGuide} element={<RegGuidePage />} />
        </Route>
        <Route path={InternalLinks.ecpLocatorExternal} element={<EcpLocatorExternal />} />
      </Routes>
      {location.pathname !== InternalLinks.ecpLocatorExternal && (
        <>
          <CookieBanner />
          <AppLevelModals />
        </>
      )}
      <MyAcuvueIdContainer />
    </>
  );
};
