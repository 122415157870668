import React, { forwardRef, useImperativeHandle } from 'react';

import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { IVPExternalState } from '../vision-profile.models';
import { SurveyStep as SurveyStepView } from './survey-step';

export const SurveyStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  externalState,
}, ref): JSX.Element => {
  const { surveySteps } = externalState as IVPExternalState;

  const handleSurveyDone = () => {
    onGoNextSuccess();
  };

  useImperativeHandle(ref, () => ({
    goNext() { },
    goBack() { },
  }));

  return (
    <SurveyStepView
      onSurveyDone={handleSurveyDone}
      surveySteps={surveySteps}
    />
  );
});
