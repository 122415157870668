import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import bellBlue from '../../../../../../../assets/images/backgrounds/bell-blue.png';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  activeIcon: {
    display: 'block',
    width: 32,
    height: 32,
    background: `no-repeat url(${bellBlue}) center`,
    backgroundSize: 'contain',
    [breakpoints.up('md')]: {
      width: 40,
      height: 40,
    },
  },
}));
