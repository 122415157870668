import React, { forwardRef, useImperativeHandle } from 'react';

import { CompleteStep as CompleteStepView } from './complete-step';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';

export const CompleteStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
}, ref): JSX.Element => {
  useImperativeHandle(ref, () => ({
    goNext() {
      onGoNextSuccess();
    },
    goBack() { },
  }));

  return (
    <CompleteStepView />
  );
});
