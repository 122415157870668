import { makeVar } from '@apollo/client';

import {
  getTokenFromStorage,
  getCookiePolicyFromStorage,
  getLensesTutorialFromStorage,
  getBonusesTutorialFromStorage,
} from '../../utils/user';
import { IScreenState } from './models/screen-block.model';
import { TPhoneValues } from '../../components/business/app/registration';

export const token = makeVar(getTokenFromStorage());
export const isLoggedIn = makeVar(!!token());

export const cookiePolicy = makeVar(getCookiePolicyFromStorage());
export const isCookiePolicyAgreed = makeVar(!!cookiePolicy());

export const lensesTutorial = makeVar(getLensesTutorialFromStorage());
export const isLensesTutorialShown = makeVar(!!lensesTutorial());

export const bonusesTutorial = makeVar(getBonusesTutorialFromStorage());
export const isBonusesTutorialShown = makeVar(!!bonusesTutorial());

export const screenState = makeVar<IScreenState>({
  isScreenBlocked: false,
  unblock: null,
});

export const setPhoneStepValues = makeVar<TPhoneValues | null>(null);
