import { FormattedMessage, useIntl } from 'react-intl';
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration';

import { Button } from '../../../../../../common';
import { SubmitButtonProps } from './submit-button.models';
import { RecaptchaActions } from '../../../../../../../rest/consumer';

export const SubmitButton = ({
  isValueValid,
  requestCodeTime,
  onRequestCode,
  isDisabled,
}: SubmitButtonProps): JSX.Element => {
  const intl = useIntl();

  const ButtonNoSubmit = () => (
    <>
      {isDisabled ? (
        <Button
          type="button"
          variant="contained"
          data-testid="request-another-code-timer-button"
          disabled={isDisabled}
          fullWidth
          onClick={onRequestCode}
        >
          <FormattedMessage
            id="registration.confirmationCodeStep.requestNewCodeInButton.label"
            values={{
              timeLeft: <FormattedDuration seconds={requestCodeTime} format={TIMER_FORMAT} />,
            }}
          />
        </Button>
      ) : (
        <Button
          type="button"
          variant="contained"
          data-testid="request-another-code-button"
          disabled={isDisabled}
          fullWidth
          onClick={onRequestCode}
          className="g-recaptcha"
          data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          data-callback="onRequestCode"
          data-action={RecaptchaActions.resetPassword}
        >
          <FormattedMessage id="resetPassword.oneTimePinStep.nextButton.label" />
        </Button>
      )}
    </>
  );

  return (
    <>
      {
        isValueValid ? (
          <Button
            type="submit"
            variant="contained"
            data-testid="submit-button"
            fullWidth
            aria-label={intl.formatMessage({ id: 'registration.confirmationCodeStep.nextButton.ariaLabel' })}
          >
            <FormattedMessage id="resetPassword.nextButton.label" />
          </Button>
        ) : <ButtonNoSubmit />
      }
    </>
  );
};
