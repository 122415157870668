import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  root: {
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 70,
    },
    [breakpoints.up('lg')]: {
      marginBottom: 86,
    },
  },
  slide: {
    position: 'relative',
    height: 380,
    overflow: 'hidden',
    background: palette.custom.main,
    [breakpoints.up('md')]: {
      height: 432,
      background: palette.custom.secondary,
    },
    [breakpoints.up('lg')]: {
      height: 518,
    },
    '& img': {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      objectFit: 'cover',
      margin: 0,
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    paddingLeft: 16,
    paddingTop: 36,
    paddingBottom: 8,
    [breakpoints.up('md')]: {
      paddingLeft: 77,
      paddingTop: 64,
      paddingBottom: 20,
    },
    [breakpoints.up('lg')]: {
      paddingLeft: 118,
      paddingTop: 85,
      paddingBottom: 30,
    },
  },
  title: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxl,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.xxl,
    textAlign: 'left',
    color: palette.common.white,
    maxWidth: 320,
    marginTop: 0,
    marginBottom: 16,
    [breakpoints.up('md')]: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.xxxl,
      fontWeight: fontWeights.medium,
      lineHeight: lineHeights.xxxl,
      textAlign: 'left',
      color: palette.common.white,
      maxWidth: 530,
      marginBottom: 24,
    },
    [breakpoints.up('lg')]: {
      fontFamily: fontFamilies.primary,
      fontSize: fontSizes.xxxl,
      fontWeight: fontWeights.medium,
      lineHeight: lineHeights.xxxl,
      textAlign: 'left',
      color: palette.common.white,
      maxWidth: 530,
      marginBottom: 24,
    },
    [breakpoints.between('lg', 'xl')]: {
      maxWidth: 450,
    },
    [breakpoints.up('xl')]: {
      maxWidth: 530,
    },
    '& sup': {
      position: 'relative',
      lineHeight: 0,
    },
  },
  button: {
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    width: 238,
    marginBottom: 25,
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
      width: 256,
      height: 52,
    },
    [breakpoints.up('lg')]: {
      lineHeight: lineHeights.md,
      width: 256,
      height: 52,
      marginBottom: 16,
    },
  },
  description: {
    flexGrow: 1,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.xs,
    textAlign: 'left',
    color: palette.grey['100'],
    opacity: 0.5,
    maxWidth: 190,
    [breakpoints.up('md')]: {
      maxWidth: 350,
    },
    [breakpoints.up('lg')]: {
      maxWidth: 350,
    },
    [breakpoints.up('xl')]: {
      maxWidth: 350,
    },
  },
  link: {
    color: palette.grey['100'],
    '&:hover': {
      textDecoration: 'none',
    },
  },
  partial: {
    margin: 0,
  },
  disclaimer: {
    width: 'auto',
    maxWidth: '100%',
    height: 11,
    objectFit: 'contain',
    [breakpoints.up('sm')]: {
      height: 20,
    },
    [breakpoints.up('lg')]: {
      height: 28,
    },
    [breakpoints.down(321)]: {
      height: 10,
    },
  },
}));
