import { ReactElement, useMemo } from 'react';
import { sortBy } from 'lodash';

import {
  DiscountCard,
  FreepackCard,
  IDiscountCardProps,
  IFreepackCardProps,
} from '../bonus-cards';
import { BonusType, IBonusesData } from '../../../../../graphql/bonuses/models/get-bonuses.models';
import {
  checkLabelEnabled,
} from '../../my-lenses/my-lenses-fitted-cards-list/utils/check-label-enabled';
import { IBonusCards } from '../my-bonuses.models';
import { IPromotionCardProps, PromotionCard } from '../../user-activity-items/promotions';
import { LoyaltyType } from '../../../../../graphql/promotions/models/get-promotions.models';
import { replaceWelcomeVoucher } from '../utils/replace-welcome-voucher';

interface IBonusesParams {
  bonuses?: IBonusesData;
  balance?: number;
  hasFittings: boolean;
  hasPurchases: boolean;
}

export const useGetBonuses = ({
  hasFittings, bonuses, balance, hasPurchases,
}: IBonusesParams): IBonusCards => {
  const result = useMemo(() => {
    let discounts: ReactElement<IDiscountCardProps>[] = [];
    let freepacks: ReactElement<IFreepackCardProps>[] = [];
    let promotions: ReactElement<IPromotionCardProps>[] = [];

    if (bonuses && balance !== undefined) {
      discounts = bonuses.voucherByAuthId
        .filter(({ type }) => type === BonusType.discount && hasFittings)
        .map(({ value }, index) => (
          <DiscountCard
            id={index}
            isActivationMode={!hasFittings && balance >= Number(value)}
            isAvailable={balance >= Number(value)}
            balance={balance}
            value={Number(value)}
          />
        ));

      freepacks = sortBy(bonuses.voucherByAuthId
        .filter(({ type, brands }) => type === BonusType.freepack && hasFittings
          && brands?.length), ['value'])
        .map(({ value, brands, packSize }, index) => (
          <FreepackCard
            id={index}
            brand={brands![0].product}
            productType={brands![0].productType}
            isAvailable={balance >= Number(value)}
            isActivationMode={!hasPurchases && balance >= Number(value)}
            packSize={packSize as number}
            noveltyLabel={brands![0].labels.find(checkLabelEnabled)?.name}
            balance={balance}
            value={Number(value)}
            fullName={brands![0].fullName}
          />
        ));

      // Add second welcome voucher if there is the first welcome voucher
      const promoBonuses = bonuses.voucherByAuthId.filter(
        voucher => voucher.type === BonusType.custom || voucher.type === BonusType.loyalty,
      );
      const firstVoucher = promoBonuses.find((
        promotion,
      ) => promotion.type === BonusType.loyalty && promotion.name === LoyaltyType.discountFirstPurchase);

      promotions = firstVoucher ? replaceWelcomeVoucher(promoBonuses)
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.id}${promotion?.endDate}`}
            promotion={promotion!}
            isAvailable={!(promotion?.name === LoyaltyType.discountSecondPurchase)}
            isFirstWelcomeVoucher={promotion?.name === LoyaltyType.discountFirstPurchase}
          />
        )) : promoBonuses.map((promotion) => (
        <PromotionCard
          id={`${promotion?.id}${promotion?.endDate}`}
          promotion={promotion}
          notClickable={promotion?.name === LoyaltyType.discountSecondPurchase}
        />
      ));
    }

    return {
      discounts,
      freepacks,
      promotions,
    };
  }, [
    bonuses,
    balance,
    hasFittings,
    hasPurchases,
  ]);

  return result;
};
