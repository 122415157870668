import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontSizes, lineHeights, fontWeights, borderRadiuses, palette,
}: Theme) => ({
  icon: {
    textAlign: 'center',
    '& svg': {
      width: 180,
      height: 109,
      [breakpoints.up('sm')]: {
        width: 228,
        height: 138,
      },
    },
  },
  title: {
    marginTop: 12,
    marginBottom: 16,
    textAlign: 'center',
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
    },
  },
  titlePartial: {
    display: 'block',
    [breakpoints.down(321)]: {
      display: 'inline',
    },
  },
  benefitsList: {
    listStyle: 'none',
    padding: 0,
    marginBottom: 32,
    [breakpoints.up('sm')]: {
      marginBottom: 64,
    },
  },
  benefitsItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      marginBottom: 20,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:nth-of-type(2)': {
      alignItems: 'flex-start',
    },
  },
  benefitIcon: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: borderRadiuses.xxl,
    background: palette.primary.main,
    [breakpoints.up('sm')]: {
      width: 48,
      height: 48,
      marginRight: 16,
    },
  },
  benefitText: {
    width: 'calc(100% - 48px)',
    [breakpoints.up('sm')]: {
      width: 'calc(100% - 54px)',
    },
  },
}));
