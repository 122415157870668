import { IEcpStore } from '../../rest/ecp-locator';
import { isClinicStore, isVendingMachine } from './define-store-types';
import markerClinicIconActive from '../../assets/images/map-markers/clinic-active.png';
import markerOpticsIconActive from '../../assets/images/map-markers/optic-active.png';
import markerVendingIconActive from '../../assets/images/map-markers/vending-active.png';
import markerClinicIcon from '../../assets/images/map-markers/clinic.png';
import markerOpticsIcon from '../../assets/images/map-markers/optic.png';
import markerVendingIcon from '../../assets/images/map-markers/vending.png';
import markerClinicIconCampaign from '../../assets/images/map-markers/clinic-active-campaign.png';
import markerOpticsIconCampaign from '../../assets/images/map-markers/optic-active-campaign.png';
import markerVendingIconCampaign from '../../assets/images/map-markers/vending-active-campaign.png';
import {
  MARKER_WIDTH,
  MARKER_HEIGHT,
  MARKER_WIDTH_ACTIVE,
  MARKER_HEIGHT_ACTIVE,
} from '../../constants/ecp-locator';

export const getMarkerIcon = ({ type, code }: IEcpStore, codeToCheck: string) => {
  let url: string;
  const isMarkerActive = codeToCheck === code;
  const scaledSize = isMarkerActive
    ? new window.google.maps.Size(MARKER_WIDTH_ACTIVE, MARKER_HEIGHT_ACTIVE)
    : new window.google.maps.Size(MARKER_WIDTH, MARKER_HEIGHT);

  switch (true) {
    case isClinicStore(type):
      url = isMarkerActive ? markerClinicIconActive : markerClinicIcon;
      break;
    case isVendingMachine(type):
      url = isMarkerActive ? markerVendingIconActive : markerVendingIcon;
      break;
    default:
      url = isMarkerActive ? markerOpticsIconActive : markerOpticsIcon;
      break;
  }

  return {
    url,
    scaledSize,
  };
};

export const getCampaignMarkerIcon = ({ type, code }: IEcpStore, codeToCheck: string) => {
  let url: string;
  const isMarkerActive = codeToCheck === code;
  const scaledSize = isMarkerActive
    ? new window.google.maps.Size(MARKER_WIDTH_ACTIVE, MARKER_HEIGHT_ACTIVE)
    : new window.google.maps.Size(MARKER_WIDTH, MARKER_HEIGHT);

  switch (true) {
    case isClinicStore(type):
      url = markerClinicIconCampaign;
      break;
    case isVendingMachine(type):
      url = markerVendingIconCampaign;
      break;
    default:
      url = markerOpticsIconCampaign;
      break;
  }

  return {
    url,
    scaledSize,
  };
};
