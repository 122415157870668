import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PhoneStep } from './registration-steps/phone-step';
import { ConfirmationCodeStepDescription } from './registration-steps/confirmation-code-step/confirmation-code-step-description';
import { ConfirmationCodeStep } from './registration-steps/confirmation-code-step';
import { NameStep } from './registration-steps/name-step';
import { PasswordStep } from './registration-steps/password-step';
import { LensesExperienceStep } from './registration-steps/lenses-experience-step';
import { EmailStep } from './registration-steps/email-step';
import { IStepProps } from '../../../common/stepper';

export const useRegistrationSteps = (): IStepProps[] => {
  const intl = useIntl();

  return [
    {
      title: (
        <span data-testid="phone-step-title">
          <FormattedMessage id="registration.phoneStep.title" />
        </span>
      ),
      description: intl.formatMessage(
        { id: 'registration.phoneStep.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      ),
      StepComponent: PhoneStep,
      id: 'phone',
    },
    {
      title: (
        <span data-testid="confirmation-code-step-title">
          <FormattedMessage id="registration.confirmationCodeStep.title" />
        </span>
      ),
      description: ConfirmationCodeStepDescription,
      StepComponent: ConfirmationCodeStep,
      id: 'confirmationCode',
      disableNextButton: true,
    },
    {
      title: (
        <span data-testid="name-step-title">
          <FormattedMessage id="registration.nameStep.title" />
        </span>
      ),
      StepComponent: NameStep,
      id: 'name',
    },
    {
      title: (
        <span data-testid="lenses-experience-step-title">
          <FormattedMessage id="registration.lensesExperienceStep.title" />
        </span>
      ),
      description: <FormattedMessage id="registration.lensesExperienceStep.description" />,
      StepComponent: LensesExperienceStep,
      id: 'lensesExperience',
    },
    {
      title: (
        <span data-testid="email-step-title">
          <FormattedMessage id="registration.emailStep.title" />
        </span>
      ),
      description: <FormattedMessage id="registration.emailStep.description" />,
      StepComponent: EmailStep,
      id: 'email',
    },
    {
      title: (
        <span data-testid="password-step-title">
          <FormattedMessage id="registration.passwordStep.title" />
        </span>
      ),
      description: <FormattedMessage id="registration.passwordStep.description" />,
      StepComponent: PasswordStep,
      id: 'password',
      hintText: <FormattedMessage id="registration.passwordStep.requiredAgeNotification" />,
      nextButtonLabel: <FormattedMessage id="common.registrationButton.label" />,
    },
  ];
};
