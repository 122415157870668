import { ExternalLinks } from '../../../../../constants';

export const wikiItems = [
  {
    title: 'wiki.card.everyDay.title',
    description: [
      {
        name: 'wiki.card.everyDay.description.gadgets',
        link: ExternalLinks.visionAndGadgets,
      },
      {
        name: 'wiki.card.everyDay.description.travel',
        link: ExternalLinks.visionAndTravel,
      },
      {
        name: 'wiki.card.everyDay.description.makeup',
        link: ExternalLinks.visionAndMakeup,
      },
    ],
  },
  {
    title: 'wiki.card.types.title',
    description: [
      {
        name: 'wiki.card.types.description.daily',
        link: ExternalLinks.dailyLenses,
      },
      {
        name: 'wiki.card.types.description.weekly',
        link: ExternalLinks.weeklyLenses,
      },
    ],
  },
  {
    title: 'wiki.card.care.title',
    description: [
      {
        name: 'wiki.card.care.description.clean',
        link: ExternalLinks.cleanAndCare,
      },
      {
        name: 'wiki.card.care.description.putIn',
        link: ExternalLinks.putInLenses,
      },
      {
        name: 'wiki.card.care.description.takeOut',
        link: ExternalLinks.takeOutLenses,
      },
      {
        name: 'wiki.card.care.description.tips',
        link: ExternalLinks.usefulTips,
      },
    ],
    horizontal: true,
  },
  {
    title: 'wiki.card.fit.title',
    description: [
      {
        name: 'wiki.card.fit.description.covid',
        link: ExternalLinks.covidRestrictions,
      },
      {
        name: 'wiki.card.fit.description.fitting',
        link: ExternalLinks.lensesFitting,
      },
    ],
  },
  {
    title: 'wiki.card.facts.title',
    description: [
      {
        name: 'wiki.card.fit.description.myths',
        link: ExternalLinks.commonMyths,
      },
      {
        name: 'wiki.card.fit.description.benefits',
        link: ExternalLinks.lensesBenefits,
      },
    ],
  },
];
