import { EcpStoreType } from '../../../rest/ecp-locator';

export type ServiceTypeFilters = EcpStoreType.retailer | EcpStoreType.fitter | EcpStoreType.c2cActivated | null;

export enum SpecializationTypeFilters {
  astigmatism = 'astigmatism',
  presbyopia = 'presbyopia',
  children = 'children',
}

export enum ShopTypeFilters {
  optics = 'optics',
  clinics = 'clinics',
  vendings = 'vendings',
}

export type TFiltersData = {
  services: ServiceTypeFilters;
  specializations: SpecializationTypeFilters[];
  shops: ShopTypeFilters[];
  isFiltersChanged: boolean;
  isFirstTimeLoading: boolean;
};
