import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { subYears } from 'date-fns';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';

import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../../common/stepper';
import { yup } from '../../../../../../../services/yup';
import { toDate } from '../../../../../../../utils/date/to-date';
import { convertDateToString } from '../../../../../../../utils/date/convert-date-to-string';
import { IStepsState } from '../survey-step.models';
import { UPDATE_VISION_PROFILE } from '../../../../../../../graphql/profile/mutations';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { GET_VISION_PROFILE } from '../../../../../../../graphql/profile/queries/get-vision-profile';
import { IVisionProfileData } from '../../../../../../../graphql/profile/models/get-vision-profile.models';
import { isChangeDateAllowed } from '../../../../../../../utils/date/is-vpdate-allowed';
import { BirthdayView } from '../../../vision-profile-views/birthday-view';
import { TFormValues } from '../../../vision-profile-views/birthday-view/birthday-view-form';

export const BirthdayStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onNextButtonDisabled,
  stepsState,
}, ref): JSX.Element => {
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const intl = useIntl();
  const { data: visionProfileData, updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const { dateOfBirth: dateOfBirthState } = stepsState as IStepsState;
  const canUpdateDateOfBirth = isChangeDateAllowed(visionProfileData?.visionProfile);
  const maxDateOfBirth = useMemo(() => toDate(subYears(new Date(), 18)), [])!;

  const defaultValues = useMemo(() => dateOfBirthState || {
    dateOfBirth: null,
  }, []);

  const validationSchema = useMemo(() => yup
    .object({
      dateOfBirth: yup
        .date()
        .nullable()
        .required(intl.formatMessage({ id: 'vp.dateOfBirth.birthdayInput.errors.required' }))
        .max(
          maxDateOfBirth,
          intl.formatMessage({ id: 'vp.dateOfBirth.birthdayInput.errors.max' }),
        ),
    }),
  [intl],
  );

  const handleUpdateBirthday = async ({ dateOfBirth }: TFormValues) => {
    const dateOfBirthCurrentString = convertDateToString(dateOfBirth!);
    const dateOfBirthString = convertDateToString(dateOfBirthState?.dateOfBirth);

    if (dateOfBirthCurrentString === dateOfBirthString) {
      onGoNextSuccess({ dateOfBirth: dateOfBirthString });
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              dateOfBirth: dateOfBirthCurrentString,
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      removeActionInProgress();
      onGoNextSuccess({ dateOfBirth: patchVisionProfile.dateOfBirth });
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }

      removeActionInProgress();
    }
  };

  const handleValidateDependencies = (isValid: boolean) => {
    onNextButtonDisabled(!isValid);
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <BirthdayView
      ref={formRef}
      canUpdateDateOfBirth={canUpdateDateOfBirth}
      formProps={{
        onSubmit: handleUpdateBirthday,
        onValidateDependencies: handleValidateDependencies,
        defaultValues,
        validationSchema,
        validationMode: 'onChange',
      }}
    />
  );
});
