import {
  KeyboardEvent as KeyboardSyntheticEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { cx } from '@emotion/css';

import { DISTANCE_UNIT } from '../../../../constants/ecp-locator';
import { useStyles } from './ecp-store.styles';
import { IEcpStoreProps } from './ecp-store.models';
import { useRoundDistance } from '../../../../hooks/use-round-distance';
import { roundRating } from '../../../../utils/ecp-locator/round-rating';
import { useStoreByType } from '../../../../hooks/use-store-by-type';
import { Icon, IconTypes } from '../../../common';
import { isVendingMachine } from '../../../../utils/ecp-locator/define-store-types';

export const EcpStore = ({
  storeData,
  onStoreSelect,
  storeCode,
  onScrollToStore,
  contentStyles,
  contentPartialStyles,
  titleStyles,
}: IEcpStoreProps): JSX.Element => {
  const classes = useStyles();
  const {
    name,
    rating,
    type,
    distance,
    street,
    openinghours,
    phonenumber,
    code,
    detailedLocation,
  } = storeData;

  const phoneEl = useRef<HTMLAnchorElement>(null);
  const titleEl = useRef<HTMLHeadingElement>(null);

  const address = isVendingMachine(type) && detailedLocation?.length
    ? `${street}, ${detailedLocation}`
    : street;

  useEffect(() => {
    onScrollToStore(code, titleEl, storeCode);
  }, [storeCode]);

  const handleKeyPress = useCallback((event: KeyboardSyntheticEvent) => {
    if (phoneEl.current !== event.target
      && (event.code === 'Enter' || event.code === 'Space')) {
      event.preventDefault();

      onStoreSelect(storeData);
    }
  }, [onStoreSelect]);

  const handleClick = useCallback((event: SyntheticEvent) => {
    if (phoneEl.current !== event.target) {
      onStoreSelect(storeData);
    }
  }, [onStoreSelect]);

  return (
    <div
      className={classes.root}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={0}
      data-testid="store"
    >
      <span
        className={classes.arrowRight}
        data-testid="arrow-right-button"
        aria-hidden="true"
      >
        <Icon type={IconTypes.arrowRight} />
      </span>
      <h2
        data-testid="store-title"
        className={titleStyles || classes.title}
        ref={titleEl}
        tabIndex={-1}
      >
        {name}
      </h2>
      <div className={contentStyles || classes.content}>
        <div className={contentPartialStyles}>
          <p data-testid="store-rating-bar" className={classes.ratingWrapper}>
            {rating && (
              <span
                data-testid="store-rating"
                className={cx(classes.rating, classes.pointSeparator)}
              >
                {roundRating(rating)}
              </span>
            )}
            <span
              data-testid="store-type"
              className={classes.pointSeparator}
            >
              {useStoreByType(type)}
            </span>
            {distance && (
              <span data-testid="store-distance">
                {`${useRoundDistance(distance)} ${DISTANCE_UNIT}`}
              </span>
            )}
          </p>
          <p data-testid="store-address" className={classes.street}>{address}</p>
        </div>
        <div className={contentPartialStyles}>
          <p
            data-testid="store-hours"
            className={classes.hours}
          >
            {openinghours}
          </p>
          <a
            ref={phoneEl}
            data-testid="store-phone"
            href={`tel:${phonenumber}`}
            className={classes.phone}
          >
            {phonenumber}
          </a>
        </div>
      </div>
    </div>
  );
};
