import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    position: 'relative',
    zIndex: 1, // to make boxshadow visible on the map
    boxShadow: `0px 4px 6px -1px rgba(26, 32, 44, 0.1),
                0px 2px 4px -1px rgba(26, 32, 44, 0.06)`,
    background: palette.common.white,
  },
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 68,
    [breakpoints.up('md')]: {
      minHeight: 88,
    },
    [breakpoints.up('lg')]: {
      minHeight: 120,
    },
  },
  primaryNavContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: spacing(1.5),
    [breakpoints.up('md')]: {
      gap: spacing(2.5),
    },
    [breakpoints.up('lg')]: {
      gap: spacing(4),
    },
  },
  secondaryNavContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: spacing(1.5),
    [breakpoints.up('md')]: {
      gap: spacing(2.5),
    },
    [breakpoints.up('lg')]: {
      gap: spacing(4),
    },
  },
  logoWrapper: {
    display: 'flex',
  },
  sup: {
    position: 'relative',
    top: '-0.4em',
  },
}));
