import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfirmationModal } from '../../../../../../common/confirmation-modal';

import { IDeleteReminderModalProps } from './delete-reminder-modal.models';

export const DeleteReminderModal = (props: IDeleteReminderModalProps): JSX.Element => (
  <ConfirmationModal
    {...props}
    data-testid="reminder-confirm-delete-modal"
    title={(
      <FormattedMessage
        id="reminders.modal.form.deleteReminderModal.title"
      />
    )}
    content={(
      <FormattedMessage
        id="reminders.modal.form.deleteReminderModal.description"
      />
    )}
    confirmButtonLabel={(
      <FormattedMessage
        id="reminders.modal.form.deleteReminderModal.confirmButton.label"
      />
    )}
    rejectButtonLabel={(
      <FormattedMessage
        id="reminders.modal.form.deleteReminderModal.rejectButton.label"
      />
    )}
  />
);
