import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { IConsumerLenses } from '../../../../graphql/lenses/models/get-lenses.models';
import { GET_LENSES } from '../../../../graphql/lenses/queries/get-lenses';
import { useMyLocation } from '../../../../hooks/use-my-location';
import { isUserFitted } from '../../../../utils/lenses/is-user-fitted';
import { IFittingProps } from '../ecp-locator-settings.models';

export const withFittingProps = <T extends unknown>(Component: FC<T>) => (
  props: T & IFittingProps,
): JSX.Element => {
  const { data, loading, error } = useQuery<IConsumerLenses>(GET_LENSES, { fetchPolicy: 'network-only' });
  const { isLocationDefined } = useMyLocation();
  const isFitted = isUserFitted(data);
  const isFittingsLoaded = !!data && !error;

  return (
    <Component
      {...props}
      isLoading={loading && isLocationDefined}
      isError={error}
      isFitted={isFitted}
      isFittingsLoaded={isFittingsLoaded}
    />
  );
};
