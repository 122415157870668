import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies,
  spacing,
  breakpoints,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  footer: {
    backgroundColor: palette.info.dark,
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    [breakpoints.up('md')]: {
      paddingBottom: spacing(5),
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(7),
    },
  },
  supportContainer: {
    display: 'grid',
    rowGap: spacing(2),
    paddingBottom: spacing(1.75),
    [breakpoints.up('md')]: {
      paddingBottom: spacing(3.75),
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: spacing(2.5),
      paddingBottom: spacing(4.25),
    },
  },
  questions: {
    display: 'block',
    fontFamily: `${fontFamilies.primary}`,
    color: palette.common.white,
    margin: `0 auto ${spacing(2.5)}`,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.lg,
    },
  },
}));
