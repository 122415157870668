import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { InfoLabel } from '../info-label';
import { Icon, IconTypes } from '../icon';

export const MobileApplicationInfoLabel = (): JSX.Element => {
  const intl = useIntl();

  return (
    <InfoLabel
      fullWidth
      icon={(
        <Icon
          type={IconTypes.mobileVertical}
        />
      )}
      description={intl.formatMessage(
        { id: 'mobileAppInfoLabel.description' },
        { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
      )}
    />
  );
};
