import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { ContentWrapper } from '../content-wrapper';
import { useStyles } from './program.styles';
import { BackButton } from '../../../common/back-button';
import { Accordion } from '../../../common/accordion';
import { useProgramItemsContent, IProgramItemConfig } from './items';
import { IconTypes } from '../../../common';
import { setIsFreepacks } from '../../../../graphql/configuration/configuration.cache';

const itemsContent = (isEnabled: boolean): IProgramItemConfig[] => ([
  {
    id: isEnabled ? 'myAcuvueProgram.getPoints.withFreepacks' : 'myAcuvueProgram.getPoints',
    icon: IconTypes.aboutProgram,
  },
  {
    id: isEnabled ? 'myAcuvueProgram.spendPoints.withFreepacks' : 'myAcuvueProgram.spendPoints',
    icon: IconTypes.percent,
  },
  { id: 'myAcuvueProgram.myLenses', icon: IconTypes.lenses },
  { id: 'myAcuvueProgram.buyLenses', icon: IconTypes.map },
  { id: 'myAcuvueProgram.fitLenses', icon: IconTypes.visibleSecret },
  { id: 'myAcuvueProgram.askExpert', icon: IconTypes.firstAidKit },
]);

export const Program = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { getProgramItemsContent } = useProgramItemsContent();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'myAcuvueProgramPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'myAcuvueProgramPage.description' })}
        />
      </Helmet>
      <div className={classes.root}>
        <ContentWrapper>
          <BackButton />
          <h1 className={classes.title}>
            <FormattedMessage
              id="myAcuvueProgram.title"
            />
          </h1>
          <Accordion
            items={getProgramItemsContent(itemsContent(isFreepacksEnabled), classes)}
          />
        </ContentWrapper>
      </div>
    </>
  );
};
