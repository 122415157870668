import React from 'react';

import { Card, CardTypes } from '../../card';
import { IWhyCard } from './why-card.models';
import { useStyles } from './why-card.styles';

export const WhyCard = ({ imageSource, description, footer }: IWhyCard): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      type={CardTypes.primary}
      data-testid="why-card"
    >
      <div className={classes.content}>
        <img
          className={classes.image}
          src={imageSource}
          data-testid="why-card-logo"
          alt=""
        />
        <p className={classes.description}>
          {description}
        </p>
      </div>
      {footer && (
        <div className={classes.footer}>
          {footer}
        </div>
      )}
    </Card>
  );
};
