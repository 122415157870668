import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { InternalLinks } from '../../../../../constants';
import { VisionProfile as VisionProfileView } from './vision-profile';
import { IVisionProfileProps } from './vision-profile.models';

export const VisionProfile = ({ surveySteps, ...props }: IVisionProfileProps): JSX.Element => {
  const navigate = useNavigate();

  const externalState = useMemo(() => ({
    surveySteps,
  }), []);

  const handleVisionProfileDone = () => {
    navigate(InternalLinks.visionProfile, { state: { hasPreviousLocation: true } });
  };

  return (
    <VisionProfileView
      {...props}
      onDone={handleVisionProfileDone}
      externalState={externalState}
    />
  );
};
