import { FC } from 'react';
import { cx, ClassNamesArg } from '@emotion/css';
import { getThemeProps } from '@mui/styles';

import { IBonusCardProps } from './bonus-card.models';
import { useStyles } from './bonus-card.styles';
import { Card, CardTypes } from '../card';
import { NoveltyLabel } from '../novelty-label';
import { Icon, IconSizes, IconTypes } from '../icon';

export const BonusCard: FC<IBonusCardProps> = ({
  isAvailable,
  cardTitle,
  bonusImage,
  pointsAmount,
  noveltyLabel,
  onClick,
  footer,
  description,
  externalContentClasses,
  href,
  notClickable,
  ...props
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      {...props}
      {...getThemeProps}
      className={cx(classes.root,
        { [classes.isAvailable]: isAvailable },
        { [classes.clickable]: isAvailable && !notClickable },
      )}
      href={href}
      onClick={onClick}
      type={CardTypes.bonus}
    >
      <div className={cx(classes.cardContent,
        { [externalContentClasses as string]: externalContentClasses } as ClassNamesArg,
      )}>
        {noveltyLabel && (
          <NoveltyLabel
            className={classes.noveltyLabel}
            label={noveltyLabel}
          />
        )}
        {bonusImage && (
          <div
            className={classes.bonusImage}
            data-testid="bonus-card-image"
          >
            {bonusImage}
          </div>
        )}
        {cardTitle && (
          <h3 className={classes.title}>
            {cardTitle}
          </h3>
        )}
        {description && (
          <p
            className={classes.description}
            data-testid="bonus-card-description"
          >
            {description}
          </p>
        )}
        {pointsAmount && (
          <p
            className={cx(classes.pointsAmount,
              { [classes.pointsAmountNotAvailable]: !isAvailable },
            )}
            data-testid="bonus-card-amount"
          >
            {!isAvailable && (
              <Icon
                type={IconTypes.aRoundedBlue}
                size={IconSizes.xs}
                className={classes.pointsNotAvailableIcon}
              />
            )}
            {pointsAmount}
          </p>
        )}
        {footer}
      </div>
    </Card>
  );
};
