import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ModalProvider from 'mui-modal-provider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AppTranslationSettings } from '../app-translation-settings';
import { SnackbarProvider } from '../snackbar-provider';
import theme from '../../../themes/default';
import { IAppSettingsProps } from './app-settings.models';
import { graphqlClient } from '../../../graphql/graphql-client';
import { ApiSettings } from '../api-settings';
import { ScrollToTop } from '../scroll-to-top';
import { PreloaderProvider } from '../preloader-provider';
import '../../../fonts';
import { RefreshTokenProvider } from '../refresh-token';
import { localText } from '../../../constants';
import { ConfigurationSettings } from '../configuration-settings';

export const AppSettings = ({ children }: IAppSettingsProps) => (
  <ApolloProvider client={graphqlClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppTranslationSettings>
          <RefreshTokenProvider>
            <PreloaderProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ru}
                localeText={localText}
              >
                <SnackbarProvider>
                  <Router>
                    <ModalProvider>
                      <ScrollToTop />
                      <ApiSettings>
                        <ConfigurationSettings>
                          <CssBaseline />
                          {children}
                        </ConfigurationSettings>
                      </ApiSettings>
                    </ModalProvider>
                  </Router>
                </SnackbarProvider>
              </LocalizationProvider>
            </PreloaderProvider>
          </RefreshTokenProvider>
        </AppTranslationSettings>
      </ThemeProvider>
    </StyledEngineProvider>
  </ApolloProvider>
);
