import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../../../common/button';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { Modal, ResponsiveWidth } from '../../../../../common/modal';
import { ReminderForm } from '../reminder-form';
import { ICreateReminderModalProps } from './create-reminder-modal.models';

export const CreateReminderModal = forwardRef<IFormImperativeHandleProps, ICreateReminderModalProps>(({
  onUpdateReminder, options, onSubmitModalClose, ...props
}, ref): JSX.Element => (
  <Modal
    {...props}
    data-testid="create-reminder-modal"
    responsiveWidth={ResponsiveWidth.md}
    title={(
      <FormattedMessage
        id="reminders.modal.create.title"
      />
    )}
    content={(
      <ReminderForm
        ref={ref}
        options={options}
        onModalClose={onSubmitModalClose}
      />
    )}
    footer={(
      <Button
        fullWidth
        variant="contained"
        onClick={onUpdateReminder}
        data-testid="reminder-add-button"
      >
        <FormattedMessage
          id="reminders.modal.create.createButton.label"
        />
      </Button>
    )}
  />
));
