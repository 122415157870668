import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { EyeParamsType } from '../../../../graphql/lenses/models/get-lenses.models';

export const useFormattedValue = () => {
  const intl = useIntl();

  const getFormattedValue = useCallback((
    value: string, property: EyeParamsType,
  ): string => {
    let resultValue: string;

    switch (property) {
      case EyeParamsType.baseCurve:
      case EyeParamsType.power:
      case EyeParamsType.cylinder:
        if (property === EyeParamsType.power && value.slice(0, 1) !== '-') {
          resultValue = `+${intl.formatNumber(Number(value), { minimumFractionDigits: 1 })}`;
        } else {
          resultValue = intl.formatNumber(Number(value), { minimumFractionDigits: 1 });
        }
        break;
      default:
        resultValue = value;
        break;
    }

    return resultValue;
  }, [intl]);

  return { getFormattedValue };
};
