import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';

import { Logo } from '../../../../common/logo';
import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './header.styles';

export const Header = (): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <header
      className={classes.root}
      data-testid="header-secondary"
    >
      <ContentWrapper>
        <div className={classes.content}>
          <Link className={classes.logoLink} to="/">
            <Logo
              size={isTablet ? 'lg' : 'sm'}
            />
          </Link>
        </div>
      </ContentWrapper>
    </header>
  );
};
