import {
  FC,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ContentWrapper } from '../content-wrapper';
import { Box, ResponsiveSize } from '../../../common/box';
import { Stepper } from '../../../common/stepper';
import { profileDeleteSteps } from './profile-delete.steps';
import { InternalLinks } from '../../../../constants';
import { Logo } from '../../../common/logo';
import { useStyles } from './profile-delete.styles';
import { removeTokenFromStorage } from '../../../../utils/user';
import { isLoggedIn } from '../../../../graphql/user/user.cache';


export const ProfileDelete: FC = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const classes = useStyles();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const handleProfileDeleteDone = () => {
    navigate(InternalLinks.home, { state: { hasPreviousLocation: false } });
  };

  useEffect(() => {
    removeTokenFromStorage();
    isLoggedIn(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'resetPasswordPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'resetPasswordPage.description' })}
        />
      </Helmet>
      <div className={classes.content}>
        <ContentWrapper withoutPaddings>
          <Link className={classes.logoLink} to="/">
            <Logo
              size={isTablet ? 'lg' : 'sm'}
            />
          </Link>
          <Box
            responsiveSize={ResponsiveSize.xs}
            center
            externalRootClasses={classes.box}
          >
            <Stepper
              disableStepIndicators
              steps={profileDeleteSteps}
              onDone={handleProfileDeleteDone}
            />
          </Box>
        </ContentWrapper>
      </div>
    </>
  );
};
