import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
}: Theme) => ({
  messageContainer: {
    marginTop: 12,
  },
  messagePartial: {
    margin: 0,
    color: palette.grey['800'],
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.xs,
  },
  messageLink: {
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: `1px dotted ${palette.primary.main}`,
    },
  },
}));
