import React, {
  forwardRef,
  MutableRefObject,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { addDays } from 'date-fns';

import { IDailyLensesFormProps, TFormValues } from './daily-lenses-form.models';
import { DailyLensesForm as DailyLensesFormView } from './daily-lenses-form';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { secondNotificationItems } from './form-select-items';
import { convertDateToString } from '../../../../../../../utils/date/convert-date-to-string';
import { convertStringToDate } from '../../../../../../../utils/date/convert-string-to-date';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { UPDATE_REMINDER } from '../../../../../../../graphql/reminders/mutations/create-edit-reminder';
import { LENSES_AMOUNT_MAX, usageFrequencyValueObj } from '../reminder-form.constants';
import { toDate } from '../../../../../../../utils/date/to-date';
import { useLensesAmountValidation } from '../utils/use-lenses-amount-validation';
import { getRecalculatedLensesAmount } from './utils/get-recalculated-lenses-amount';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import {
  FrequencyOfUsageTypes,
  NotificationType,
} from '../../../../../../../graphql/reminders/models/reminder.models';

export const DailyLensesForm = forwardRef<IFormImperativeHandleProps, IDailyLensesFormProps>(({
  options, onModalClose,
}, ref): JSX.Element => {
  const { consumerLensesId, packSize, reminderProps, eyesAmount, onUpdateLenses } = options;
  const dailyLensesFormRef = ref as MutableRefObject<IFormImperativeHandleProps>;
  const validationSchema = useLensesAmountValidation();
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const [updateReminder] = useMutation(UPDATE_REMINDER);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const [secondNotificationOptions, setSecondNotificationOptions] = useState(secondNotificationItems);

  const defaultValues = useMemo(() => {
    const today = toDate(new Date().toDateString())!;

    if (reminderProps) {
      const { amountOfLenses, buyNewPackAt, startWearingAt, frequencyOfUsage } = reminderProps;
      const currentAmountOfLenses = getRecalculatedLensesAmount({
        amountOfLenses,
        startWearingAt,
        eyesAmount,
        frequencyOfUsage,
      });

      return {
        ...reminderProps,
        amountOfLenses: currentAmountOfLenses,
        startWearingAt: convertDateToString(today),
        buyNewPackAt: convertStringToDate(buyNewPackAt),
      };
    }

    return {
      amountOfLenses: '0',
      frequencyOfUsage: FrequencyOfUsageTypes.everyDay,
      buyNewPackAt: today,
      firstNotification: NotificationType.threeDays,
      secondNotification: NotificationType.none,
      startWearingAt: convertDateToString(today),
      setupAt: convertDateToString(today),
    };
  }, []);

  const handleSubmit = async (values: TFormValues) => {
    addActionInProgress();

    try {
      await makeCancellablePromise(updateReminder({
        variables: {
          consumerLensesId,
          reminder: {
            ...values,
            buyNewPackAt: convertDateToString(values.buyNewPackAt!),
          },
        },
      }));

      await makeCancellablePromise(onUpdateLenses());

      onModalClose();

    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  const handleSecondNotificationChange = () => {
    const { firstNotification, secondNotification } = dailyLensesFormRef.current.getValues();
    const index = secondNotificationItems.findIndex(({ value }) => value === firstNotification);
    const newSecondNotificationOptions = index < 0
      ? secondNotificationItems
      : secondNotificationItems.slice(0, index);

    const isSelectValueChange = !newSecondNotificationOptions
      .some(({ value }) => value === secondNotification);

    setSecondNotificationOptions(newSecondNotificationOptions);

    if (isSelectValueChange) {
      dailyLensesFormRef.current.setValue(
        'secondNotification',
        newSecondNotificationOptions[newSecondNotificationOptions.length - 1].value,
        { shouldDirty: true },
      );
    }
  };

  const handleBuyDateChange = () => {
    const week = 7;
    const {
      amountOfLenses,
      frequencyOfUsage,
    } = dailyLensesFormRef.current.getValues() as TFormValues;

    const daysAmount = Math.floor(
      Number(amountOfLenses) / eyesAmount * week / usageFrequencyValueObj[frequencyOfUsage!],
    );

    const newBuyPackDate = toDate(addDays(new Date(), daysAmount));

    dailyLensesFormRef.current.setValue('buyNewPackAt', newBuyPackDate, {
      shouldDirty: true,
    });
  };

  const handleAddLensesClick = () => {
    const { amountOfLenses } = dailyLensesFormRef.current.getValues();
    let newAmount = Number(amountOfLenses) + packSize;

    if (newAmount > Number(LENSES_AMOUNT_MAX)) {
      newAmount = Number(LENSES_AMOUNT_MAX);
    }

    dailyLensesFormRef.current.setValue('amountOfLenses', String(newAmount), {
      shouldValidate: true,
      shouldDirty: true,
    });

    // setValue callback in react-hook-form doesn't call onChange event in textfield
    handleBuyDateChange();
  };

  return (
    <DailyLensesFormView
      ref={ref}
      packSize={packSize}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      secondNotificationOptions={secondNotificationOptions}
      onSubmit={handleSubmit}
      onAddLensesClick={handleAddLensesClick}
      onBuyDateDependency={handleBuyDateChange}
      onSecondNotificationDependency={handleSecondNotificationChange}
    />
  );
});
