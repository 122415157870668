import React from 'react';
import { cx } from '@emotion/css';

import { IHelpBlockProps } from './help-block.models';
import { Button } from '../button';

import { useStyles } from './help-block.styles';

export const HelpBlock = (props: IHelpBlockProps): JSX.Element => {
  const {
    title,
    description,
    content,
    action,
    hintTexts,
    titleClasses,
    descriptionClasses,
    rootClasses,
    actionClasses,
    hintTextClasses,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={cx(classes.section, rootClasses)}
      data-testid="footer-help-block"
    >
      <h2
        className={cx(classes.text, classes.title, titleClasses)}
        data-testid="footer-help-title"
      >
        {title}
      </h2>
      {content}
      <p className={cx(classes.text, classes.description, descriptionClasses)}>
        {description}
      </p>
      <Button
        {...action}
        className={cx(classes.button, actionClasses)}
        variant="contained"
        data-testid="footer-help-action"
      />
      <p className={cx(classes.hintText, hintTextClasses)}>
        {hintTexts}
      </p>
    </div>
  );
};
