import { cx } from '@emotion/css';

import { useStyles } from './campaign-icon.styles';
import { ICampaignIconProps } from './campaign-icon.models';

export const CampaignIcon = ({ isLessTopSpace }: ICampaignIconProps): JSX.Element => {
  const classes = useStyles();

  return (
    <span
      data-testid="campaign-wrapper-footer"
      aria-hidden="true"
      className={cx(classes.campaign,
        { [classes.lessTopSpace]: isLessTopSpace },
      )}
    />
  );
};
