import React from 'react';
import { cx } from '@emotion/css';

import { IProps } from './content-wrapper.models';
import { useStyles } from './content-wrapper.styles';

export const ContentWrapper = ({ children, withoutPaddings }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      data-testid="content-wrapper"
      className={cx(
        classes.root,
        {
          [classes.rootWithoutPaddings]: withoutPaddings,
        },
      )}
    >
      {children}
    </div>
  );
};
