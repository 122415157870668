import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, siteMaxWidth, spacing,
}: Theme) => ({
  root: {
    width: 'calc(100% - 32px)',
    maxWidth: siteMaxWidth,
    margin: '20px auto 0',
    [breakpoints.up('lg')]: {
      width: 'unset',
      paddingLeft: spacing(2.5),
      paddingRight: spacing(2.5),
    },
    [breakpoints.up('sm')]: {
      marginTop: 24,
    },
  },
}));
