import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../../common/stepper';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';

import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { yup } from '../../../../../../../services/yup';
import { IStepsState } from '../survey-step.models';
import { UPDATE_VISION_PROFILE } from '../../../../../../../graphql/profile/mutations';
import { GET_VISION_PROFILE } from '../../../../../../../graphql/profile/queries/get-vision-profile';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { IVisionProfileData } from '../../../../../../../graphql/profile/models/get-vision-profile.models';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { TimeOutsideDurationView } from '../../../vision-profile-views/time-outside-duration-view';
import { TTimeOutsideDurationValues } from '../../../vision-profile-views/time-outside-duration-view/time-outside-duration-form';

export const TimeOutsideDurationStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
  onNextButtonDisabled,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { timeOutsideDuration: timeOutsideDurationState } = stepsState as IStepsState;
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => timeOutsideDurationState || {
    timeOutsideDuration: null,
  }, []);

  const validationSchema = useMemo(() => yup.object({
    timeOutsideDuration: yup.string().nullable().required(intl.formatMessage({
      id: 'common.errors.requiredPickOne',
    })),
  }), [intl]);

  const handleValidatieDependencies = (isValid: boolean) => {
    onNextButtonDisabled(!isValid);
  };

  const handleUpdateTimeOutsideDuration = async ({
    timeOutsideDuration: timeOutsideDurationCurrent,
  }: TTimeOutsideDurationValues) => {
    const timeOutsideDuration = timeOutsideDurationState?.timeOutsideDuration;

    if (timeOutsideDurationCurrent === timeOutsideDuration) {
      onGoNextSuccess({ timeOutsideDuration });
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              timeOutsideDuration: timeOutsideDurationCurrent,
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      removeActionInProgress();
      onGoNextSuccess({ timeOutsideDuration: patchVisionProfile.timeOutsideDuration });
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }

      removeActionInProgress();
    }
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <TimeOutsideDurationView
      ref={formRef}
      formProps={{
        onSubmit: handleUpdateTimeOutsideDuration,
        onValidateDependencies: handleValidatieDependencies,
        defaultValues,
        validationSchema,
        validationMode: 'onChange',
      }}
    />
  );
});
