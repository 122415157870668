import { differenceInDays, startOfDay } from 'date-fns';

interface IRecalculatedParams {
  amountOfLenses: number;
  eyesAmount: number;
  startWearingAt: string;
}

export const getRecalculatedLensesAmount = ({
  amountOfLenses, eyesAmount, startWearingAt,
}: IRecalculatedParams): string => {
  const twoWeeks = 14;
  const daysDifference = differenceInDays(startOfDay(new Date()), new Date(startWearingAt));
  const newAmountOfLenses = amountOfLenses - Math.floor(daysDifference / twoWeeks) * eyesAmount;

  return String(newAmountOfLenses);
};
