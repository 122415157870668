import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { IPhoneStepFormProps } from './phone-step-form.models';
import { Button } from '../../../../../../common';
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration';

export const PhoneStepForm = forwardRef<IFormImperativeHandleProps, IPhoneStepFormProps>(({
  onSubmit,
  validationSchema,
  defaultValues,
  isTimerRunning,
  time,
}, ref): JSX.Element => {
  const intl = useIntl();
  const buttonLabel = (!!time && isTimerRunning)
    ? <FormattedMessage
      id="registration.confirmationCodeStep.requestNewCodeInButton.label"
      values={{
        timeLeft: <FormattedDuration seconds={time} format={TIMER_FORMAT} />,
      }}
    />
    : <FormattedMessage id="resetPassword.phoneStep.nextButton.label" />;

  return (
    <Form
      ref={ref}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        autoFocus
        name="username"
        type="tel"
        fullWidth
        label={(
          <FormattedMessage
            id="resetPassword.phoneStep.phoneTextField.label"
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        data-testid="request-code-timer-button"
        fullWidth
        disabled={!!time && isTimerRunning}
        aria-label={intl.formatMessage({ id: 'resetPassword.phoneStep.nextButton.ariaLabel' })}
      >
        {buttonLabel}
      </Button>
    </Form>
  );
});

