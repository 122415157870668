import React, { useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { IFormImperativeHandleProps } from '../../../common/form';

import { EcpFiltersModal as EcpFiltersModalView } from './ecp-filters-modal';
import { IEcpFiltersModalProps, TFilters } from './ecp-filters-modal.models';
import { filterParams } from '../../../../graphql/ecp-locator/ecp-locator.cache';
import { useUpdateEcpStores } from '../../../../hooks/use-update-ecp-stores';
import { useActionsInProgress } from '../../../../graphql/preloader/actions/actions-in-progress';
import { isFilterStateDefault } from './utils/is-filter-state-default';
import { DEFAULT_FILTERS } from '../../../../constants/ecp-locator';
import { TFiltersData } from '../../../../graphql/ecp-locator/models/filter-params.models';

export const EcpFiltersModal = (props: IEcpFiltersModalProps): JSX.Element => {
  const { onClose } = props;
  const ecpFiltersFormRef = useRef<IFormImperativeHandleProps>(null);
  const currentFilters = useReactiveVar(filterParams);
  const { updateStores } = useUpdateEcpStores();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const isResetButtonDisabled = isFilterStateDefault(currentFilters);

  const requestToUpdateStores = async (filters: TFiltersData) => {
    try {
      addActionInProgress();
      await updateStores({ filters });
    } finally {
      removeActionInProgress();
      onClose!();
    }
  };

  const handleApplyFilters = async (values: TFilters) => {
    await requestToUpdateStores({
      ...values,
      isFiltersChanged: true,
      isFirstTimeLoading: false,
    });
  };

  const handleApplyFiltersButtonClick = () => {
    if (ecpFiltersFormRef.current) {
      ecpFiltersFormRef.current.submit();
    }
  };

  const handleResetFilters = async () => {
    await requestToUpdateStores({
      ...DEFAULT_FILTERS,
      isFiltersChanged: true,
    });
  };

  return (
    <EcpFiltersModalView
      {...props}
      ref={ecpFiltersFormRef}
      defaultValues={currentFilters}
      isResetButtonDisabled={isResetButtonDisabled}
      onApplyFilters={handleApplyFilters}
      onSubmitButtonClick={handleApplyFiltersButtonClick}
      onResetFilters={handleResetFilters}
    />
  );
};
