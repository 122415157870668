import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  root: {
    [breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  isAvailable: {
    '&&': {
      border: `1px solid ${palette.grey['500']}`,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  noveltyLabel: {
    margin: '0 auto 12px',
    [breakpoints.up('sm')]: {
      position: 'absolute',
      top: 20,
      marginBottom: 20,
    },
  },
  title: {
    height: '100%',
    margin: '0 auto',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.lg,
      lineHeight: 1.3,
    },
  },
  pointsAmount: {
    margin: '20px auto',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.grey['800'],
    [breakpoints.up('sm')]: {
      marginBottom: 16,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.xl,
    },
  },
  pointsAmountNotAvailable: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '4px 8px',
    border: `1px dashed ${palette.grey['500']}`,
    borderRadius: 64,
    [breakpoints.up('sm')]: {
      lineHeight: lineHeights.md,
    },
  },
  pointsNotAvailableIcon: {
    marginRight: 8,
  },
  clickable: {
    cursor: 'pointer',
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 24,
    color: palette.common.black,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('md')]: {
      marginBottom: 32,
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  bonusImage: {
    margin: '0 auto 16px',
    lineHeight: 0,
    [breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
}));
