import { ReactNode } from 'react';
import { cx } from '@emotion/css';
import { useReactiveVar } from '@apollo/client';

import { useStyles } from './pre-survey-view.styles';
import {
  ReactComponent as PreSurveyIcon,
} from '../../../../../../assets/images/icons/acuvue-life.svg';
import { useIntl } from 'react-intl';
import { IconTypes, Icon } from '../../../../../common';
import { IPreSurveyViewProps } from './pre-survey-view.models';
import { setIsFreepacks } from '../../../../../../graphql/configuration/configuration.cache';

export const PreSurveyView = ({ benefitsListStyles, titleId }: IPreSurveyViewProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const discountsFreePacksText = isFreepacksEnabled
    ? 'vp.benefits.discountsFreePacks.withFreepacks'
    : 'vp.benefits.discountsFreePacks';

  const benefitItems = [
    {
      title: 'vp.benefits.birthdayPoints',
      icon: IconTypes.giftRoundedBlue,
    },
    {
      title: discountsFreePacksText,
      icon: IconTypes.lensesRoundedBlue,
    },
    {
      title: 'vp.benefits.news',
      icon: IconTypes.bellRoundedBlue,
    },
  ];

  return (
    <>
      <div
        data-testid="pre-survey-step-icon"
        className={classes.icon}
        aria-hidden="true"
      >
        <PreSurveyIcon />
      </div>
      <h2
        data-testid="pre-survey-step-title"
        className={classes.title}
      >
        {intl.formatMessage(
          { id: titleId ? titleId : 'vp.startPage.title' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            span: (chunks: ReactNode) => <span className={classes.titlePartial}>{chunks}</span>,
          },
        )}
      </h2>
      <ul
        data-testid="pre-survey-step-list"
        className={cx(classes.benefitsList, benefitsListStyles )}
      >
        {
          benefitItems.map((benefit) => (
            <li
              className={classes.benefitsItem}
              data-testid="pre-survey-step-benefit"
              key={benefit.title}
            >
              <Icon
                type={benefit.icon}
                className={classes.benefitIcon}
                aria-hidden="true"
              />
              <span className={classes.benefitText}>
                {intl.formatMessage(
                  { id: benefit.title },
                  { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                )}
              </span>
            </li>
          ))
        }
      </ul>
    </>
  );
};
