import React from 'react';
import { cx } from '@emotion/css';

import { IWikiCardProps } from './wiki-card.models';
import { useStyles } from './wiki-card.styles';
import { Card, CardTypes } from '../card';
import { FormattedMessage } from 'react-intl';

export const WikiCard = ({ title, description, horizontal }: IWikiCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      type={CardTypes.quaternary}
      data-testid="wiki-card"
      className={cx({
        [classes.rootHorizontal]: horizontal,
      })}
    >
      <h3
        className={cx(classes.title, {
          [classes.titleHorizontal]: horizontal,
        })}
        data-testid="wiki-card-title"
      >
        <FormattedMessage
          id={title}
        />
      </h3>
      <div
        className={cx(classes.description, {
          [classes.descriptionHorizontal]: horizontal,
        })}
        data-testid="wiki-card-description"
      >
        <ul
          className={classes.list}
          data-testid="wiki-card-list"
        >
          {description.map((wikiItem) => (
            <li
              key={wikiItem.name}
              className={classes.listItem}
              data-testid="wiki-card-list-item"
            >
              {
                wikiItem.link ? (
                  <a
                    href={wikiItem.link}
                    className={classes.link}
                    data-testid="wiki-card-list-item-link"
                    target="_blank"
                  >
                    <FormattedMessage
                      id={wikiItem.name}
                    />
                  </a>
                ) : <FormattedMessage id={wikiItem.name} />
              }
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};
