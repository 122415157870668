import { HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ExternalLinks } from '../../../constants';
import { Button } from '../button';
import { Modal, ResponsiveWidth } from '../modal';
import { IContactAJnjProModalProps } from './contact-a-jnj-pro-modal.models';
import { useStyles } from './contact-a-jnj-pro-modal.styles';

export const ContactAJnjProModal = ({
  onClose, ...props
}: IContactAJnjProModalProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  const handleButtonClick = () => {
    onClose!();
  };

  return (
    <Modal
      {...props}
      onClose={onClose}
      responsiveWidth={ResponsiveWidth.lg}
      PaperProps={{ 'data-testid': 'contact-jnj-pro-window' } as HTMLAttributes<HTMLDivElement>}
      title={(
        <span data-testid="contactAJnjProModal-title">
          <FormattedMessage
            id="contactAJnjPro.modal.title"
          />
        </span>
      )}
      content={(
        <div
          className={classes.content}
          data-testid="contactAJnjProModal-content"
        >
          {intl.formatMessage(
            { id: 'contactAJnjPro.modal.content' },
            { p: (chunk: ReactNode) => <p className={classes.contentParagraph}>{chunk}</p> },
          )}
        </div>
      )}
      footer={(
        <div
          className={classes.footer}
          data-testid="contactAJnjProModal-footer"
        >
          <Button
            href={ExternalLinks.newOrder}
            target="_blank"
            variant="contained"
            fullWidth
            onClick={handleButtonClick}
          >
            <FormattedMessage
              id="contactAJnjPro.modal.orderButton.label"
            />
          </Button>
          <p className={classes.description}>
            <FormattedMessage
              id="contactAJnjPro.modal.description"
            />
          </p>
        </div>
      )}
    />
  );
};
