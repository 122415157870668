import { useModal } from 'mui-modal-provider';

import { VisionProfileBanner } from '../../user-activity-items/vision-profile-banner';
import { AskLoginModal } from './ask-login-modal';
import { useStyles } from './vision-profile-unregistered.styles';

export const VisionProfileUnregistered = (): JSX.Element => {
  const { showModal } = useModal();
  const classes = useStyles();

  const handleButtonClick = () => {
    showModal(AskLoginModal, {});
  };

  return (
    <div
      className={classes.root}
      data-testid="vp-banner-unregistered"
    >
      <VisionProfileBanner
        onButtonClick={handleButtonClick}
      />
    </div>
  );
};
