import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  calendar: {
    '&.MuiCalendarPicker-root': {
      width: 270,
      minWidth: 270,
      height: 287,
      minHeight: 287,
      margin: 0,
      padding: '10px 0',
      overflowY: 'hidden',
      [breakpoints.up('md')]: {
        padding: '34px 16px 16px',
        width: 310,
        minWidth: 310,
        height: 328,
        minHeight: 328,
      },
      '& .MuiCalendarPicker-viewTransitionContainer': {
        [breakpoints.up('md')]: {
          height: 238,
        },
        '& .PrivatePickersSlideTransition-root': {
          position: 'relative',
          top: 'unset',
          left: 'unset',
          right: 'unset',
          [breakpoints.up('md')]: {
            height: 216,
            minHeight: 'unset',
          },
        },
      },
    },
    '& .MuiCalendarPicker-root': {
      margin: 0,
      padding: '16px 16px 0',
      width: 310,
      minWidth: 310,
      height: 300,
      minHeight: 300,
      overflowY: 'hidden',
      '& .MuiCalendarPicker-viewTransitionContainer': {
        height: 232,
        [breakpoints.up('md')]: {
          height: 228,
        },
        '& .PrivatePickersSlideTransition-root': {
          height: 212,
          minHeight: 'unset',
          [breakpoints.up('md')]: {
            height: 206,
          },
          '& > [role="grid"]': {
            position: 'relative',
            top: 'unset',
            left: 'unset',
            right: 'unset',
            '&:nth-of-type': {
              display: 'none',
            },
          },
        },
      },
      '& .css-1dozdou': {
        '& + div': {
          position: 'relative',
          height: 232,
          [breakpoints.up('md')]: {
            height: 228,
          },
        },
      },
      '& .MuiYearPicker-root': {
        '& .PrivatePickersYear-root': {
          '& .Mui-selected': {
            background: palette.primary.main,
            color: palette.common.white,
          },
          '& .Mui-disabled': {
            color: palette.grey['500'],
          },
        },
      },
      '& .MuiMonthPicker-root': {
        width: 'auto',
        '& .MuiTypography-root': {
          flexBasis: '25%',
          margin: '5px 0',
          padding: '8px 0',
          background: 'transparent',
          color: palette.text.secondary,
          borderRadius: 20,
          lineHeight: lineHeights.sm,
          [breakpoints.up('md')]: {
            lineHeight: lineHeights.md,
          },
          '&.Mui-selected': {
            background: palette.primary.main,
            color: palette.common.white,
          },
        },
        '& [disabled]': {
          color: palette.grey['500'],
        },
      },
    },
    '& .MuiCalendarPicker-root, &.MuiCalendarPicker-root': {
      display: 'block',
      '& > div:first-of-type': {
        position: 'relative',
        margin: '0 0 22px',
        padding: '0 30px',
        [breakpoints.up('md')]: {
          marginBottom: 26,
        },
        '& > div': {
          minWidth: 'unset',
          width: '100%',
          minHeight: 'unset',
          '&:first-of-type': {
            justifyContent: 'center',
            zIndex: 1,
            '& .MuiButtonBase-root': {
              marginRight: 'unset',
            },
          },
          '&:nth-of-type(2)': {
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            justifyContent: 'space-between',
          },
        },
        '& .PrivatePickersFadeTransitionGroup-root': {
          color: palette.common.black,
          fontSize: fontSizes.xs,
          lineHeight: lineHeights.sm,
          fontWeight: fontWeights.medium,
          textTransform: 'capitalize',
          [breakpoints.up('md')]: {
            fontSize: fontSizes.sm,
            lineHeight: lineHeights.md,
          },
        },
        '& .MuiButtonBase-root': {
          width: 24,
          height: 24,
          padding: 0,
          '&[disabled]': {
            opacity: 0.2,
          },
        },
      },
      '& > div:nth-of-type(2)': {
        margin: 0,
        padding: 0,
        // todo after update check if can be changed to a meaningful class name
        '& .css-1n2mv2k': {
          justifyContent: 'stretch',
          margin: '0 0 6px',
        },
      },
      '& .MuiTypography-root': {
        flexGrow: 1,
        width: 'auto',
        height: 'auto',
        margin: 0,
        fontFamily: fontFamilies.primary,
        fontSize: fontSizes.xs,
        lineHeight: 1,
        color: palette.grey['500'],
        textTransform: 'capitalize',
        [breakpoints.up('md')]: {
          fontSize: fontSizes.sm,
        },
        '&:first-of-type': {
          paddingRight: 2,
        },
        '&:last-of-type': {
          paddingLeft: 2,
        },
      },
      '& .PrivatePickersSlideTransition-root': {
        minHeight: 204,
        [breakpoints.up('md')]: {
          minHeight: 264,
        },
        '& [role="row"]': {
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          gridColumnGap: 5,
          margin: 0,
          '&:last-of-type': {
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        '& .MuiPickersDay-root': {
          width: '100%',
          height: 34,
          margin: 0,
          color: palette.text.secondary,
          fontFamily: fontFamilies.primary,
          fontSize: fontSizes.xs,
          lineHeight: lineHeights.sm,
          [breakpoints.up('md')]: {
            fontSize: fontSizes.sm,
            lineHeight: lineHeights.md,
          },
          '&:hover, &:active': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: palette.grey['500'],
            opacity: 1,
          },
          '&.Mui-selected': {
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            fontWeight: fontWeights.medium,
          },
          '&.Mui-focusVisible': {
            border: `1px solid ${palette.primary.main}`,
            '&:not(.Mui-selected)': {
              backgroundColor: 'transparent',
            },
          },
          '&.MuiPickersDay-today': {
            '&:not(.Mui-focusVisible)': {
              border: 'none',
            },
          },
        },
      },
    },
  },
  paper: {
    borderRadius: borderRadiuses.lg,
    transition: 'none',
    '&&': {
      minWidth: 310,
      width: 310,
    },
    '&. MuiDialogContent-root': {
      position: 'relative',
      overflowY: 'hidden',
    },
    '& .MuiPickersToolbar-root': {
      padding: '32px 16px 16px',
      borderRadius: `${borderRadiuses.lg}px ${borderRadiuses.lg}px 0 0`,
      background: palette.primary.main,
      [breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
      '& > .MuiTypography-root': {
        fontSize: fontSizes.xs,
        lineHeight: lineHeights.sm,
        marginBottom: 8,
        [breakpoints.up('md')]: {
          fontSize: fontSizes.sm,
          lineHeight: lineHeights.md,
        },
      },
      '& .MuiIconButton-root': {
        display: 'none',
      },
      '& + div': {
        width: '100%',
      },
    },
    '& .MuiTypography-root': {
      color: palette.common.white,
      textTransform: 'none',
    },
    '& .PrivatePickersToolbar-dateTitleContainer': {
      '& .MuiTypography-root': {
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
  },
  actionButtons: {
    '& .MuiDialogActions-root': {
      padding: '24px 32px',
      '& .MuiButton-root': {
        minWidth: 'unset',
        padding: 0,
        fontSize: fontSizes.xs,
        lineHeight: lineHeights.sm,
        [breakpoints.up('md')]: {
          fontSize: fontSizes.sm,
          lineHeight: lineHeights.md,
        },
        '&:hover': {
          background: 'transparent',
        },
        '&:not(:first-of-type)': {
          marginLeft: 16,
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
  },
  changeButton: {
    fontFamily: fontFamilies.primary,
    color: palette.primary.main,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    padding: 0,
    borderRadius: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      background: 'none',
    },
  },
  inputWithAdornment: {
    position: 'relative',
    zIndex: 1,
  },
  inputAdornmentDate: {
    position: 'absolute',
    right: 0,
  },
}));
