import { isClinicStore } from '../../../../../utils/appointment/define-store-types';
import markerClinicIconActive from '../../../../../assets/images/map-markers/clinic-active.png';
import markerOpticsIconActive from '../../../../../assets/images/map-markers/optic-active.png';
import markerClinicIconCampaign from '../../../../../assets/images/map-markers/clinic-active-campaign.png';
import markerOpticsIconCampaign from '../../../../../assets/images/map-markers/optic-active-campaign.png';
import {
  MARKER_WIDTH_ACTIVE,
  MARKER_HEIGHT_ACTIVE,
} from '../../../../../constants/ecp-locator';

export const getMarkerIcon = (type: string) => ({
  url: type && isClinicStore(type) ? markerClinicIconActive : markerOpticsIconActive,
  scaledSize: new window.google.maps.Size(MARKER_WIDTH_ACTIVE, MARKER_HEIGHT_ACTIVE),
});

export const getCampaignMarkerIcon = (type: string) => ({
  url: type && isClinicStore(type) ? markerClinicIconCampaign : markerOpticsIconCampaign,
  scaledSize: new window.google.maps.Size(MARKER_WIDTH_ACTIVE, MARKER_HEIGHT_ACTIVE),
});
