import React from 'react';

import { ecpLocatorApi, TInternetShopResponse } from '../../../../../rest/ecp-locator';
import { InternetShops as InternetShopsView } from './internet-shops';
import { useRequest } from '../../../../../hooks/use-request';
import { IInternetShopsContainerProps } from './internet-shops.models';

export const InternetShops = ({
  isFitted, onBackButtonClick,
}: IInternetShopsContainerProps): JSX.Element => {
  const { data, loading, error } = useRequest<TInternetShopResponse>(
    ecpLocatorApi.getInternetShops,
  );

  return (
    <InternetShopsView
      shops={data}
      isFitted={isFitted}
      isLoading={loading}
      isError={!!error}
      onBackButtonClick={onBackButtonClick}
    />
  );
};
