import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      alignItems: 'flex-end',
    },
  },
  apps: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    padding: '60px 0',
    [breakpoints.up('md')]: {
      width: '235px',
      padding: 0,
    },
    '& svg': {
      display: 'block',
    },
  },
  stores: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  appStore: {
    width: 127,
    height: 39,
  },
  googlePlay: {
    width: 127,
    height: 39,
  },
  qrCode: {
    width: 88,
    height: 88,
  },
  campaignIcon: {
    display: 'block',
    marginTop: 30,
    width: 107,
    height: 'auto',
    objectFit: 'contain',
    [breakpoints.up('md')]: {
      width: 154,
      marginTop: 40,
    },
  },
}));
