import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ellipse from '../../../../assets/images/backgrounds/ellipse.png';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  palette,
  fontWeights,
}: Theme) => ({
  root: {
    paddingTop: 24,
    paddingBottom: 32,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: -330,
    backgroundImage: `url(${ellipse})`,
    [breakpoints.up('sm')]: {
      paddingTop: 32,
      paddingBottom: 64,
      backgroundPositionY: -340,
    },
    [breakpoints.up('lg')]: {
      paddingTop: 40,
      backgroundPositionY: -270,
    },
  },
  title: {
    margin: '0 0 24px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.lg,
    color: palette.text.secondary,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxxl,
    },
    [breakpoints.up('lg')]: {
      margin: '0 0 32px',
    },
  },
  iconSVG: {
    width: '60%',
    height: '60%',
  },
  accordionRoot: {
    '&&': {
      marginBottom: 8,
      [breakpoints.up('sm')]: {
        marginBottom: 16,
      },
    },
    '&&:last-child': {
      marginBottom: 0,
    },
  },
  summaryRoot: {
    padding: 16,
    [breakpoints.up('sm')]: {
      padding: 32,
    },
  },
  expandIcon: {
    '& svg': {
      [breakpoints.up('sm')]: {
        width: 35,
        height: 35,
      },
    },
  },
  link: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    color: palette.primary.main,
    textDecoration: 'none',
    '&:focus': {
      outline: `1px dotted ${palette.primary.main}`,
    },
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));
