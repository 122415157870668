import {
  IFittingPreviewData,
  IPurchasesData,
} from '../../../../../../../graphql/lenses/models/get-lenses-preview.models';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../../../../../graphql/lenses/models/get-lenses.models';

const findBrandType = (
  brands: (LensesBrandType | SolutionsBrandType)[], type: LensesBrandType,
): LensesBrandType | SolutionsBrandType | undefined => {
  return brands.find((brand) => brand === type);
};

const findPriorityBrand = (brands: (
  LensesBrandType | SolutionsBrandType)[],
): LensesBrandType | SolutionsBrandType | undefined | null  => {
  return brands.length
    ? (
      findBrandType(brands, LensesBrandType.aoh1dfa)
      || findBrandType(brands, LensesBrandType.aomaxmf)
      || findBrandType(brands, LensesBrandType.oneDamfa)
      || findBrandType(brands, LensesBrandType.oneDamm)
      || findBrandType(brands, LensesBrandType.aom1d)
      || findBrandType(brands, LensesBrandType.aoh1d)
      || findBrandType(brands, LensesBrandType.oneDam)
      || findBrandType(brands, LensesBrandType.aohfa)
      || findBrandType(brands, LensesBrandType.aohm)
      || findBrandType(brands, LensesBrandType.aowt)
      || findBrandType(brands, LensesBrandType.aoh)
      || findBrandType(brands, LensesBrandType.oneDate)
    ) : null;
};

export const getBrandByPriority = (data: IPurchasesData | IFittingPreviewData | undefined) => {
  let brands: (LensesBrandType | SolutionsBrandType)[] = [];

  if (data) {
    if ('scannedProduct' in data) {
      brands = data.scannedProduct.map(({ myAcuvueBrandCode }) => myAcuvueBrandCode);
    } else {
      brands = data.fittedBrands.map(({ brand: { product } }) => product);
    }
  }

  return findPriorityBrand(brands);
};
