import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  siteMinWidth, siteMaxWidth, spacing, breakpoints,
}: Theme) => ({
  root: {
    minWidth: siteMinWidth,
    maxWidth: siteMaxWidth,
    margin: '0 auto',
    padding: spacing(0, 2),
    [breakpoints.up('lg')]: {
      paddingLeft: spacing(2.5),
      paddingRight: spacing(2.5),
    },
  },
  rootWithoutPaddings: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
