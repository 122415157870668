import { CmsBanner } from '../../../../../common/cms-banner';
import { ContentWrapper } from '../../../content-wrapper';
import { useStyles } from './campaign-banner.styles';
import { useGetBanners } from '../../../../../../hooks/use-get-banners';
import { ICampaignBanner } from './campaign-banner.models';
import { PrimaryBanner as PrimaryBannerView } from '../primary-banner';

export const CampaignBanner = ({ bannerData, campaignBannerId }: ICampaignBanner): JSX.Element => {
  const classes = useStyles();

  const { isBanner, url, pwaCallToActionLink } = useGetBanners(campaignBannerId);

  return (
    <>
      {isBanner ? (
        <section className={classes.root}>
          <ContentWrapper>
            <CmsBanner
              url={url}
              pwaCallToActionLink={pwaCallToActionLink}
            />
          </ContentWrapper>
        </section>
      ) : <PrimaryBannerView bannerData={bannerData} />}
    </>
  );
};
