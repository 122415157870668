import React, {
  forwardRef,
  MutableRefObject,
  useMemo,
} from 'react';
import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { addWeeks, addDays } from 'date-fns';

import { IWeeklyLensesFormProps, TFormValues } from './weekly-lenses-form.models';
import { WeeklyLensesForm as WeeklyLensesFormView } from './weekly-lenses-form';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { toDate } from '../../../../../../../utils/date/to-date';
import { useLensesAmountValidation } from '../utils/use-lenses-amount-validation';
import { convertDateToString } from '../../../../../../../utils/date/convert-date-to-string';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { UPDATE_REMINDER } from '../../../../../../../graphql/reminders/mutations/create-edit-reminder';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import {
  FrequencyOfUsageTypes,
  NotificationType,
} from '../../../../../../../graphql/reminders/models/reminder.models';
import { LENSES_AMOUNT_MAX } from '../reminder-form.constants';
import { convertStringToDate } from '../../../../../../../utils/date/convert-string-to-date';
import { getRecalculatedLensesAmount } from './utils/get-recalculated-lenses-amount';

export const WeeklyLensesForm = forwardRef<IFormImperativeHandleProps, IWeeklyLensesFormProps>(({
  options, onModalClose,
}, ref): JSX.Element => {
  const { packSize, eyesAmount, reminderProps, consumerLensesId, onUpdateLenses } = options;
  const weeklyLensesFormRef = ref as MutableRefObject<IFormImperativeHandleProps>;
  const validationSchema = useLensesAmountValidation();
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const [updateReminder] = useMutation(UPDATE_REMINDER);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => {
    const today = toDate(new Date)!;

    if (reminderProps) {
      const { amountOfLenses, startWearingAt, buyNewPackAt } = reminderProps;
      const currentAmountOfLenses = getRecalculatedLensesAmount({
        amountOfLenses,
        startWearingAt,
        eyesAmount,
      });

      return {
        ...reminderProps,
        amountOfLenses: currentAmountOfLenses,
        startWearingAt: convertStringToDate(startWearingAt!),
        buyNewPackAt: convertStringToDate(buyNewPackAt),
        changeLensesAt: toDate(addWeeks(new Date(startWearingAt), 2))!,
      };
    }

    return {
      amountOfLenses: '0',
      changeLensesAt:  toDate(addWeeks(new Date(), 2))!,
      startWearingAt: today,
      buyNewPackAt: today,
      firstNotification: NotificationType.oneDay,
      secondNotification: NotificationType.threeDays,
      setupAt: convertDateToString(today),
      frequencyOfUsage: FrequencyOfUsageTypes.iDontKnow,
    };
  }, []);

  const handleSubmit = async (values: TFormValues) => {
    addActionInProgress();

    try {
      await makeCancellablePromise(updateReminder({
        variables: {
          consumerLensesId,
          reminder: {
            ...omit(values, 'changeLensesAt'),
            buyNewPackAt: convertDateToString(values.buyNewPackAt!),
            startWearingAt: convertDateToString(values.startWearingAt!),
          },
        },
      }));

      await makeCancellablePromise(onUpdateLenses());

      onModalClose();

    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  const handleBuyDateChange = () => {
    const twoWeeks = 14;
    const { amountOfLenses } = weeklyLensesFormRef.current.getValues();
    const daysAmount = twoWeeks * Math.floor(Number(amountOfLenses) / eyesAmount);
    const newBuyPackDate = toDate(addDays(new Date(), daysAmount));

    weeklyLensesFormRef.current.setValue('buyNewPackAt', newBuyPackDate, {
      shouldDirty: true,
    });
  };

  const handleShiftLensesChange = () => {
    const { startWearingAt } = weeklyLensesFormRef.current.getValues();
    const newChangeLensesDate = toDate(addWeeks(new Date(startWearingAt as Date), 2));

    weeklyLensesFormRef.current.setValue('changeLensesAt', newChangeLensesDate, {
      shouldDirty: true,
    });
  };

  const handleAddLensesClick = () => {
    const { amountOfLenses } = weeklyLensesFormRef.current.getValues();
    let newAmount = Number(amountOfLenses) + packSize;

    if (newAmount > Number(LENSES_AMOUNT_MAX)) {
      newAmount = Number(LENSES_AMOUNT_MAX);
    }

    weeklyLensesFormRef.current.setValue('amountOfLenses', String(newAmount), {
      shouldValidate: true,
      shouldDirty: true,
    });

    // setValue callback in react-hook-form doesn't call onChange event in textfield
    handleBuyDateChange();
  };

  return (
    <WeeklyLensesFormView
      ref={ref}
      packSize={packSize}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onAddLensesClick={handleAddLensesClick}
      onChangeLensesDependency={handleShiftLensesChange}
      onBuyDateDependency={handleBuyDateChange}
    />
  );
});
