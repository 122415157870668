import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IEyeParamsOption } from '../../../graphql/lenses/models/get-lenses.models';
import { IEyeParamsProps } from './eye-params.models';
import { useStyles } from './eye-params.styles';
import { getParamLabelTranslationId, getUnits } from './utils';
import { useFormattedValue } from './hooks';

export const EyeParams = ({ params, title }: IEyeParamsProps): JSX.Element => {
  const classes = useStyles();
  const { getFormattedValue } = useFormattedValue();

  const formattedParams = useMemo(
    () => params.map(({ value, property }: IEyeParamsOption) => ({
      property,
      value: getFormattedValue(value, property),
    })), [getFormattedValue, params],
  );

  return (
    <div>
      <h4 className={classes.title}>
        {title}
      </h4>
      {formattedParams.map(({ value, property }: IEyeParamsOption) => (
        <p
          className={classes.param}
          key={property}
          data-testid="eye-params-item"
        >
          <FormattedMessage
            id={getParamLabelTranslationId(property)}
          />
          {': '}
          <span className={classes.paramValue}>
            {value}
            {getUnits(property)}
          </span>
        </p>
      ))}
    </div>
  );
};
