import { Modal } from '../../../../common/modal';
import { Stepper } from '../../../../common/stepper';
import { IVisionProfileViewProps } from './vision-profile.models';
import { useVisionProfileSteps } from './vision-profile.steps';

export const VisionProfile = ({
  externalState, onDone, ...props
}: IVisionProfileViewProps): JSX.Element => {
  const visionProfileSteps = useVisionProfileSteps();

  return (
    <Modal
      {...props}
      content={(
        <Stepper
          externalState={externalState}
          disableStepIndicators
          steps={visionProfileSteps}
          onDone={onDone}
        />
      )}
    />
  );
};
