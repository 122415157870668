import { CmsBanner, CmsBannerType } from '../../../../common/cms-banner';
import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './primary-banner-unregistered.styles';
import { useGetBanners } from '../../../../../hooks/use-get-banners';
import { useCallback } from 'react';
import { Preloader } from '../../../../common/preloader';

export const PrimaryBannerUnregistered = (): JSX.Element => {
  const classes = useStyles();
  const { isBanner, url, loading, pwaCallToActionLink } = useGetBanners(CmsBannerType.unregisteredUser);

  const renderBanner = useCallback(() => {
    if (loading) {
      return <Preloader />;
    }

    if (isBanner) {
      return (
        <CmsBanner
          url={url}
          pwaCallToActionLink={pwaCallToActionLink}
        />
      );
    }
  }, [isBanner]);

  return (
    <section
      className={classes.root}
      data-testid="primary-banner-unregistered"
    >
      <ContentWrapper>
        {renderBanner()}
      </ContentWrapper>
    </section>
  );
};
