import React, {
  forwardRef, useImperativeHandle, useMemo, useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { addDays, addMonths } from 'date-fns';

import { toDate } from '../../../../../../utils/date/to-date';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { DateStepForm } from './date-step-form';
import { useStyles } from './date-step.styles';
import { IStepsState } from '../../appointment-registration-modal.models';

export const DateStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  stepsState,
}, ref): JSX.Element => {
  const classes = useStyles();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const minDate = useMemo(() => toDate((addDays(new Date(), 2)).toDateString()), [])!;
  const maxDate = useMemo(() => toDate((addMonths(new Date(), 2)).toDateString()), [])!;
  const date = (stepsState as IStepsState).appointmentDate?.date ?? minDate;
  const defaultValues = { date };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {},
  }));

  return (
    <>
      <div
        className={classes.coloredDateTitleWrapper}
        data-testid="appointment-date-step-title"
      >
        <h2 className={classes.title}>
          <FormattedMessage
            id="appointmentRegistration.dateStep.title"
          />
        </h2>
      </div>
      <div className={classes.content}>
        <DateStepForm
          ref={formRef}
          minDate={minDate}
          maxDate={maxDate}
          defaultValues={defaultValues}
          onSubmit={onGoNextSuccess}
        />
      </div>
    </>
  );
});
