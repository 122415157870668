import React, { useCallback } from 'react';
import { Button } from '../button';
import { Modal, ResponsiveWidth } from '../modal';
import { IConfirmationModal } from './confirmation-modal.models';

import { useStyles } from './confirmation-modal.styles';

export const ConfirmationModal = ({
  content,
  confirmButtonLabel,
  rejectButtonLabel,
  onClose,
  onConfirm,
  onReject,
  rejectOnClose,
  hasChangedButtonsPriority,
  isInProgress,
  ...props
}: IConfirmationModal): JSX.Element => {
  const classes = useStyles();

  const handleConfirmButtonClick = useCallback(() => {
    if (isInProgress) {
      return;
    }

    onConfirm();
  }, [onConfirm, isInProgress]);

  const handleRejectButtonClick = useCallback(() => {
    if (isInProgress) {
      return;
    }

    if (onReject) {
      onReject();
    } else {
      onClose!();
    }
  }, [onClose, onReject, isInProgress]);

  const handleClose = useCallback(() => {
    if (rejectOnClose && onReject) {
      onReject();
    } else {
      onClose!();
    }
  }, [onClose, rejectOnClose, onReject]);

  const priorityButton = (
    <Button
      variant="contained"
      fullWidth
      onClick={hasChangedButtonsPriority ? handleRejectButtonClick : handleConfirmButtonClick}
      data-testid={hasChangedButtonsPriority ? 'reject' : 'confirm'}
    >
      {hasChangedButtonsPriority ? rejectButtonLabel : confirmButtonLabel}
    </Button>
  );

  const nonPriorityButton = (
    <Button
      variant="outlined"
      fullWidth
      onClick={hasChangedButtonsPriority ? handleConfirmButtonClick : handleRejectButtonClick}
      data-testid={hasChangedButtonsPriority ? 'confirm' : 'reject'}
    >
      {hasChangedButtonsPriority ? confirmButtonLabel : rejectButtonLabel}
    </Button>
  );

  return (
    <Modal
      {...props}
      onClose={handleClose}
      isInProgress={isInProgress}
      content={(
        <p className={classes.content}>
          {content}
        </p>
      )}
      footer={(
        <div className={classes.actions}>
          {priorityButton}
          {nonPriorityButton}
        </div>
      )}
      responsiveWidth={ResponsiveWidth.sm}
    />
  );
};
