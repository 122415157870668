import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { useGoBack } from '../../../../hooks/use-go-back';
import { IProfileProps } from './profile.models';
import { ProfileForm } from './profile-form';
import { ContentWrapper } from '../content-wrapper';
import { Box, ResponsiveMargins, ResponsiveSize } from '../../../common/box';
import { Preloader } from '../../../common/preloader';
import { TechIssues } from '../../../common/tech-issues';
import { SECONDARY_BACKGROUND } from '../../../../constants';

export const Profile = ({
  onProfileUpdate, profileData, validationSchema, isError, isLoading, updateQuery,
}: IProfileProps): JSX.Element => {
  const { goBack } = useGoBack();
  const intl = useIntl();

  const handleBack = useCallback(() => goBack(), [goBack]);

  const renderProfileForm = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return <TechIssues />;
    }

    return (
      <ContentWrapper withoutPaddings>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'profilePage.title' })}
          </title>
          <meta name="description" content={
            intl.formatMessage({ id: 'profilePage.description' })} />
          <style type="text/css">
            {`
              body {
                background-color: ${SECONDARY_BACKGROUND};
              }
            `}
          </style>
        </Helmet>
        <Box
          center
          responsiveMargins={ResponsiveMargins.sm}
          responsiveSize={ResponsiveSize.xs}
          title={(
            <span data-testid="profile-title">
            <FormattedMessage
              id="profile.title"
            />
          </span>
          )}
          backButtonUrl={handleBack}
        >
          <ProfileForm
            onSubmit={onProfileUpdate}
            profileData={profileData!}
            updateProfileQuery={updateQuery!}
            validationSchema={validationSchema}
          />
        </Box>
      </ContentWrapper>
    );
  };

  return <>{renderProfileForm()}</>;
};
