import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  fontSizes,
  lineHeights,
  fontWeights,
}: Theme) => ({
  backButton: {
    position: 'absolute',
    top: 0,
    right: '100%',
    [breakpoints.up('md')]: {
      marginRight: 20,
    },
  },
  root: {
    zIndex: 1,
  },
  content: {},
  title: {
    position: 'relative',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    color: palette.text.secondary,
    margin: '0 0 8px',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xxl,
      lineHeight: lineHeights.xxl,
      marginBottom: 16,
    },
  },
  titleWithoutDescription: {
    marginBottom: 24,
    [breakpoints.up('md')]: {
      marginBottom: 32,
    },
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.lg,
    color: palette.text.primary,
    margin: '0 0 24px',
    [breakpoints.up('md')]: {
      lineHeight: lineHeights.md,
      marginBottom: 32,
    },
  },
  hintText: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    color: palette.grey['500'],
    textAlign: 'center',
    margin: '16px 0 0',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  stepIndicators: {
    height: 5,
    display: 'flex',
    marginBottom: 22,
    [breakpoints.up('md')]: {
      marginBottom: 48,
    },
  },
  stepIndicator: {
    flexGrow: 1,
    marginLeft: 12,
    backgroundColor: palette.grey['200'],
    borderRadius: 10,
    '&:first-child': {
      marginLeft: 0,
    },
    [breakpoints.up('md')]: {
      marginLeft: 17,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  stepIndicatorDone: {
    backgroundColor: palette.primary.light,
  },
  stepIndicatorActive: {
    backgroundColor: palette.primary.light,
  },
  goNextButton: {
    letterSpacing: 'normal',
  },
}));
