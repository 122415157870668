import React from 'react';
import { cx } from '@emotion/css';

import { IShopsListProps } from './shops-list.models';
import { useStyles } from './shops-list.styles';

export const ShopsList = ({ shops }: IShopsListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ul
      className={classes.shopList}
      data-testid="internet-shops-list"
    >
      {shops.map(({ name }, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          data-testid="internet-shop"
          className={cx(classes.shopItem, {
            [classes.itemLine]: index !== shops.length - 1,
          })}
        >
          {name}
        </li>
      ))}
    </ul>
  );
};
