import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
}: Theme) => ({
  resetPasswordLink: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.common.black,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
  disclaimer: {
    '& > a': {
      color: palette.common.black,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    color: palette.common.black,
    opacity: 0.3,
    margin: 'auto 32px 32px',
    textAlign: 'center',
  },
}));
