import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { yup } from '../../../../../../../services/yup';

export const useLensesAmountValidation = () => {
  const intl = useIntl();

  return useMemo(() => yup.object({
    amountOfLenses: yup
      .string()
      .required(intl.formatMessage({ id: 'common.errors.required' }))
      .notOneOf(['0'], intl.formatMessage({ id: 'reminders.modal.form.errors.nullableAmount' })),
  }), []);
};
