import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {},
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0 16px',
    [breakpoints.up('md')]: {
      paddingTop: 64,
      paddingBottom: 28,
    },
  },
  logoLink: {
    display: 'flex',
  },
}));
