import { useQuery } from '@apollo/client';
import {
  IVisionProfileData,
} from '../../../../../../graphql/profile/models/get-vision-profile.models';
import { GET_VISION_PROFILE } from '../../../../../../graphql/profile/queries/get-vision-profile';
import { UserMenu as UserMenuView } from './user-menu';
import {
  useVisionProfileData, VPStatus,
} from '../../../../../../hooks/use-vision-profile-data';

export const UserMenu = () => {
  const { data } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const { status } = useVisionProfileData(data);

  return (
    <>
      {
        (status === VPStatus.INCOMPLETE || status === VPStatus.COMPLETE) && (
          <UserMenuView visionProfileStatus={status} />
        )
      }
    </>
  );
};
