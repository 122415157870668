import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './sign-up-slide.styles';
import { Button } from '../../../../common/button';
import { InternalLinks } from '../../../../../constants';
import { CmsBanner, CmsBannerType } from '../../../../common/cms-banner';
import { Icon, IconTypes } from '../../../../common';
import { useGetBanners } from '../../../../../hooks/use-get-banners';

export const SignUpSlide = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();
  const { isBanner, url, loading } = useGetBanners(CmsBannerType.signUpBanner);

  const renderSlide = useCallback(() => {
    if (loading) {
      return null;
    }

    if (isBanner) {
      return (
        <CmsBanner
          url={url}
        />
      );
    }
  }, [isBanner]);

  return (
    <div className={classes.root}>
      <ContentWrapper withoutPaddings>
        <div className={classes.slide} data-testid="slide">
          <div aria-hidden="true" data-testid="slide-img">
            {renderSlide()}
          </div>
          <div className={classes.content} data-testid="slide-content">
            <h1 className={classes.title} data-testid="title">
              {intl.formatMessage(
                { id: 'heroSlides.signUp.title' },
                {
                  sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
                  nonBreakSpace: <>&nbsp;</>,
                },
              )}
            </h1>
            <Button
              data-testid="signup-button"
              to={InternalLinks.registration}
              state={hasPreviousLocationState}
              color="secondary"
              variant="outlined"
              className={classes.button}
            >
              <FormattedMessage
                id="common.registrationButton.label"
              />
            </Button>
            <div className={classes.description} data-testid="description">
              {intl.formatMessage(
                { id: 'heroSlides.signUp.description' },
                { a: (chunk: ReactNode) => (
                    <a
                      href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
                      target="_blank"
                      className={classes.link}
                    >
                      {chunk}
                    </a>
                ),
                p: (chunk: ReactNode) => (
                  <p
                    className={classes.partial}
                    data-testid="description-partial"
                  >
                    {chunk}
                  </p>
                ),
                },
              )}
            </div>
            <span
              aria-label={intl.formatMessage({ id: 'heroSlides.signUp.disclaimer' })}
              data-testid="disclaimer-wrapper"
            >
              <Icon
                type={IconTypes.slideLegal}
                className={classes.disclaimer}
                aria-hidden={true}
              />
            </span>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
