import { SortingTypes } from '../../../components/business/app/ecp-sort/ecp-sort.models';
import { sortingType } from '../ecp-locator.cache';

const setSortingTypeToCache = (value: SortingTypes) => {
  sortingType(value);
};

export const useSortingType = () => ({
  setSortingTypeToCache,
});
