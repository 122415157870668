import { gql } from '@apollo/client';

export const UPDATE_REMINDER = gql`
  mutation UpdateReminder(
      $reminder: ReminderContractInput,
      $consumerLensesId: String) {
        setupReminder(
          reminder: $reminder,
          consumerLensesId: $consumerLensesId) {
            id
      }
    }
`;