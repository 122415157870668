import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGoBack } from '../../../hooks/use-go-back';
import { Icon, IconTypes } from '../icon';
import { Button } from '../button';
import { useStyles } from './back-button.styles';

export const BackButton = (): JSX.Element => {
  const classes = useStyles();
  const { goBack } = useGoBack();

  const handleBackButtonClick = useCallback(() => goBack(), [goBack]);

  return (
    <div
      className={classes.root}
      data-testid="back-button"
    >
      <Button
        color="info"
        onClick={handleBackButtonClick}
        startIcon={(
          <Icon type={IconTypes.arrowLeft} />
        )}
        labelClassName="normal"
      >
        <FormattedMessage id="common.backButton.label" />
      </Button>
    </div>
  );
};
