import { useIntl } from 'react-intl';

import { Appointment as AppointmentView } from '../../user-activity-items/appointment';
import { useStyles } from './appointment.styles';

export const Appointment = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <section
      data-testid="appointment-landing-wrapper"
      aria-label={intl.formatMessage({ id: 'appointment.landing.ariaLabel' })}
      className={classes.root}
    >
      <AppointmentView />
    </section>
  );
};
