import {
  ecpStoresCached,
  filterParams,
  sortingType,
  setInternetShops,
  setStoreDetail,
  setUserLocation,
  setStoreCode,
  setAutocompletedSearch,
} from './ecp-locator.cache';

export const ecpLocator = {
  read() {
    return {
      ecpStoresCached: ecpStoresCached(),
      isInternetShops: setInternetShops(),
      userLocation: setUserLocation(),
      storeDetail: setStoreDetail(),
      filterParams: filterParams(),
      sortingType: sortingType(),
      storeCode: setStoreCode(),
      isAutocompletedSearch: setAutocompletedSearch(),
    };
  },
};
