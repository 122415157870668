import { ReactNode, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { useGoBack } from '../../../../hooks/use-go-back';
import { ContentWrapper } from '../content-wrapper';
import { Box, ResponsiveSize } from '../../../common/box';
import { Stepper } from '../../../common/stepper';
import { useRegistrationSteps } from './registration.steps';
import { useStyles } from './registration.styles';
import { IRegistrationProps } from './registration.models';

export const Registration = ({ onDone, externalState }: IRegistrationProps): JSX.Element => {
  const { goBack } = useGoBack();
  const classes = useStyles();
  const intl = useIntl();
  const registrationSteps = useRegistrationSteps();

  const handleBack = useCallback(() => goBack(), [goBack]);

  return (
    <>
      <Helmet>
        <script src={process.env.REACT_APP_RECAPTCHA_API_URL} />
        <title>
          {intl.formatMessage({ id: 'registrationPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'registrationPage.description' })}
        />
      </Helmet>
      <ContentWrapper withoutPaddings>
        <Box
          responsiveSize={ResponsiveSize.xs}
          center
        >
          <Stepper
            steps={registrationSteps}
            onDone={onDone}
            backButtonUrl={handleBack}
            externalState={externalState}
          />
        </Box>
      </ContentWrapper>

      <div className={classes.disclaimer} data-testid="registration-footer-disclaimer">
        {intl.formatMessage(
          { id: 'registration.disclaimer.text' },
          { a: (chunk: ReactNode) => (
            <a
              href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
              target="_blank"
            >
              {chunk}
            </a>
          ) },
        )}
      </div>
    </>
  );
};
