import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  socialNetworksList: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: spacing(2),
    justifyContent: 'center',
    padding: '0',
    margin: 0,
    listStyleType: 'none',
  },
}));
