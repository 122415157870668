import {
  useRef,
  useCallback,
  useMemo,
  useReducer,
  useLayoutEffect,
  useEffect,
} from 'react';
import { isFunction } from 'lodash';
import { cx } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from 'react-intl';

import { useBind } from '../../../hooks/use-bind';
import { useCancellablePromise } from '../../../hooks/use-cancellable-promise';
import { IconButton, IIconButtonProps } from '../icon-button';
import { IconTypes, IconSizes } from '../icon';
import { Button } from '../button';
import { useStyles } from './stepper.styles';
import {
  IStepperProps,
  IStepperImperativeHandleProps,
  IStepProps,
  IStepperState,
  IStepperAction,
  IActionType,
  TStepsResolver,
} from './stepper.models';

const init = (steps: IStepProps[]) => ({
  steps,
  isDone: false,
  stepsState: {},
  activeStepIndex: 0,
  activeStepId: steps[0].id,
  stepActionsInProgress: 0,
  isNavigationAllowed: true,
  isNextButtonDisabled: false,
  isStepResolving: false,
  stepsResolver: null,
});

const calculateIsNavigationAllowed = (newState: IStepperState) => {
  const { isStepResolving, stepsResolver, stepActionsInProgress } = newState;

  return {
    ...newState,
    isNavigationAllowed: !(isStepResolving || stepsResolver || stepActionsInProgress),
  };
};

const reducer = (state: IStepperState, action: IStepperAction) => {
  switch (action.type) {
    case IActionType.setStepState: {
      const { stepsState } = state;

      return {
        ...state,
        stepsState: {
          ...stepsState as Object,
          [action.id!]: action.stepState,
        },
      };
    }
    case IActionType.done:
      return calculateIsNavigationAllowed({
        ...state,
        stepsResolver: action.stepsResolver!,
      });
    case IActionType.doneFail:
      return calculateIsNavigationAllowed({
        ...state,
        stepsResolver: null,
        isDone: false,
      });
    case IActionType.doneSuccess:
      return calculateIsNavigationAllowed({
        ...state,
        stepsResolver: null,
        isDone: false,
      });
    case IActionType.goBack: {
      const {
        activeStepId, activeStepIndex, stepsState, steps,
      } = state;
      const newActiveStepIndex = activeStepIndex - 1;
      const newActiveStepId = steps[newActiveStepIndex].id;

      return {
        ...state,
        stepsState: {
          ...stepsState as Object,
          [activeStepId]: action.stepState,
        },
        activeStepIndex: newActiveStepIndex,
        activeStepId: newActiveStepId,
        isNextButtonDisabled: false,
        isDone: false,
      };
    }
    case IActionType.goNext:
      return calculateIsNavigationAllowed({
        ...state,
        isStepResolving: true,
        isNextButtonDisabled: false,
      });
    case IActionType.goNextFail:
      return calculateIsNavigationAllowed({
        ...state,
        isStepResolving: false,
      });
    case IActionType.goNextSuccess: {
      const {
        activeStepId, stepsState, activeStepIndex, steps,
      } = state;
      const isDone = activeStepIndex + 1 === steps.length;
      const newActiveStepIndex = isDone ? activeStepIndex : activeStepIndex + 1;
      const newActiveStepId = isDone ? activeStepId : steps[newActiveStepIndex].id;

      return calculateIsNavigationAllowed({
        ...state,
        isStepResolving: false,
        stepsState: {
          ...stepsState as Object,
          [activeStepId]: action.stepState,
        },
        activeStepIndex: newActiveStepIndex,
        activeStepId: newActiveStepId,
        isDone,
      });
    }
    case IActionType.setNextButtonState:
      return {
        ...state,
        isNextButtonDisabled: action.disabledButtonState!,
      };
    case IActionType.incrementStepActionsInProgress:
      return calculateIsNavigationAllowed({
        ...state,
        stepActionsInProgress: state.stepActionsInProgress + 1,
      });
    case IActionType.decrementStepActionsInProgress:
      return calculateIsNavigationAllowed({
        ...state,
        stepActionsInProgress: state.stepActionsInProgress - 1,
      });
    default:
      throw new Error();
  }
};

export const Stepper = ({
  steps,
  onDone,
  backButtonUrl,
  externalState,
  disableStepIndicators,
}: IStepperProps): JSX.Element => {
  const stepRef = useRef<IStepperImperativeHandleProps>();
  const [{
    isDone,
    isNavigationAllowed,
    activeStepIndex,
    stepsState,
    isNextButtonDisabled,
  }, dispatch] = useReducer(reducer, steps, init);

  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const setStepState = useCallback((id: string, stepState: unknown) => dispatch({
    type: IActionType.setStepState,
    stepState,
    id,
  }), [dispatch]);

  const done = useCallback((stepsResolver: TStepsResolver) => dispatch({
    type: IActionType.done,
    stepsResolver,
  }), [dispatch]);

  const doneSuccess = useCallback(() => dispatch({
    type: IActionType.doneSuccess,
  }), [dispatch]);

  const doneFail = useCallback(() => dispatch({
    type: IActionType.doneFail,
  }), [dispatch]);

  const goBack = useCallback((stepState: unknown) => dispatch({
    type: IActionType.goBack,
    stepState,
  }), [dispatch]);

  const goNext = useCallback(() => dispatch({
    type: IActionType.goNext,
  }), [dispatch]);

  const goNextFail = useCallback(() => dispatch({
    type: IActionType.goNextFail,
  }), [dispatch]);

  const goNextSuccess = useCallback((stepState: unknown) => dispatch({
    type: IActionType.goNextSuccess,
    stepState,
  }), [dispatch]);

  const setNextButtonDisabledState = useCallback((disabledButtonState?: boolean) => dispatch({
    type: IActionType.setNextButtonState,
    disabledButtonState,
  }), [dispatch]);

  const incrementStepActionsInProgress = useCallback(() => dispatch({
    type: IActionType.incrementStepActionsInProgress,
  }), [dispatch]);

  const decrementStepActionsInProgress = useCallback(() => dispatch({
    type: IActionType.decrementStepActionsInProgress,
  }), [dispatch]);

  const {
    StepComponent,
    title,
    description: Description,
    hintText,
    nextButtonLabel,
    disableBackButon,
    disableNextButton,
    nextButtonAriaLabel,
    isNextDisabled,
    descriptionStyles,
    isRecaptcha,
    recaptchaAction,
  } = steps[activeStepIndex];

  const resultNextButtonLabel = useMemo(
    () => nextButtonLabel || <FormattedMessage id="common.nextButton.label" />,
    [nextButtonLabel],
  );

  const handleGoBackButtonClick = useCallback(() => {
    if (!isNavigationAllowed) {
      return;
    }

    const stepState = stepRef.current!.goBack();
    goBack(stepState);
  }, [
    goBack,
    isNavigationAllowed,
  ]);

  const handleGoNextButtonClick = useCallback(() => {
    if (!isNavigationAllowed) {
      return;
    }

    stepRef.current!.goNext();
  }, [
    isNavigationAllowed,
  ]);

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const doneBind = useBind(done);
  const doneSuccessBind = useBind(doneSuccess);
  const doneFailBind = useBind(doneFail);
  const onDoneBind = useBind(onDone);
  const stepsStateBind = useBind(stepsState);

  const handleDone = useCallback(async () => {
    const newStepsResolver = onDoneBind.current(stepsStateBind.current);

    if (!(newStepsResolver instanceof Promise)) {
      return;
    }

    doneBind.current(newStepsResolver);

    try {
      await makeCancellablePromiseBind.current(newStepsResolver);
      doneSuccessBind.current();
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      doneFailBind.current();
    }
  }, [
    doneBind,
    doneSuccessBind,
    doneFailBind,
    onDoneBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    stepsStateBind,
  ]);

  useLayoutEffect(() => {
    if (isDone) {
      handleDone();
    }
  }, [isDone, handleDone]);

  const renderBackButton = (stepIndex: number) => {
    const iconButtonProps: Partial<IIconButtonProps> = {};

    if (stepIndex === 0) {
      if (backButtonUrl) {
        iconButtonProps.to = backButtonUrl;
      } else {
        return null;
      }
    } else {
      iconButtonProps.onClick = handleGoBackButtonClick;
    }

    return (
      <IconButton
        iconProps={{
          type: IconTypes.arrowLeft,
          size: isTablet ? IconSizes.md : IconSizes.sm,
        }}
        className={classes.backButton}
        {...iconButtonProps}
      />
    );
  };

  const hasTitle = Boolean(title);
  const hasDescription = Boolean(Description);

  useEffect(() => {
    if (isRecaptcha) {
      (window as any).handleGoNextButtonClick = handleGoNextButtonClick;
    }
  }, []);

  return (
    <div
      className={classes.root}
      data-testid="stepper"
    >
      {!disableStepIndicators && (
        <div className={classes.stepIndicators}>
          {steps.map(({ id: stepId }, stepIndex) => (
            <div
              key={stepId}
              className={
                cx(
                  classes.stepIndicator,
                  { [classes.stepIndicatorDone]: activeStepIndex > stepIndex },
                  { [classes.stepIndicatorActive]: activeStepIndex === stepIndex },
                )
              }
            />
          ))}
        </div>
      )}
      <div className={classes.content}>
        {hasTitle && (
          <h3
            className={cx(classes.title, {
              [classes.titleWithoutDescription]: !hasDescription,
            })}
          >
            {!disableBackButon && renderBackButton(activeStepIndex)}
            {title}
          </h3>
        )}
        {hasDescription && (
          <p className={cx(classes.description, descriptionStyles)}>
            {isFunction(Description) ? (
              <Description
                stepsState={stepsState}
                externalState={externalState}
              />
            ) : Description}
          </p>
        )}
        <StepComponent
          ref={stepRef}
          stepsState={stepsState}
          setStepState={setStepState}
          externalState={externalState}
          onGoNextSuccess={goNextSuccess}
          onGoNext={goNext}
          onGoNextFail={goNextFail}
          onGoBack={handleGoBackButtonClick}
          onNextButtonDisabled={setNextButtonDisabledState}
          incrementStepActionsInProgress={incrementStepActionsInProgress}
          decrementStepActionsInProgress={decrementStepActionsInProgress}
          isNavigationAllowed={isNavigationAllowed}
        />
        {!disableNextButton && (
          <Button
            variant="contained"
            data-testid="stepper-go-next-button"
            fullWidth
            disabled={isNextButtonDisabled || isNextDisabled}
            onClick={handleGoNextButtonClick}
            className={cx(classes.goNextButton,
              { ['g-recaptcha']: isRecaptcha },
            )}
            aria-label={nextButtonAriaLabel as string}
            data-sitekey={isRecaptcha ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : undefined}
            data-callback={isRecaptcha ? 'handleGoNextButtonClick' : undefined}
            data-action={isRecaptcha ? recaptchaAction : undefined}
          >
            {resultNextButtonLabel}
          </Button>
        )}
        {hintText && (
          <p className={classes.hintText}>
            {hintText}
          </p>
        )}
      </div>
    </div>
  );
};
