import React, { forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import { IEditReminderModalProps } from './edit-reminder-modal.models';
import { EditReminderModal as EditReminderModalView } from './edit-reminder-modal';
import { IFormImperativeHandleProps } from '../../../../../common/form';
import { DELETE_REMINDER } from '../../../../../../graphql/reminders/mutations/delete-reminder';
import { useActionsInProgress } from '../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../hooks/use-cancellable-promise';
import { promiseErrorCallbacks } from '../../../../../../utils/promise/set-promise-error-callbacks';
import { DeleteReminderModal } from './delete-reminder-modal';

export const EditReminderModal = forwardRef<IFormImperativeHandleProps, IEditReminderModalProps>(({
  options, onSubmitModalClose, ...props
}, ref): JSX.Element => {
  const { showModal } = useModal();
  const { consumerLensesId, onUpdateLenses } = options;
  const [deleteReminder] = useMutation(DELETE_REMINDER);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();

  const handleDeleteReminerConfirmation = async () => {
    addActionInProgress();
    
    try {
      await makeCancellablePromise(deleteReminder({
        variables: {
          consumerLensesId,
        },
      }));

      await makeCancellablePromise(onUpdateLenses());

      onSubmitModalClose();

    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  const handleDeleteReminer = () => {
    const { hide } = showModal(DeleteReminderModal, {
      onConfirm: async () => {
        await handleDeleteReminerConfirmation();
        hide();
      },
    });
  };

  return (
    <EditReminderModalView
      {...props}
      ref={ref}
      options={options}
      onSubmitModalClose={onSubmitModalClose}
      onDeleteReminder={handleDeleteReminer}
    />
  );
});
