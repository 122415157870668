import { ReactNode } from 'react';
import { IEyeParamsOption } from '../../../graphql/lenses/models/get-lenses.models';

export interface IEyeParamsProps {
  title: ReactNode;
  params: IEyeParamsOption[];
}

export enum EyeType {
  left = 'left',
  right = 'right',
}

export interface IEyeParamsData {
  rightEyeColumnData: IEyeParamsProps;
  leftEyeColumnData: IEyeParamsProps;
  isOneEye: boolean;
}
