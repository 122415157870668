import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { ClassNameMap } from '@mui/styles';

import { IProgramItemConfig, IProgramItem } from './items.model';
import { Button, Icon } from '../../../../common';
import { InternalLinks } from '../../../../../constants/internal-links';
import { AskLoginLink } from '../ask-login-link';
import { ContactAJnjProModal } from '../../../../common/contact-a-jnj-pro-modal';
import { useGoBack } from '../../../../../hooks/use-go-back';

export const useProgramItemsContent = () => {
  const intl = useIntl();
  const { showModal } = useModal();
  const { hasPreviousLocationState } = useGoBack();

  const handleAskQuestionClick = useCallback(() => {
    showModal(ContactAJnjProModal, {});
  }, [showModal]);

  const getProgramItemTitle = useCallback((id: string): ReactNode => intl.formatMessage(
    { id },
    {
      sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      linebreak: <br />,
    }), []);

  const getProgramItemContent = useCallback((id: string, classes: ClassNameMap): ReactNode => intl.formatMessage(
    { id },
    {
      linebreak: <br />,
      sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
      p: (chunks: ReactNode) => <p className={classes.paragraph}>{chunks}</p>,
      myAcuvueProgramPolicyLink: (chunks: ReactNode) => (
        <a
          href={intl.formatMessage({ id: 'common.myAcuvuePolicy' })}
          className={classes.link}
          target="_blank"
        >
          {chunks}
        </a>
      ),
      myLensesLink: (chunks: ReactNode) => (
        <AskLoginLink
          to={InternalLinks.myLensesPage}
          state={hasPreviousLocationState}
          className={classes.link}
        >
          {chunks}
        </AskLoginLink>
      ),
      ecpLocatorLink: (chunks: ReactNode) => (
        <Button
          to={InternalLinks.ecpLocator}
          labelClassName="normal"
        >
          {chunks}
        </Button>
      ),
      newOrderLink: (chunks: ReactNode) => (
        <Button
          labelClassName="normal"
          onClick={handleAskQuestionClick}
        >
          {chunks}
        </Button>
      ),
    },
  ), [handleAskQuestionClick]);

  const getProgramItems = useCallback((
    config: IProgramItemConfig[], classes: ClassNameMap,
  ): IProgramItem[] => config.map((configItem: IProgramItemConfig) => ({
    title: getProgramItemTitle(`${configItem.id}.title`),
    content: getProgramItemContent(`${configItem.id}.text`, classes),
    icon: <Icon type={configItem.icon} className={classes.iconSVG} />,
    newClasses: classes,
  })), [getProgramItemContent, getProgramItemTitle]);

  const getProgramItemsContent = useCallback((
    items: IProgramItemConfig[],
    classes: ClassNameMap,
  ) => (getProgramItems(items, classes)), [getProgramItems]);

  return { getProgramItemsContent };
};
