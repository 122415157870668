export enum FrequencyOfUsageTypes {
  everyDay = 'EVERYDAY',
  fiveDays = 'FIVEDAYS',
  threeToFourDays = 'THREETOFOURDAYS',
  oneToTwoDays = 'ONETOTWODAYS',
  lessThanOneDay = 'LESSTHANONEDAY',
  iDontKnow = 'IDONTKNOW',
}

export enum NotificationType {
  threeDays = 'THREE_DAY',
  twoDays = 'TWO_DAY',
  oneDay = 'ONE_DAY',
  none = 'NONE',  
}