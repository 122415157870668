export const APPOINTMENT_HOW_TO = `${process.env.REACT_APP_REST_API_URL}/cms/en/pages/how_is_the_fitting`;

export enum ExternalLinks {
  personalDataPolicyLink = 'https://www.acuvue.ru/usloviya-ispolzovaniya-personalnyh-dannyh',
  privacyPolicyLink = 'https://www.acuvue.ru/privacy-policy',
  myAcuvueProgramPolicyLink = 'https://www.acuvue.ru/pravila-programmy-myacuvue',
  legalNoticeLink = 'https://www.acuvue.ru/legal-notice',
  newOrder = 'https://sample.acuvue.ru/visioncare/new-order',
  mobileMarket = 'https://myacuvue.onelink.me/o8U8/acuvueru',
  locator = 'https://myacuvue.onelink.me/o8U8/ECPLocator',
  visionAndGadgets = 'https://acuvue.ru/zrenie-i-tekhnika',
  visionAndTravel = 'https://acuvue.ru/zrenie-i-puteshestviya',
  visionAndMakeup = 'https://acuvue.ru/zrenie-i-kosmetika',
  weeklyLenses = 'https://acuvue.ru/linzy-na-dve-nedeli',
  dailyLenses = 'https://acuvue.ru/linzy-na-den',
  monthlyLenses = 'https://acuvue.ru/linzy-na-mesyac',
  cleanAndCare = 'https://acuvue.ru/kak-ochishchat-i-uhazhivat',
  takeOutLenses = 'https://acuvue.ru/kak-snyat-linzy',
  usefulTips = 'https://acuvue.ru/poleznye-sovety',
  putInLenses = 'https://acuvue.ru/kak-nadet-linzy',
  commonMyths = 'https://acuvue.ru/mify-kotorye-my-razoblachili',
  lensesBenefits = 'https://acuvue.ru/preimushchestva-kl',
  covidRestrictions = 'https://acuvue.ru/poseshchenie-optik-vo-vremya-covid-19',
  lensesFitting = 'https://acuvue.ru/podbor-linz',
  telegramBot = 'https://t.me/MyACUVUEru_bot',
  whatsAppNumber = 'https://wa.me/79105901168',
  mailLink = 'mailto:acuvue@jnj.ru',
  phoneNumber = '88006007997',
}
