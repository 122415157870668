import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontFamilies, fontSizes, lineHeights, fontWeights, palette, breakpoints,
}: Theme) => ({
  rootHorizontal: {
    [breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  title: {
    margin: 0,
    padding: '16px 20px',
    color: palette.text.secondary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      padding: 24,
      fontSize: fontSizes.md,
      lineHeight: lineHeights.md,
    },
  },
  titleHorizontal: {
    [breakpoints.up('sm')]: {
      maxWidth: 210,
    },
  },
  description: {
    padding: '16px 20px 20px',
    borderTop: `2px solid ${palette.grey['200']}`,
    [breakpoints.up('sm')]: {
      padding: '24px 24px 32px',
    },
  },
  descriptionHorizontal: {
    [breakpoints.up('sm')]: {
      width: 'calc((100% - 24px) / 2)',
      borderTop: 'none',
    },
    [breakpoints.up('xl')]: {
      width: '62%',
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    marginBottom: 12,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    [breakpoints.up('sm')]: {
      marginBottom: 16,
      fontSize: fontSizes.xs,
      lineHeight: lineHeights.md,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  link: {
    color: palette.text.primary,
    letterSpacing: 'normal',
    textAlign: 'left',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: `1px dotted ${palette.text.primary}`,
    },
    '& span': {
      fontWeight: fontWeights.regular,
    },
  },
}));
