import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './complete-step.styles';
import {
  ReactComponent as SurveyCompletedIcon,
} from '../../../../../../assets/images/icons/vision-profile/survey-done.svg';

export const CompleteStep = (): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <div
        data-testid="complete-step-icon"
        className={classes.icon}
        aria-hidden="true"
      >
        <SurveyCompletedIcon />
      </div>
      <h2
        data-testid="complete-step-title"
        className={classes.title}
      >
        <FormattedMessage id="vp.surveyDone.title" />
      </h2>
      <div
        data-testid="complete-step-description"
        className={classes.descriptionWrapper}
      >
        {intl.formatMessage(
          { id: 'vp.surveyDone.description' },
          { p: (chunks: ReactNode) => <p className={classes.description}>{chunks}</p> },
        )}
      </div>
    </>
  );
};
