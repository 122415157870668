import { Logo } from '../../../../../common/logo';
import { useStyles } from './company-policy.styles';
import { PolicyLinks } from '../../../../../common/policy-links';

export const CompanyPolicy = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      data-testid="footer-company-policy"
    >
      <div className={classes.logoWrapper}>
        <Logo withBackground size="lg" />
      </div>
      <PolicyLinks />
    </div>
  );
};
