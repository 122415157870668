import { FormattedMessage } from 'react-intl';

import { IUnsavedChangesConfirmationModal } from './unsaved-changes-confirmation-modal.models';
import { ConfirmationModal } from '../confirmation-modal';

export const UnsavedChangesConfirmationModal = (
  { pageName, ...props }: IUnsavedChangesConfirmationModal,
): JSX.Element => {
  const typeId = pageName ? 'withPageName' : 'default';

  return (
    <ConfirmationModal
      {...props}
      data-testid="unsaved-changes-confirmation-modal"
      title={<FormattedMessage id="unsavedChanges.modal.title" />}
      content={(
        <FormattedMessage
          id={`unsavedChanges.modal.description.${typeId}`}
          values={{
            pageName,
          }}
        />
      )}
      confirmButtonLabel={<FormattedMessage id="unsavedChanges.modal.confirmButton.label" />}
      rejectButtonLabel={<FormattedMessage id="unsavedChanges.modal.rejectButton.label" />}
      rejectOnClose
    />
  );
};
