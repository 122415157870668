import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IStoreServices } from './store-services.models';
import { isHybridStore } from '../../../utils/ecp-locator/define-store-types';
import { EcpStoreType } from '../../../rest/ecp-locator';
import { IconSizes, IconTypes, Tooltip } from '../index';
import { IconButton } from '../icon-button';
import { useStyles } from './store-services.styles';

export const StoreServices = ({ type, speciality }: IStoreServices): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const formatSpecialties = (
    specialties: string,
  ) => specialties.split(',');

  return (
    <div>
      <h2 className={classes.serviceTitle} data-testid="service-title">
        <FormattedMessage id="ecp.service.title" />
      </h2>
      {isHybridStore(type) && (
        <ul
          className={classes.service}
          data-testid="store-detail-fit-and-buy-list"
        >
          <li
            className={classes.serviceItem}
          >
            <FormattedMessage id="ecp.service.fit" />
          </li>
          <li
            className={classes.serviceItem}
          >
            <FormattedMessage id="ecp.service.buy" />
          </li>
        </ul>
      )}
      {type === EcpStoreType.fitter && (
        <p
          className={classes.serviceItem}
          data-testid="store-detail-fit"
        >
          <FormattedMessage id="ecp.service.fit" />
        </p>
      )}
      {type === EcpStoreType.retailer && (
        <p
          className={classes.serviceItem}
          data-testid="store-detail-buy"
        >
          <FormattedMessage id="ecp.service.buy" />
        </p>
      )}
      {
        speciality && (
          <>
            <h2
              className={classes.serviceTitle}
              data-testid="store-detail-additional-service-title"
            >
              <FormattedMessage id="ecp.spec.additional.title" />
            </h2>
            <ul className={classes.service}>
              {
                formatSpecialties(speciality).map((spec) => (
                  <li
                    key={spec}
                    className={classes.serviceItem}
                    data-testid="add-service-item"
                  >
                    <FormattedMessage id={`ecp.spec.additional.${spec}`} />
                    {intl.formatMessage({
                      id: `ecp.spec.additional.${spec}`,
                    }) === intl.formatMessage({
                      id: 'ecp.spec.additional.presbyopia',
                    }) && (
                      <Tooltip
                        // @ts-ignore
                        title={intl.formatMessage({
                          id: 'ecp.spec.additional.presbyopia.tooltip',
                        }, {
                          p: (
                            chunk: ReactNode,
                          ) => (
                            <p
                              className={classes.messagePartial}
                              data-testid="tooltip-message-partial"
                            >
                              {chunk}
                            </p>
                          ),
                        })}
                        withArrow
                      >
                        <IconButton
                          data-testid="tooltip-button"
                          aria-label={intl.formatMessage({
                            id: 'ecp.spec.additional.presbyopia.ariaLabel',
                          })}
                          iconProps={{
                            type: IconTypes.info,
                            size: IconSizes.xs,
                          }}
                        />
                      </Tooltip>
                    )}
                  </li>
                ))
              }
            </ul>
          </>
        )
      }
    </div>
  );
};
