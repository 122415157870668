import { ReactNode } from 'react';
import { DialogProps } from '@mui/material/Dialog';

export interface IModalProps extends Omit<DialogProps, 'title' | 'onClose'> {
  title?: ReactNode;
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  footerSeparateLine?: boolean;
  responsiveWidth?: ResponsiveWidth;
  onClose?: (
    event?: MouseEvent | KeyboardEvent, reason?: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  isInProgress?: boolean;
  propsPaperStyles?: string;
  propsContentStyles?: string;
  disableClosing?: boolean;
  rootStyles?: string;
  titleStyles?: string;
  topIcon?: ReactNode;
}

export enum ResponsiveWidth {
  default = 'default',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  mdFixed = 'mdFixed', // has a fixed size in mobile
  lg = 'lg',
  xl = 'xl',
  xlWide = 'xlWide', // has a wide size in tablet
  mdWide = 'mdwide', // has a wider size on desktop and tablet
  mdWider = 'mdwider',
}
