import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  palette,
}: Theme) => ({
  link: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    color: palette.primary.main,
    '&:focus': {
      outline: `1px dotted ${palette.primary.main}`,
    },
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& span': {
      fontWeight: 'inherit',
    },
  },
}));
