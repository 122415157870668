import { capitalize } from 'lodash';
import { cx } from '@emotion/css';

import { Card } from '../card';
import { ITitleSize, IUserActivitiesListProps } from './user-activities-list.models';
import { useStyles } from './user-activities-list.styles';

export const UserActivitiesList = <T extends { id: number | string }>({
  title, activities, placeholder = null, footer,
}: IUserActivitiesListProps<T>): JSX.Element => {
  const classes = useStyles();
  let titleSizeClass = '';

  if (title) {
    const { size = ITitleSize.md } = title;

    titleSizeClass = classes[
      `titleSize${capitalize(size) as 'Md' | 'Lg'}`
    ];
  }

  return (
    <Card className={classes.root}>
      {title && (
        <h2
          data-testid="my-points-section-title"
          className={cx(classes.title, titleSizeClass)}
        >
          {title.label}
        </h2>
      )}
      {activities.length ? (
        <ul
          className={classes.list}
          data-testid="activities-list"
        >
          {activities.map((activityItem) => (
            <li
              className={classes.bonusCardsItem}
              key={activityItem.props.id}
            >
              {activityItem}
            </li>
          ))}
        </ul>
      ) : placeholder}
      {footer && (
        <footer
          className={classes.footer}
          data-testid="activities-footer"
        >
          {footer}
        </footer>
      )}
    </Card>
  );
};
