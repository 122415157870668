import React, { useEffect } from 'react';
import { cx } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';

import { useStyles } from './my-lenses.styles';
import { IMyLensesProps } from './my-lenses.models';
import { ContentWrapper } from '../content-wrapper';
import { MyLensesFittedCardsList } from './my-lenses-fitted-cards-list';
import { MyLensesNotFittedContent } from './my-lenses-not-fitted-content';
import { isUserFitted } from '../../../../utils/lenses/is-user-fitted';
import { Preloader } from '../../../common/preloader';
import { BackButton } from '../../../common/back-button';
import { MyLensesTutorial } from './my-lenses-tutorial';
import { GET_IS_LENSES_TUTORIAL_SHOWN } from '../../../../graphql/user/queries';
import { TechIssues } from '../../../common/tech-issues';

export const MyLenses = ({ lensesData }: IMyLensesProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { data, loading, error, refetch: handleUpdateLenses } = lensesData;
  const isFitted = isUserFitted(data);
  const { data: { user: { isLensesTutorialShown } } } = useQuery(GET_IS_LENSES_TUTORIAL_SHOWN);
  const { showModal } = useModal();
  const intl = useIntl();

  useEffect(() => {
    if (!isLensesTutorialShown) {
      showModal(MyLensesTutorial, {});
    }
  }, [isLensesTutorialShown, showModal]);

  const renderContent = () => {
    if (loading) {
      return <Preloader />;
    }

    if (error) {
      return <TechIssues />;
    }

    return (
      <div
        className={cx(classes.root,
          { [classes.rootBackgroundFitted]: isFitted },
          { [classes.rootBackgroundNotFitted]: !isFitted },
        )}
      >
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'myLensesPage.title' })}
          </title>
          <meta name="description" content={
            intl.formatMessage({ id: 'myLensesPage.description' })} />
        </Helmet>
        <ContentWrapper>
          <BackButton />
          <h1
            className={classes.title}
            data-testid="my-lenses-title"
          >
            <FormattedMessage
              id="myLensesDetailed.title"
            />
          </h1>
        </ContentWrapper>
        <ContentWrapper withoutPaddings={!isFitted && !isDesktop}>
          {
            isFitted
              ? (
                <MyLensesFittedCardsList
                  cards={data!.consumerLensesByAuthID}
                  onUpdateLenses={handleUpdateLenses}
                />
              )
              : <MyLensesNotFittedContent />
          }
        </ContentWrapper>
      </div>
    );
  };

  return (
    <>{renderContent()}</>
  );
};
