import React from 'react';
import { useQuery } from '@apollo/client';

import { Header as HeaderView } from './header';
import { GET_IS_USER_LOGGED_IN } from '../../../../../graphql/user/queries';

export const Header = (): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);

  return (
    <HeaderView isLoggedIn={isLoggedIn} />
  );
};
