import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';

import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../../common/stepper';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { yup } from '../../../../../../../services/yup';
import { TWearRegularityValues } from './wear-regularity-form/wear-regularity-form.models';
import { IStepsState } from '../survey-step.models';
import { UPDATE_VISION_PROFILE } from '../../../../../../../graphql/profile/mutations';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { GET_VISION_PROFILE } from '../../../../../../../graphql/profile/queries/get-vision-profile';
import { IVisionProfileData } from '../../../../../../../graphql/profile/models/get-vision-profile.models';
import { WearRegularityView } from '../../../vision-profile-views/wear-regularity-view';

export const WearRegularityStep = forwardRef<
IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
  onNextButtonDisabled,
  stepsState,
}, ref): JSX.Element => {
  const intl = useIntl();
  const formRef = useRef<IFormImperativeHandleProps>(null);
  const { wearRegularity: wearRegularityState } = stepsState as IStepsState;
  const { updateQuery } = useQuery<IVisionProfileData>(GET_VISION_PROFILE);
  const [updateVisionProfile] = useMutation(UPDATE_VISION_PROFILE);
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const defaultValues = useMemo(() => wearRegularityState || {
    wearRegularity: null,
  }, []);

  const validationSchema = useMemo(() => yup.object({
    wearRegularity: yup.string().nullable().required(
      intl.formatMessage({ id: 'common.errors.requiredPickOne' })),
  }), [intl]);

  const handleUpdateWearRegularity = async ({
    wearRegularity: wearRegularityCurrent,
  }: TWearRegularityValues) => {
    const wearRegularity = wearRegularityState?.wearRegularity;

    if (wearRegularityCurrent === wearRegularity) {
      onGoNextSuccess({ wearRegularity });
      return;
    }

    addActionInProgress();

    try {
      const { data: { patchVisionProfile } } = await makeCancellablePromise(
        updateVisionProfile({
          variables: {
            visionProfile: {
              wearRegularity: wearRegularityCurrent,
            },
          },
        }),
      );

      updateQuery((prevState) => ({
        visionProfile: {
          ...prevState.visionProfile,
          ...patchVisionProfile,
        },
      }));

      removeActionInProgress();
      onGoNextSuccess({ wearRegularity: patchVisionProfile.wearRegularity });
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }

      removeActionInProgress();
    }
  };

  const handleValidateDependencies = (isValid: boolean) => {
    onNextButtonDisabled(!isValid);
  };

  useImperativeHandle(ref, () => ({
    goNext() {
      formRef.current!.submit();
    },
    goBack() {
      return formRef.current!.getValues();
    },
  }));

  return (
    <WearRegularityView
      ref={formRef}
      formProps={{
        onSubmit: handleUpdateWearRegularity,
        onValidateDependencies: handleValidateDependencies,
        defaultValues,
        validationSchema,
        validationMode: 'onChange',
      }}
    />
  );
});
