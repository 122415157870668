import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { useGoBack } from '../../../../hooks/use-go-back';
import { ContentWrapper } from '../content-wrapper';
import { Box, ResponsiveSize } from '../../../common/box';
import { IResetPasswordProps } from './reset-password.models';
import { Stepper } from '../../../common/stepper';
import { resetPasswordSteps } from './reset-password.steps';

export const ResetPassword = ({ onDone, externalState }: IResetPasswordProps): JSX.Element => {
  const { goBack } = useGoBack();
  const intl = useIntl();

  const handleBack = useCallback(() => goBack('/login'), [goBack]);

  return (
    <>
      <Helmet>
        <script src={process.env.REACT_APP_RECAPTCHA_API_URL} />
        <title>
          {intl.formatMessage({ id: 'resetPasswordPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'resetPasswordPage.description' })}
        />
      </Helmet>
      <ContentWrapper withoutPaddings>
        <Box
          responsiveSize={ResponsiveSize.xs}
          center
        >
          <Stepper
            disableStepIndicators
            steps={resetPasswordSteps}
            onDone={onDone}
            backButtonUrl={handleBack}
            externalState={externalState}
          />
        </Box>
      </ContentWrapper>
    </>
  );
};
