import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ siteMediumWidth }: Theme) => ({
  root: {
    width: '100%',
    maxWidth: siteMediumWidth,
    margin: '0 auto',
  },
}));
