import React from 'react';

import { EcpLocatorExternal as EcpLocatorView } from './ecp-locator-external';
import { ecpLocatorSettings } from '../../../common/ecp-locator-settings';
import { IEcpLocatorProps } from './ecp-locator-external.models';

const EcpLocatorExternalContainer = (props: IEcpLocatorProps): JSX.Element => (
  <EcpLocatorView
    {...props}
  />
);

export const EcpLocatorExternal = ecpLocatorSettings(EcpLocatorExternalContainer);
