import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export interface ILocationState {
  hasPreviousLocation: true;
}

export const useGoBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback((fallback: string = '/') => {
    if (location.state && (location.state as ILocationState).hasPreviousLocation) {
      navigate(-1);
    } else {
      navigate(fallback);
    }
  }, [location, history]);

  return useMemo(() => ({
    goBack,
    hasPreviousLocationState: {
      hasPreviousLocation: true,
    },
  }), [goBack]);
};
