import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './wiki.styles';
import { ContentWrapper } from '../../content-wrapper';
import { WikiList } from '../../../../common/wiki-list';
import { wikiItems } from './wiki.items';

export const Wiki = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ContentWrapper>
      <section
        data-testid="wiki-landing-wrapper"
        aria-label={intl.formatMessage({ id: 'wiki.section.ariaLabel' })}
        className={classes.root}
      >
        <h2
          className={classes.title}
          data-testid="wiki-landing-title"
        >
          <FormattedMessage id="wiki.section.title" />
        </h2>
        <WikiList
          items={wikiItems}
        />
      </section>
    </ContentWrapper>
  );
};
