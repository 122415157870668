import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  container: {
    display: 'grid',
    rowGap: spacing(3),
    [breakpoints.up('md')]: {
      gridAutoFlow: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));
