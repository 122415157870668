import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
  borderRadiuses,
}: Theme) => ({
  root: {
    fontWeight: fontWeights.medium,
    textTransform: 'none',
    textAlign: 'center',
    minWidth: 0,
    verticalAlign: 'baseline',
    transition: `
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  },
  rootColorInfo: {
    '&&$text': {
      color: palette.text.secondary,
    },
  },
  rootColorPrimary: {
    '&&$text': {
      color: palette.primary.main,
    },
    '&&$text:disabled': {
      color: palette.grey['500'],
    },
  },
  rootColorSecondary: {
    '&&$outlined': {
      color: palette.common.white,
      borderColor: palette.common.white,
      '&:hover': {
        background: palette.common.white,
        color: palette.primary.main,
      },
    },
  },
  rootUnderline: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  contained: {
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    borderRadius: borderRadiuses.xl,
    [breakpoints.up('md')]: {
      padding: '12px 24px',
      lineHeight: lineHeights.md,
    },
    padding: '8px 16px',
    backgroundColor: palette.primary.main,
    color: palette.grey.A100,
    '&:hover': {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    '&:active': {
      backgroundColor: palette.action.active,
    },
    '&:disabled': {
      backgroundColor: palette.grey['200'],
      color: palette.grey['500'],
    },
  },
  outlined: {
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
    borderRadius: borderRadiuses.xl,
    [breakpoints.up('md')]: {
      padding: '10px 22px',
      lineHeight: lineHeights.md,
    },
    border: `2px ${palette.primary.main} solid`,
    padding: '6px 14px',
    backgroundColor: 'transparent',
    color: palette.primary.main,
    '&:hover': {
      color: palette.primary.light,
      borderColor: palette.primary.light,
      backgroundColor: 'transparent',
      borderWidth: 2,
    },
    '&:active': {
      color: palette.action.active,
      borderColor: palette.action.active,
    },
    '&:disabled': {
      borderWidth: 2,
    },
  },
  text: {
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    padding: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:hover': {
      background: 'none',
    },
  },
  startIcon: {
    marginRight: 5,
    marginLeft: 0,
  },
  startIconWithoutText: {
    margin: 0,
  },
  labelMarked: {
    '& > span': {
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
    },
    '& span::after': {
      content: "''",
      display: 'flex',
      alignSelf: 'flex-start',
      width: 7,
      height: 7,
      borderRadius: 9,
      backgroundColor: palette.error.main,
      [breakpoints.up('md')]: {
        width: 8,
        height: 8,
        borderRadius: 10,
      },
    },
  },
  normal: {
    fontWeight: fontWeights.regular,
  },
}));
