import { useIntl } from 'react-intl';

import { Icon, IconTypes, IconSizes } from '../icon';
import { useStyles } from './socials-networks.styles';
import { ISocialNetworksProps } from './social-networks.models';

export const SocialsNetworks = ({ isMail }: ISocialNetworksProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ul
      className={classes.socialNetworksList}
    >
      {isMail && (
        <li>
          <a href={intl.formatMessage({ id: 'common.email.program' })} target="_blank">
            <Icon type={IconTypes.email} size={IconSizes.lg} />
          </a>
        </li>
      )}
    </ul>
  );
};
