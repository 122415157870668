import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';

import { Header } from './header';
import { useStyles } from './layout-secondary.styles';
import { useSpecificPath } from '../../../../hooks/use-specific-path';

import { InternalLinks } from '../../../../constants';
import { SECONDARY_BACKGROUND } from '../../../../constants';
import { Banner } from './banner';

export const LayoutSecondary = (): JSX.Element => {
  const classes = useStyles();
  const { isSpecificPath } = useSpecificPath([InternalLinks.registration]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div
      className={cx(classes.root, {
        [classes.rootWithBanner]: isSpecificPath,
      })}
    >
      <Helmet>
        <style type="text/css">
          {`
            body {
              background-color: ${SECONDARY_BACKGROUND};
            }
          `}
        </style>
      </Helmet>
      {(isSpecificPath && isDesktop) && (
        <Banner isLeft />
      )}
      <div className={classes.headerAndContentWrapper}>
        <Header />
        <Outlet />
      </div>
      {(isSpecificPath && isDesktop) && (
        <Banner isRight />
      )}
    </div>
  );
};
