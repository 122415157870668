import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, fontFamilies, fontSizes, palette, lineHeights,
}: Theme) => ({
  content: {
    marginBottom: 16,
    [breakpoints.up('md')]: {
      marginBottom: 48,
    },
  },
  contentParagraph: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.secondary,
    margin: '0 0 20px 0',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
    },
  },
  footer: {
    [breakpoints.up('md')]: {
      margin: '0 auto',
      width: 440,
    },
  },
  description: {
    textAlign: 'center',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xxs,
    lineHeight: lineHeights.xs,
    color: palette.grey['500'],
    marginTop: 16,
    [breakpoints.up('md')]: {
      marginTop: 22,
    },
  },
}));
