import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  spacing,
  breakpoints,
  palette,
  fontSizes,
  fontWeights,
}: Theme) => ({
  footer: {
    backgroundColor: palette.info.dark,
    padding: `${spacing(5)} ${spacing(1.25)}`,
    [breakpoints.up('md')]: {
      padding: `${spacing(4)} ${spacing(1.25)} ${spacing(4)}`,
    },
  },
  inner: {
    [breakpoints.up('md')]: {
      maxWidth: 1344,
      margin: '0 auto',
    },
  },
  supportContainer: {
    display: 'grid',
    rowGap: spacing(2),
    paddingBottom: spacing(5),
    [breakpoints.up('md')]: {
      paddingBottom: spacing(7.5),
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      columnGap: spacing(2.5),
    },
  },
  supportContainerClasses: {
    borderColor: palette.common.white,
    padding: `${spacing(2.5)} 4px ${spacing(3)}`,
    [breakpoints.up('lg')]: {
      padding: `${spacing(4)} ${spacing(1.25)} 0`,
    },
  },
  supportContainerTitle: {
    fontSize: fontSizes.sm,
    lineHeight: 1.3,
    fontWeight: fontWeights.regular,
    letterSpacing: '-0.01em',
    [breakpoints.up('md')]: {
      fontSize: fontSizes.xl,
    },
  },
  supportContainerDescription: {
    maxWidth: 'unset',
    fontSize: fontSizes.xxs,
    lineHeight: 1.3,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
    },
  },
  supportActionClasses: {
    marginBottom: 0,
    padding: '12px 20px',
    fontWeight: fontWeights.regular,
  },
  supportHintText: {
    marginTop: 20,
    marginBottom: 0,
    color: 'rgba(247, 247, 247, 0.5)',
    opacity: 1,
    fontSize: 10,
    [breakpoints.up('lg')]: {
      marginTop: 36,
      marginBottom: 20,
      fontSize: fontSizes.xs,
    },
  },
  policy: {
    flexBasis: '100%',
  },
  policyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 50,
    },
  },
  logoGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  logo: {
    marginBottom: 50,
    width: 93,
    height: 98,
    [breakpoints.up('md')]: {
      marginBottom: 30,
      width: 109,
      height: 121,
    },
  },
  campaignIcon: {
    height: 108,
    objectFit: 'contain',
  },
  terms: {
    marginTop: 0,
    marginBottom: 16,
    color: palette.common.white,
    fontSize: 10,
    lineHeight: 1.3,
    [breakpoints.up('md')]: {
      fontSize: 12,
    },
  },
  legal: {
    maxWidth: '100%',
  },
}));
