import { FormattedMessage } from 'react-intl';
import { FrequencyOfUsageTypes } from '../../../../../../../../graphql/reminders/models/reminder.models';
import { IOptions } from '../../../../../../../common/select';

export const frequencyOfUsageItems: IOptions[] = [{
  value: FrequencyOfUsageTypes.everyDay,
  label: (
    <FormattedMessage
      id="reminders.modal.form.frequencyOfUsage.options.everyDay"
    />
  ),
}, {
  value: FrequencyOfUsageTypes.fiveDays,
  label: (
    <FormattedMessage
      id="reminders.modal.form.frequencyOfUsage.options.fiveDays"
    />
  ),
}, {
  value: FrequencyOfUsageTypes.threeToFourDays,
  label: (
    <FormattedMessage
      id="reminders.modal.form.frequencyOfUsage.options.threeToFourDays"
    />
  ),
}, {
  value: FrequencyOfUsageTypes.oneToTwoDays,
  label: (
    <FormattedMessage
      id="reminders.modal.form.frequencyOfUsage.options.oneToTwoDays"
    />
  ),
}, {
  value: FrequencyOfUsageTypes.lessThanOneDay,
  label: (
    <FormattedMessage
      id="reminders.modal.form.frequencyOfUsage.options.lessThanOneDay"
    />
  ),
}];