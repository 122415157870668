import { ApolloQueryResult } from '@apollo/client';

import { IModalProps } from '../../../../common/modal';
import {
  IConsumerLenses,
  IReminder,
  LensesScheduleType,
} from '../../../../../graphql/lenses/models/get-lenses.models';


export interface ICreateEditReminderModalProps extends IModalProps {
  type: ReminderModalType;
  options: IReminderOptions;
}

export interface IReminderOptions {
  consumerLensesId: string;
  packSize: number;
  eyesAmount: number;
  schedule: LensesScheduleType;
  reminderProps?: IReminder;
  onUpdateLenses: () => Promise<ApolloQueryResult<IConsumerLenses>>;
}

export enum ReminderModalType {
  create = 'create',
  edit = 'edit',
}