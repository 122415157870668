import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  fontWeights,
  fontFamilies,
  palette,
  fontSizes,
  breakpoints,
  lineHeights,
  borderRadiuses,
}: Theme) => ({
  serviceTitle: {
    fontFamily: fontFamilies.primary,
    fontWeight: fontWeights.medium,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    [breakpoints.up('xl')]: {
      '&:nth-of-type(2)': {
        paddingTop: 12,
        marginTop: 20,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderColor: palette.grey['200'],
      },
    },
  },
  service: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
  },
  serviceItem: {
    listStyle: 'none',
    display: 'block',
    width: 'fit-content',
    padding: '4px 12px',
    marginBottom: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: palette.grey['200'],
    borderRadius: borderRadiuses.sm,
    color: palette.grey['800'],
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    [breakpoints.up('sm')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '&:not(:last-child)': {
      marginRight: 8,
    },
    '& .MuiIconButton-root': {
      marginLeft: 6,
    },
  },
  messagePartial: {
    marginTop: 0,
    marginBottom: 8,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
