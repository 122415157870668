import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  palette,
  lineHeights,
  fontSizes,
  fontWeights,
}: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 15px 15px',
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    marginBottom: 25,
  },
  image: {
    display: 'block',
    margin: '0 auto 30px',
    width: 152,
  },
  description: {
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    color: palette.text.primary,
    padding: '0 5px',
    margin: 0,
    [breakpoints.up('md')]: {
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
    '& strong': {
      fontWeight: fontWeights.medium,
    },
  },
  footer: {
    marginTop: 5,
  },
}));
