import { Tooltip as TooltipBase } from '@mui/material';

import { ITooltipProps } from './tooltip.models';
import { useStyles } from './tooltip.styles';
import { TOUCH_ENTER_DELAY, LEAVE_TOUCH_DELAY } from '../../../constants/tooltip-options';

export const Tooltip = ({
  title,
  withArrow,
  placement,
  tooltipArrowClass,
  tooltipClass,
  children,
}: ITooltipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TooltipBase
      title={title}
      arrow={withArrow}
      placement={placement}
      enterTouchDelay={TOUCH_ENTER_DELAY}
      classes={{
        tooltip: tooltipClass || classes.tooltip,
        arrow: tooltipArrowClass || classes.tooltipArrow,
      }}
      leaveTouchDelay={LEAVE_TOUCH_DELAY}
    >
      {children}
    </TooltipBase>
  );
};
