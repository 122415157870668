import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { Button } from '../../../../common/button';
import discountsImageUrl from '../../../../../assets/images/why-my-acuvue/discounts.svg';
import findOpticImageUrl from '../../../../../assets/images/why-my-acuvue/find-optic.svg';
import advicesImageUrl from '../../../../../assets/images/why-my-acuvue/advices.svg';
import questionsImageUrl from '../../../../../assets/images/why-my-acuvue/questions.svg';
import { Why } from '../../../../common/why';
import { MobileApplicationInfoLabel } from '../../../../common/mobile-application-info-label';
import { InternalLinks } from '../../../../../constants';
import { setIsFreepacks } from '../../../../../graphql/configuration/configuration.cache';

export const WhyMyAcuvue = (): JSX.Element => {
  const intl = useIntl();
  const { hasPreviousLocationState } = useGoBack();
  const isFreepacksEnabled = useReactiveVar(setIsFreepacks);
  const discountsDescription = isFreepacksEnabled
    ? 'whyMyAcuvue.discounts.description.withFreepacks'
    : 'whyMyAcuvue.discounts.description';

  return (
    <Why
      title={intl.formatMessage(
        { id: 'whyMyAcuvue.title' },
        {
          sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
        },
      )}
      items={[{
        imageSource: discountsImageUrl,
        description: intl.formatMessage(
          { id: discountsDescription },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            strong: (chunks: ReactNode) => <strong data-testid="emphasis-text">{chunks}</strong>,
          },
        ),
      }, {
        imageSource: findOpticImageUrl,
        description: intl.formatMessage(
          { id: 'whyMyAcuvue.findOptic.description' },
          { strong: (chunks: ReactNode) =>
            <strong data-testid="emphasis-text">{chunks}</strong> },
        ),
      }, {
        imageSource: advicesImageUrl,
        description: intl.formatMessage(
          { id: 'whyMyAcuvue.advices.description' },
          {
            sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            strong: (chunks: ReactNode) => <strong data-testid="emphasis-text">{chunks}</strong>,
            linebreak: <br />,
          },
        ),
        footer: <MobileApplicationInfoLabel />,
      }, {
        imageSource: questionsImageUrl,
        description: intl.formatMessage(
          { id: 'whyMyAcuvue.questions.description' },
          {
            strong: (chunks: ReactNode) => <strong data-testid="emphasis-text">{chunks}</strong>,
            linebreak: <br />,
          },
        ),
      }]}
      footer={(
        <Button
          variant="contained"
          data-testid="whyMyAcuvue-signup-button"
          to={InternalLinks.registration}
          state={hasPreviousLocationState}
        >
          <FormattedMessage id="common.registrationButton.label" />
        </Button>
      )}
    />
  );
};
