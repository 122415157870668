import React from 'react';
import { cx } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalize } from 'lodash';
import { Paper } from '@mui/material';

import { IconButton } from '../icon-button';
import { IconTypes, IconSizes } from '../icon';
import { IBoxProps, IRootConditionalClasses } from './box.models';
import { useStyles } from './box.styles';

export const Box = ({
  children,
  footerDescription,
  title,
  description,
  backButtonUrl,
  responsiveSize,
  responsiveMargins,
  center,
  externalRootClasses,
}: IBoxProps): JSX.Element => {
  const classes = useStyles();
  const rootConditionalClasses: IRootConditionalClasses = {};

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const hasDescription = Boolean(description);

  return (
    <div
      className={cx(
        classes.root,
        { [classes[
          `rootResponsiveSize${capitalize(responsiveSize as string)}` as 'rootResponsiveSizeXs'
        ]]: center && !!responsiveSize },
        { [classes[
          `rootResponsiveMargins${capitalize(responsiveMargins as string)}` as 'rootResponsiveMarginsSm'
        ]]: center && !!responsiveMargins },
        { [rootConditionalClasses as any]: center },
        { [classes.rootCenter]: center },
        externalRootClasses,
      )}
    >
      <Paper
        classes={{
          root: classes.paperRoot,
        }}
      >
        {title && (
          <h1
            className={cx(classes.title, {
              [classes.titleWithoutDescription]: !hasDescription,
            })}
            data-testid="box-title"
          >
            {backButtonUrl && (
              <IconButton
                iconProps={{
                  type: IconTypes.arrowLeft,
                  size: isTablet ? IconSizes.md : IconSizes.sm,
                }}
                className={classes.backButton}
                to={backButtonUrl}
                data-testid="box-back-button"
              />
            )}
            {title}
          </h1>
        )}
        {hasDescription && (
          <p className={classes.description}>
            {description}
          </p>
        )}
        {children}
      </Paper>
      {footerDescription && (
        <footer className={classes.footer}>
          <p className={classes.footerDescription}>
            {footerDescription}
          </p>
        </footer>
      )}
    </div>
  );
};
