import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { IVisionProfilePageProps } from './vision-profile-page.models';
import { useGoBack } from '../../../../../hooks/use-go-back';
import { ContentWrapper } from '../../content-wrapper';
import { Box, ResponsiveMargins, ResponsiveSize } from '../../../../common/box';
import { Preloader } from '../../../../common/preloader';
import { PersonalInfo } from './personal-info';
import { Activities } from './activities/activities';
import { useStyles } from './vision-profile-page.styles';
import { VisionProfileIncomplete } from './vision-profile-incomplete/vision-profile-incomplete';
import { useVisionProfileData, VPStatus } from '../../../../../hooks/use-vision-profile-data';
import { ActivitiesChangeButton } from './activities-change-button';
import { SECONDARY_BACKGROUND } from '../../../../../constants';

export const VisionProfilePage = ({
  visionProfileData, isError, isLoading,
}: IVisionProfilePageProps): JSX.Element => {
  const { status } = useVisionProfileData(visionProfileData);
  const { goBack } = useGoBack();
  const classes = useStyles();
  const intl = useIntl();

  const handleBack = () => goBack();

  const renderVisionProfilePage = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      handleBack();
    }

    return (
      <>
        {status === VPStatus.INCOMPLETE && <VisionProfileIncomplete />}
        {status === VPStatus.COMPLETE && (
          <ContentWrapper withoutPaddings>
            <Helmet>
              <title>
                {intl.formatMessage({ id: 'vpPage.title' })}
              </title>
              <meta name="description" content={
                intl.formatMessage({ id: 'vpPage.description' })} />
              <style type="text/css">
                {`
                  body {
                    background-color: ${SECONDARY_BACKGROUND};
                  }
                `}
              </style>
            </Helmet>
            <Box
              center
              responsiveMargins={ResponsiveMargins.sm}
              responsiveSize={ResponsiveSize.xs}
              title={(
                <span data-testid="vision-profile-title" className={classes.title}>
                  {intl.formatMessage(
                    { id: 'vp.page.title' },
                    { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
                  )}
                </span>
              )}
              backButtonUrl={handleBack}
            >
              <h2 className={classes.subtitle} data-testid="subtitle-personal-info">
                <FormattedMessage id="vp.page.subtitle.personalInfo" />
              </h2>
              <PersonalInfo
                visionProfileData={visionProfileData!.visionProfile!}
              />
              <div className={classes.subtitleBar} data-testid="subtitle-bar">
                <h2 className={classes.subtitle} data-testid="subtitle-activities">
                  <FormattedMessage id="vp.page.subtitle.activities" />
                </h2>
                <ActivitiesChangeButton
                  whatDoYouLikeCustom={visionProfileData!.visionProfile!.whatDoYouLikeCustom}
                  whatDoYouLikePredefined={visionProfileData!.visionProfile!.whatDoYouLikePredefined}
                />
              </div>
              <Activities
                visionProfileData={visionProfileData!.visionProfile!}
              />
            </Box>
          </ContentWrapper>
        )}
      </>
    );
  };

  return renderVisionProfilePage();
};
