import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import leftRegistrationBanner from '../../../../../assets/images/backgrounds/registration/left-banner.png';
import rightRegistrationBanner from '../../../../../assets/images/backgrounds/registration/right-banner.png';

const bannerWidth = 144;

export const useStyles = makeStyles(({
  breakpoints, palette, fontSizes, lineHeights, fontWeights,
}: Theme) => ({
  root: {
    display: 'none',
    [breakpoints.up('lg')]: {
      position: 'fixed',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: bannerWidth,
      height: '100vh',
      padding: 8,
      paddingTop: 28,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'left bottom',
    },
  },
  leftBanner: {
    left: 0,
    right: bannerWidth,
    top: 0,
    bottom: 0,
    backgroundImage: `url(${leftRegistrationBanner})`,
  },
  rightBanner: {
    right: 0,
    left: `calc(100% - ${bannerWidth})`,
    top: 0,
    bottom: 0,
    backgroundImage: `url(${rightRegistrationBanner})`,
  },
  logoWrapper: {
    width: '100%',
    paddingLeft: 6,
    textAlign: 'center',
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  bannerPoints: {
    textTransform: 'uppercase',
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.md,
    fontWeight: fontWeights.medium,
  },
  bannerPointsFrom: {
    padding: '0 4px',
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.sm,
    fontWeight: fontWeights.medium,
  },
  marketingDisclaimer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    color: palette.info.light,
    textAlign: 'center',
  },
  legalDisclaimer: {
    color: palette.custom.regBanner,
    fontSize: fontSizes.xxxs,
    lineHeight: 1.3,
  },
  block: {
    display: 'block',
  },
  value: {
    color: palette.common.white,
    fontSize: fontSizes.large,
    lineHeight: 1.3,
  },
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  partial: {
    margin: 0,
    opacity: 0.7,
  },
  disclaimer: {
    width: '100%',
    height: 'auto',
    marginTop: 8,
    '& path': {
      fill: palette.custom.regBanner,
    },
  },
}));
