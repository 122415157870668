import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
}: Theme) => ({
  root: {},
  logoWrapper: {
    display: 'flex',
    marginBottom: spacing(2),
    [breakpoints.up('md')]: {
      marginBottom: spacing(3),
    },
  },
}));
