import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints,
  fontFamilies,
  fontSizes,
  lineHeights,
  fontWeights,
  siteMinWidth,
  borderRadiuses,
  palette,
}: Theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: siteMinWidth,
    margin: '0 auto 40px', // Depends on .filterButtonMobile
    filter: 'brightness(1)', // container for a child with position 'fixed'
    [breakpoints.up('sm')]: {
      flexDirection: 'column-reverse',
      marginBottom: 0,
      zIndex: 'unset',
    },
    [breakpoints.up('xl')]: {
      flexDirection: 'row',
    },
  },
  title: {
    margin: '0 0 16px',
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
    fontWeight: fontWeights.medium,
    [breakpoints.up('sm')]: {
      marginBottom: 28,
      fontSize: fontSizes.xl,
      lineHeight: 1.3,
    },
  },
  ecpLayerRoot: {
    paddingTop: 24,
    [breakpoints.up('sm')]: {
      padding: '40px 0',
    },
    [breakpoints.up('xl')]: {
      width: '42%',
      maxWidth: 605,
      padding: '48px 0',
    },
  },
  ecpLayerRootCard: {
    position: 'relative',
    zIndex: 1,
    marginTop: -16,
    borderRadius: '16px 16px 0 0',
    background: palette.common.white,
    [breakpoints.up('xl')]: {
      marginTop: 0,
      borderRadius: borderRadiuses.xs,
      background: 'transparent',
    },
  },
  ecpLayerWrapper: {
    padding: '0 16px',
    [breakpoints.up('sm')]: {
      padding: '0 40px',
    },
    [breakpoints.up('xl')]: {
      padding: '0 48px',
    },
  },
  shadowWrapper: {
    boxShadow: '0px 4px 6px -1px rgba(26, 32, 44, 0.1), 0px 2px 4px -1px rgba(26, 32, 44, 0.06)',
    [breakpoints.up('sm')]: {
      boxShadow: 'none',
    },
  },
  mapLayer: {
    height: 'calc(100vh - 129px)',
    width: '100%',
    marginBottom: -60,
    [breakpoints.up('sm')]: {
      height: '55vh',
      display: 'flex',
      marginBottom: 0,
    },
    [breakpoints.up('xl')]: {
      position: 'sticky',
      top: 0,
      width: '58%',
      flexGrow: 1,
      height: '100vh',
    },
  },
  mapLayerHidden: {
    display: 'none',
  },
  mapLayerOverlay: {
    pointerEvents: 'none',
    filter: 'brightness(0.3)',
  },
  searchBar: {
    display: 'grid',
    gridColumnGap: '8px',
    gridTemplateColumns: 'calc(100% - 46px) 37px',
    alignItems: 'start',
    paddingBottom: 12,
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'calc(100% - 177px) 120px 40px',
      paddingBottom: 24,
    },
    '& > .MuiIconButton-root': {
      marginTop: 8,
      [breakpoints.up('sm')]: {
        marginTop: 0,
      },
    },
    '& form': {
      lineHeight: 1.43,
    },
  },
  internetShopsButtonWrapper: {
    [breakpoints.up('sm')]: {
      border: '1px solid red',
      borderRadius: '50%',
    },
  },
  filterButtonDesktop: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      borderRadius: borderRadiuses.xs,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  sortButtons: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 330,
    },
  },
  storesList: {
    minHeight: 'calc(100vh - 175px)',
    [breakpoints.up('sm')]: {
      margin: '-12px 0 0',
      bottom: 0,
    },
    [breakpoints.up('md')]: {
      minHeight: 'unset',
    },
  },
  filterButtonMobile: {
    position: 'sticky',
    bottom: 90,
    width: 'fit-content',
    margin: '-80px 8px 0',
    left: 13,
    zIndex: 10,
    marginBottom: 8,
    [breakpoints.up('sm')]: {
      display: 'none',
    },
    '& .MuiIconButton-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 48,
      height: 48,
      paddingRight: 0,
      backgroundColor: palette.common.white,
      borderRadius: 24,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  mobileViewButton: {
    position: 'sticky',
    maxWidth: 200,
    bottom: 40,
    margin: '0 auto', // Depends on .filterButtonMobile
    zIndex: 1,
  },
  inputRoot: {
    minWidth: 'unset',
    '&.MuiInput-underline:after': {
      borderBottomColor: palette.primary.main,
    },
    '&.MuiOutlinedInput-root': {
      borderRadius: borderRadiuses.md,
      padding: '0 0 0 16px',
      '& fieldset': {
        borderColor: palette.grey['500'],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary.main,
      },
    },
  },
  input: {
    boxSizing: 'border-box',
    height: 43,
    color: palette.text.primary,
    fontFamily: fontFamilies.primary,
    fontSize: fontSizes.sm,
    padding: '12px 0',
    lineHeight: 1,
  },
  appointmentWrapper: {
    position: 'absolute',
    top: 76, // depends on mapLayer.marginBottom
    left: 16,
    width: 'calc(100% - 32px)',
    [breakpoints.up('sm')]: {
      top: 16,
    },
    [breakpoints.up('xl')]: {
      left: 20,
      width: 'calc(100% - 40px)',
    },
    '&:empty': {
      display: 'none',
    },
  },
}));
