import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'none',
    gap: 16,
    margin: 0,
    padding: 0,
    [breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: 20,
    },
    [breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridAutoRows: '1fr',
      gap: 20,
    },
    '& li:first-of-type': {
      [breakpoints.up('sm')]: {
        gridRow: 'span 2',
      },
      [breakpoints.up('xl')]: {
        minWidth: 255,
      },
    },
    '& li:nth-of-type(2)': {
      [breakpoints.up('sm')]: {
        gridRow: 'span 2',
      },
      [breakpoints.up('xl')]: {
        maxWidth: 215,
      },
    },
    '& li:nth-of-type(3)': {
      [breakpoints.up('sm')]: {
        gridColumn: 'span 2',
      },
    },
  },
  footer: {},
}));
