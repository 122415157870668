import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  rootWithBanner: {
    [breakpoints.up('lg')]: {
      padding: '0 calc(100vh / 6.5)',
    },
  },
  headerAndContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
  },
  banner: {
    display: 'none',
    [breakpoints.up('lg')]: {
      display: 'inline',
      position: 'fixed',
      height: '100vh',
      top: 0,
    },
  },
  leftBanner: {
    left: 0,
  },
  rightBanner: {
    right: 0,
  },
}));
