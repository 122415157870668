import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../../common';
import {
  IAppointmentRequestButtonProps,
  IRequestButtonProps,
} from './appointment-request-button.models';
import { Preloader } from '../../../common/preloader';
import { useStyles } from './appointment-request-button.styles';
import { InternalLinks } from '../../../../constants';
import { useGoBack } from '../../../../hooks/use-go-back';
import { isAppointmentContentShow } from '../../../../utils/appointment/is-appoinment-content-show';

export const AppointmentRequestButton = ({
  isLoggedIn,
  isLoading,
  isError,
  appointments,
  onClick, appointmentUrl,
}: IAppointmentRequestButtonProps): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  const RequestButton = ({ disabled }: IRequestButtonProps) => (
    <Button
      data-testid="appointment-request-button"
      variant="contained"
      href={appointmentUrl}
      target="_blank"
      onClick={onClick}
      disabled={disabled}
    >
      <FormattedMessage id="appointment.requestButton.label" />
    </Button>
  );

  const renderRequestButton = (): JSX.Element | null => {
    if (!isLoggedIn) {
      return <RequestButton />;
    }

    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    const { allAppointmentsByAuthId: { edges } } = appointments!;

    if (edges.length) {
      const { node: { date, status } } = edges[0];
      const isRequestButtonDisable = isAppointmentContentShow(date, status);

      return (
        <div data-testid="request-button-wrapper">
          <RequestButton disabled={isRequestButtonDisable} />
          {isRequestButtonDisable && (
            <div
              data-testid="active-appointment-message"
              className={classes.messageContainer}
            >
            {intl.formatMessage(
              { id: 'appointment.requestButton.message' },
              {
                p: (chunk: ReactNode) => (
                  <p
                    className={classes.messagePartial}
                    data-testid="appointment-message-partial"
                  >
                    {chunk}
                  </p>
                ),
                link: (chunk: ReactNode) => (
                  <Button
                    to={InternalLinks.appointment}
                    state={hasPreviousLocationState}
                    variant="text"
                    className={classes.messageLink}
                    data-testid="appointment-message-link"
                  >
                    {chunk}
                  </Button>
                ),
              },
            )}
            </div>
          )}
        </div>
      );
    }

    return <RequestButton />;
  };

  return (
    <>
      {renderRequestButton()}
    </>
  );
};
