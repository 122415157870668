import React, { forwardRef, useImperativeHandle } from 'react';

import { IStepComponentProps, IStepperImperativeHandleProps } from '../../../../../common/stepper';
import { PreSurveyView } from '../../vision-profile-views/pre-survey-view';

export const PreSurveyStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  onGoNextSuccess,
}, ref): JSX.Element => {
  useImperativeHandle(ref, () => ({
    goNext() {
      onGoNextSuccess();
    },
    goBack() { },
  }));

  return (
    <PreSurveyView />
  );
});
